<template>
  <div v-if="showWebTrigger" class="web-trigger">
    <div>Processing Request...</div>
    <div class="progress">
      <div class="progress-value"></div>
    </div>
  </div>
  <Error v-if="this.$store.state.globalStates.err.stat" />
  <div
    v-else
    class="container-fluid bg-usfc d-none d-lg-block"
    :class="!publicRouteNames.includes(currentRouteName) ? ' ' : ''"
  >
    <div v-if="this.$store.state.app.preloader.on" class="preloader d-flex">
      <lottie-animation
        class="m-auto"
        ref="anim"
        :animationData="require('@/assets/preloader.json')"
        style="transform: scale(0.5)"
      />
    </div>
    <!-- <span @click="this.$store.state.app.MainPopup.message = 'New Transporter successfully added'">Toggle</span> -->
    <SideNav
      v-if="!publicRouteNames.includes(currentRouteName)"
      @menu="getMenu"
      @submenu="getSub"
    />
    <Transition name="fade">
      <div v-if="this.$store.state.app.openModal != ''" class="modal-container">
        <ModalDialog />
      </div>
    </Transition>
    <div class="row">
      <div
        v-if="!publicRouteNames.includes(currentRouteName)"
        class="col-3 spacer"
      ></div>
      <div
        :class="this.$store.state.app.openModal != '' ? 'blur-overlay' : ''"
        class="d-none d-md-block col blur-layout"
        style="background-color: #fbfbfb; min-height: 100vh; height: auto"
      >
        <div
          v-if="!publicRouteNames.includes(currentRouteName)"
          class="row top-panel"
          style="height: auto"
        >
          <div class="col mx-3 p-0 prevent-select nav-layout">
            <span class="my-2 breadcrumb">
              <h2 class="d-inline" style="font-weight: bold">
                {{ currentMenu() }}
              </h2>
              <span
                class="mx-2"
                v-if="this.$route.path.split('/')[2] != null"
                style="font-size: 1em"
                >/</span
              >
              <span
                v-if="
                  this.$store.state.userData.access_level == 5 &&
                  !['detail', 'approval'].includes(
                    currentSubmenu().toLowerCase()
                  )
                "
              >
                <h6
                  @click="goto(2)"
                  class="d-inline clickable-text"
                  v-if="this.$route.path.split('/')[2] != null"
                  style="opacity: 0.5"
                >
                  {{ currentSubmenu() }}
                </h6>
              </span>

              <span v-else>
                <h6
                  class="d-inline"
                  v-if="this.$route.path.split('/')[2] != null"
                  style="opacity: 0.5"
                >
                  {{ currentSubmenu() }}
                </h6>
              </span>

              <h6
                v-if="this.$route.path.split('/')[3] != null"
                style="opacity: 0.5"
              >
                <span class="mx-2" style="font-size: 1em; margin">/</span
                >{{ currentSubSubMenu }}
              </h6>
            </span>
            <span
              id="profile-card"
              ref="userAction"
              @click="toggleUserAction()"
              class="profile-card"
            >
              <img
                v-if="this.$store.state.userData.photo == null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  this.$store.state.userData.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <div class="wrapper" style="text-align: left; float: right">
                <div class="mx-2 d-block name">{{ userFullName }}</div>
                <div class="mx-2 text-secondary" style="font-size: 12px">
                  {{ role }}
                </div>
              </div>
              <img
                src="/icons/all/twotone/arrow-right-1.svg"
                class="blue-filter mx-3 rot90"
              />
              <Transition name="slide-fade">
                <ul
                  v-if="userAction"
                  id="user-button"
                  class="user-button prevent-select"
                >
                  <li
                    @click="
                      openProfile(
                        this.$store.state.userData.id,
                        this.$store.state.userData.access_level
                      )
                    "
                  >
                    <img
                      hidden
                      src="/icons/all/twotone/user-edit.svg"
                      style="position: relative; top: -3px"
                      class="blue-filter mx-3"
                    />My Profile
                  </li>
                  <li style="border-bottom: solid 1px rgba(0, 0, 0, 0.1)">
                    <img
                      hidden
                      src="/icons/all/twotone/call.svg"
                      style="position: relative; top: -3px; padding: 2px"
                      class="blue-filter"
                    />Contact Us
                  </li>
                  <li
                    @click="logout()"
                    class="sign-out-button d-flex justify-content-center"
                  >
                    <img
                      hidden
                      src="/icons/all/twotone/logout-1.svg"
                      style="position: relative; top: -3px"
                      class=""
                    />Sign-out
                  </li>
                </ul>
              </Transition>
            </span>
            <span
              title="Bell will shake/vibrate when there's unread notification"
              ref="notificationButton"
              ><notificationButton
                :open="showNotification"
                :unread="hasUnreadNotification"
                @click="toggleNotification()"
            /></span>
            <notificationItems :open="showNotification" />
          </div>
        </div>
        <div
          :class="{
            '  frame-content me-0':
              !publicRouteNames.includes(currentRouteName),
          }"
          style="border-radius: 6px; display: flex; flex-direction: row"
        >
          <div
            v-if="!publicRouteNames.includes(currentRouteName)"
            style="flex: 1; max-width: 340px"
          ></div>
          <div
            :style="[
              !publicRouteNames.includes(currentRouteName)
                ? { width: 'calc(100vw - 340px)' }
                : { height: '100vh', width: '100vw' },
            ]"
            style="padding: 0 12px"
            :class="
              publicRouteNames.includes(currentRouteName) ? 'fill-frame' : ''
            "
          >
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <MainPopup />
  </div>

  <div class="container-fluid d-flex d-block d-md-none" style="height: 100vh">
    <div class="m-auto">Bigger display is required (0x0001DISPLAY)</div>
  </div>

  <alertSys
    :class="
      ['err', 'pending', 'success'].includes(
        $store.state.alert?.type?.toLowerCase()
      )
        ? 'show'
        : 'hidden'
    "
  />
  <GlobalLoader />
</template>

<script>
import "jquery/dist/jquery.js";
import GlobalLoader from "./components/GlobalLoader/GlobalLoader.vue";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import SideNav from "@/views/dashComps/navComponents/navComponent.vue";
import ModalDialog from "@/views/dashComps/modals/Modal.vue";
import $ from "jquery";
import axios from "axios";
import MainPopup from "./views/dashComps/popups/MainPopup.vue";
import Error from "./ErrorPages/Error.vue";
import notificationButton from "./components/notificationButton/notificationButton.vue";
import notificationItems from "./components/notificationItems/notificationItems.vue";
// import moment from "moment";
import { register } from "swiper/element/bundle";
import alertSys from "./reusables/alertSys.vue";
export default {
  name: "App",
  components: {
    SideNav,
    ModalDialog,
    MainPopup,
    notificationButton,
    Error,
    notificationItems,
    alertSys,
    GlobalLoader,
  },
  data() {
    return {
      showNotification: false,
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      publicRouteNames: [
        "Signin",
        "ResetPass",
        "PassChanged",
        "Register",
        "Waybill",
      ],
    };
  },
  computed: {
    showWebTrigger() {
      console.log(this.$route.query.wv);
      let token = JSON.parse(localStorage.getItem("token"));
      if (this.$route.query.wv && !token) {
        return true;
      }

      return false;
    },
    hasUnreadNotification() {
      return this.$store.state.app.hasUnreadNotification;
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentSubSubMenu() {
      return this.$store.state.app.breadcrumb.currentSubSubMenu;
    },
    userFullName() {
      if (this.$store.state.userData.full_name.length > 30) {
        return this.$store.state.userData.full_name.substring(0, 28) + "...";
      } else {
        return this.$store.state.userData.full_name;
      }
    },
    role() {
      if (this.$store.state.userData.access_level == 5) {
        return "Manager";
      } else if (this.$store.state.userData.access_level == 4) {
        return "Admin";
      } else if (this.$store.state.userData.access_level == 3) {
        return "Sales";
      } else if (this.$store.state.userData.access_level == 2) {
        return "Client";
      } else {
        return "Transporter";
      }
    },
  },

  methods: {
    registerSwiper() {
      register();
    },
    toggleNotification() {
      if (!this.showNotification) this.showNotification = true;
      else this.showNotification = false;

      this.getNotifications();
    },
    openProfile(id, access_level) {
      this.$store.state.app.managerForm.userDetail = access_level;
      this.$router.push("/manage/users/" + id);
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
    goto(backstep) {
      this.$router.push(
        "/" + this.$route.path.split("/").splice(1, backstep).join("/")
      );
    },
    currentMenu() {
      let str = this.$route.path.split("/")[1];
      str = str.replace(/-/g, " ");
      str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });

      return str;
    },
    currentSubmenu() {
      // let str = this.$route.path.split("/")[2];
      // str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      //   return letter.toUpperCase();
      // });

      // return str;

      return this.$route.name;
    },

    getMenu(val) {
      this.menu = val;
    },
    getSub(val) {
      this.submenu = val;
    },
    toggleUserAction() {
      if (!this.userAction) {
        this.userAction = true;
      } else {
        this.userAction = false;
      }
      const pos = document
        .getElementById("profile-card")
        .getBoundingClientRect();

      const card = document.getElementById("user-button");
      setTimeout(() => {
        if (card) card.style.left = `${pos.x}px`;
      }, 10);
    },
    setFocusUser(ele) {
      $(ele.target).focus();
      console.log(ele.target);
    },
    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.keepLogin;
          this.$store.state.userData = userData;
        })
        .catch(() => {
          if (!this.$route.query.wv) this.$router.push("/signin");
        });
    },
    async getNotifications() {
      let token = JSON.parse(localStorage.getItem("token"));

      const data = await axios
        .get(
          this.$store.state.app.apiBasepath + "/notification/all?is_read=false",
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          return res.data.rows;
        });

      if (data) {
        // const filteredData = data.rows.filter((item) => {
        //     const diffInDays = moment().diff(moment(item.updatedAt), "days");
        //     return diffInDays <= 7;
        // });

        this.$store.state.app.userNotification = data;

        this.hasUnreadNotification = data.some((item) => !item.is_read);
      }
    },
  },
  mounted() {
    // document.addEventListener("contextmenu", function (event) {
    //     event.preventDefault();
    // });
    this.$store.state.app.sessionToken = uuidv4();
    console.log(this.$store.state.app.sessionToken);
    this.$store.state.app.managerForm.noResult = false;
    this.$store.state.app.managerForm.formLoading = true;
    // setTimeout(() => {
    //     this.$alertSys("Test Message", "success");
    // }, 1000);
    this.registerSwiper();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 10) {
        $(".top-panel").addClass("sticky");
      } else {
        $(".top-panel").removeClass("sticky");
      }
    });

    // let token = localStorage.getItem("token");
    // if (token) {
    //     setInterval(() => {
    //         this.getNotifications();
    //     }, 10000);
    // }

    setTimeout(() => {
      document.addEventListener("mouseup", (e) => {
        if (this.$refs.userAction)
          if (!this.$refs.userAction.contains(e.target)) {
            this.userAction = false;
          }

        if (this.$refs.notificationButton)
          if (!this.$refs.notificationButton.contains(e.target)) {
            this.showNotification = false;
          }
      });
    }, 500);
  },
};
</script>

<style lang="scss">
@import "./App.scss";

.web-trigger {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3699ff;
  color: #fff;
  gap: 22px;
  text-align: center;
}

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 10px;
  width: 80%;
}

.progress-value {
  animation: load 5s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #fff;
  height: 30px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  20% {
    width: 20%;
  }
  40% {
    width: 30%;
  }
  60% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

.fill-frame {
  .frame {
    height: 100vh !important;
  }
}

.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  padding-left: 17vw;
}
</style>

<template>
    <div class="report-container">
        <div class="report-filter">
            <div class="row">
                <div v-if="this.$store.state.userData.access_level > 2" class="col">
                    <div class="label-float">
                        <label :class="bodyForm.month == null ? '' : 'option-selected'">
                            <span v-if="bodyForm.month">Choose Month</span>
                            <span v-else> Choose Month</span>
                        </label>
                        <select v-model="bodyForm.month" placeholder=" ">
                            <option value="null"><span style="color: rgba(0, 0, 0, 0.5)">Choose Month</span></option>

                            <option v-for="(item, index) in getMonths" :key="index" :value="index + 1">{{ item }}</option>
                        </select>
                    </div>
                </div>
                <div v-if="this.$store.state.userData.access_level > 2" class="col">
                    <div class="label-float">
                        <label :class="bodyForm.year == null ? '' : 'option-selected'">
                            <span v-if="bodyForm.year">Choose Year</span>
                            <span v-else> Choose Year</span>
                        </label>
                        <select v-model="bodyForm.year" placeholder=" ">
                            <option value="null"><span style="color: rgba(0, 0, 0, 0.5)">Choose Year</span></option>

                            <option v-for="(item, index) in getYears" :key="index" :value="item">{{ item }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-end my-4"><button :disabled="!bodyForm.month || !bodyForm.year" @click="submit()" style="float: right" class="btn btn-usfc-primary">Submit</button></div>
            </div>
        </div>

        <div v-if="this.result.length" class="report-result">
            <div class="card">
                <div class="inner-container">
                    <div class="table-header">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.3085 10.2812H15.9512C14.7052 10.2812 13.7804 11.2079 13.7804 12.25C13.7804 13.2921 14.7053 14.2188 15.9504 14.2188H18.3286C18.5089 14.2074 18.5868 14.0858 18.5929 14.0122V10.4878C18.5868 10.4143 18.5089 10.2926 18.3286 10.2821L18.3085 10.2812ZM18.2438 8.96875C18.3015 8.96875 18.3575 8.96875 18.41 8.97225C19.1713 9.01862 19.8459 9.5935 19.9028 10.3967C19.9063 10.4492 19.9062 10.5061 19.9062 10.5586V13.9414C19.9062 13.9939 19.9063 14.0508 19.9028 14.1033C19.8459 14.9065 19.1712 15.4814 18.4091 15.5286C18.3575 15.5312 18.3015 15.5312 18.2429 15.5312H15.9521C14.0753 15.5312 12.4688 14.1068 12.4688 12.25C12.4688 10.3932 14.0752 8.96875 15.9512 8.96875H18.2289H18.2438Z"
                                    fill="white" />
                                <path
                                    d="M16.625 12.25C16.625 12.4821 16.5328 12.7046 16.3687 12.8687C16.2046 13.0328 15.9821 13.125 15.75 13.125C15.5179 13.125 15.2954 13.0328 15.1313 12.8687C14.9672 12.7046 14.875 12.4821 14.875 12.25C14.875 12.0179 14.9672 11.7954 15.1313 11.6313C15.2954 11.4672 15.5179 11.375 15.75 11.375C15.9821 11.375 16.2046 11.4672 16.3687 11.6313C16.5328 11.7954 16.625 12.0179 16.625 12.25Z"
                                    fill="white" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M18.2437 8.96885C18.4948 8.95936 18.7446 9.00725 18.9744 9.10885C18.8807 7.7036 18.6226 6.62035 17.8141 5.81098C17.1587 5.15648 16.3284 4.86598 15.3029 4.72773L15.2661 4.72335C15.257 4.71641 15.2476 4.7097 15.2381 4.70323L11.9691 2.53585C11.4021 2.1658 10.7396 1.96875 10.0625 1.96875C9.38539 1.96875 8.72291 2.1658 8.15588 2.53585L4.88775 4.70323C4.87795 4.70969 4.86832 4.7164 4.85888 4.72335L4.82213 4.72773C3.79663 4.86598 2.96625 5.15648 2.31088 5.81098C1.65637 6.46635 1.36588 7.29673 1.22762 8.32223C1.09375 9.31973 1.09375 10.5929 1.09375 12.2011V12.2991C1.09375 13.9074 1.09375 15.1814 1.22762 16.178C1.36588 17.2035 1.65637 18.0339 2.31088 18.6892C2.96625 19.3437 3.79663 19.6342 4.82213 19.7725C5.81962 19.9064 7.09275 19.9064 8.701 19.9064H11.424C13.0322 19.9064 14.3063 19.9064 15.3029 19.7725C16.3284 19.6342 17.1587 19.3437 17.8141 18.6892C18.6226 17.8799 18.8807 16.7966 18.9744 15.3905C18.7976 15.4692 18.6069 15.5165 18.4091 15.5287C18.3575 15.5314 18.3015 15.5314 18.2429 15.5314H17.647C17.5472 16.7056 17.3224 17.3251 16.8857 17.7609C16.5156 18.131 16.0081 18.3532 15.1279 18.4714C14.2292 18.5921 13.0436 18.5939 11.375 18.5939H8.75C7.08138 18.5939 5.89663 18.5921 4.99625 18.4714C4.11687 18.3532 3.60938 18.131 3.23925 17.7609C2.86912 17.3907 2.64688 16.8832 2.52875 16.003C2.408 15.1044 2.40625 13.9187 2.40625 12.2501C2.40625 10.5815 2.408 9.39673 2.52875 8.49635C2.64688 7.61698 2.86912 7.10948 3.23925 6.73935C3.60938 6.36923 4.11687 6.14698 4.99712 6.02885C5.89663 5.9081 7.08138 5.90635 8.75 5.90635H11.375C13.0436 5.90635 14.2293 5.9081 15.1288 6.02885C16.0081 6.14698 16.5156 6.36923 16.8857 6.73935C17.3224 7.1751 17.5472 7.79548 17.647 8.96885H18.2289H18.2437ZM8.701 4.59385H11.424C11.8764 4.59385 12.3016 4.59385 12.7015 4.59648L11.2438 3.6296C10.5438 3.16585 9.58125 3.16585 8.88125 3.6296L7.42262 4.59648C7.82337 4.59385 8.24862 4.59385 8.70012 4.59385"
                                    fill="white" />
                                <path
                                    d="M5.25 8.09375C5.07595 8.09375 4.90903 8.16289 4.78596 8.28596C4.66289 8.40903 4.59375 8.57595 4.59375 8.75C4.59375 8.92405 4.66289 9.09097 4.78596 9.21404C4.90903 9.33711 5.07595 9.40625 5.25 9.40625H8.75C8.92405 9.40625 9.09097 9.33711 9.21404 9.21404C9.33711 9.09097 9.40625 8.92405 9.40625 8.75C9.40625 8.57595 9.33711 8.40903 9.21404 8.28596C9.09097 8.16289 8.92405 8.09375 8.75 8.09375H5.25Z"
                                    fill="white" />
                                <path
                                    d="M16.625 12.25C16.625 12.4821 16.5328 12.7046 16.3687 12.8687C16.2046 13.0328 15.9821 13.125 15.75 13.125C15.5179 13.125 15.2954 13.0328 15.1313 12.8687C14.9672 12.7046 14.875 12.4821 14.875 12.25C14.875 12.0179 14.9672 11.7954 15.1313 11.6313C15.2954 11.4672 15.5179 11.375 15.75 11.375C15.9821 11.375 16.2046 11.4672 16.3687 11.6313C16.5328 11.7954 16.625 12.0179 16.625 12.25Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div>Earnings</div>
                    </div>
                    <div class="table-content">
                        <div v-for="(item, index) in receipts('earnings')" :key="index" class="content-item">
                            <div>{{ index + 1 }}</div>
                            <div>{{ item.full_name }}</div>
                            <div>{{ toRp(item.total_earnings) }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="inner-container">
                    <div class="table-header">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path
                                    d="M7.3335 11.0013C8.30596 11.0013 9.23859 10.615 9.92622 9.92736C10.6139 9.23973 11.0002 8.3071 11.0002 7.33464C11.0002 6.36217 10.6139 5.42954 9.92622 4.74191C9.23859 4.05428 8.30596 3.66797 7.3335 3.66797C6.36104 3.66797 5.4284 4.05428 4.74077 4.74191C4.05314 5.42954 3.66683 6.36217 3.66683 7.33464C3.66683 8.3071 4.05314 9.23973 4.74077 9.92736C5.4284 10.615 6.36104 11.0013 7.3335 11.0013ZM15.5835 11.0013C16.3128 11.0013 17.0123 10.7116 17.528 10.1958C18.0438 9.68012 18.3335 8.98065 18.3335 8.2513C18.3335 7.52196 18.0438 6.82248 17.528 6.30676C17.0123 5.79103 16.3128 5.5013 15.5835 5.5013C14.8542 5.5013 14.1547 5.79103 13.639 6.30676C13.1232 6.82248 12.8335 7.52196 12.8335 8.2513C12.8335 8.98065 13.1232 9.68012 13.639 10.1958C14.1547 10.7116 14.8542 11.0013 15.5835 11.0013ZM4.12516 12.8346C2.86016 12.8346 1.8335 13.8613 1.8335 15.1263C1.8335 15.1263 1.8335 19.2513 7.3335 19.2513C11.6932 19.2513 12.597 16.659 12.784 15.5846C12.8335 15.3041 12.8335 15.1263 12.8335 15.1263C12.8335 13.8613 11.8068 12.8346 10.5418 12.8346H4.12516ZM14.6595 15.768C14.6384 16.1003 14.5831 16.4296 14.4945 16.7506C14.379 17.1595 14.1883 17.649 13.8675 18.1531C14.43 18.2792 15.0052 18.3401 15.5817 18.3346C20.165 18.3346 20.165 15.1263 20.165 15.1263C20.165 13.8613 19.1383 12.8346 17.8733 12.8346H13.9702C14.4102 13.491 14.665 14.2775 14.665 15.1263V15.5846L14.6595 15.768Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div>Clients</div>
                    </div>
                    <div class="table-content">
                        <div v-for="(item, index) in receipts('clients')" :key="index" class="content-item">
                            <div>{{ index + 1 }}</div>
                            <div>{{ item.full_name }}</div>
                            <div>{{ item.total_clients }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="inner-container">
                    <div class="table-header">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M13.5 19.5H19.5V21H13.5V19.5ZM13.5 16.5H22.5V18H13.5V16.5ZM13.5 13.5H22.5V15H13.5V13.5Z" fill="white" />
                                <path
                                    d="M15.6718 8.45056C15.509 8.42689 15.3682 8.32461 15.2954 8.17705L12.4483 2.40846C12.2649 2.03684 11.735 2.03684 11.5516 2.40846L8.70455 8.17704C8.63172 8.3246 8.49094 8.42689 8.32808 8.45055L1.96246 9.37557C1.55237 9.43516 1.38861 9.93912 1.68534 10.2284L6.29174 14.719C6.40957 14.8339 6.46334 14.9994 6.43551 15.1616L5.34777 21.5015C5.2777 21.9099 5.70639 22.2214 6.07321 22.0286L10.2326 19.8423C10.397 19.7559 10.5 19.5854 10.5 19.3997V18.8345C10.5 18.4584 10.1002 18.2169 9.76727 18.392L8.0659 19.2865C7.6991 19.4794 7.27038 19.1679 7.3404 18.7595L7.95821 15.156L8.0472 14.6376C8.07506 14.4752 8.02119 14.3096 7.90317 14.1947L7.52696 13.8285L4.90869 11.2754C4.612 10.9861 4.7758 10.4822 5.18588 10.4226L8.80421 9.897L9.3242 9.8215C9.48699 9.79787 9.62774 9.69567 9.70061 9.5482L9.93371 9.0765L11.5516 5.79789C11.735 5.42624 12.2649 5.42624 12.4483 5.79789L14.0662 9.0765L14.2993 9.5482C14.3722 9.69567 14.5129 9.79787 14.6757 9.8215L15.1957 9.897L20.2906 10.6386C20.5642 10.6784 20.8182 10.4887 20.8575 10.215L20.9289 9.71919C20.9681 9.44614 20.7789 9.19286 20.5059 9.15319L15.6718 8.45056Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div>SLA</div>
                    </div>
                    <div class="table-content">
                        <div v-for="(item, index) in receipts('rating')" :key="index" class="content-item">
                            <div>{{ index + 1 }}</div>
                            <div>{{ item.full_name }}</div>
                            <div>
                                <div class="Stars" :style="{ '--rating': item.average_rating }"></div>
                                {{ item.average_rating }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // import numberToWords from "number-to-words";
    // import moment from "moment";
    import axios from "axios";
    // import _ from "lodash";
    // import jsPDF from "jspdf";

    export default {
        data() {
            return {
                bodyForm: {
                    month: null,
                    year: null,
                },
                result: [],
            };
        },
        computed: {
            getYears() {
                const currentYear = new Date().getFullYear();
                const years = [currentYear - 1]; // Add 1 year before the current year

                for (let i = 0; i <= 10; i++) {
                    years.push(currentYear + i);
                }

                return years;
            },
            getMonths() {
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const currentMonth = new Date().getMonth();

                return months.map((month, index) => {
                    if (index === currentMonth) {
                        return month + " (Current Month)";
                    } else {
                        return month;
                    }
                });
            },
        },
        methods: {
            generateUserSummary(data) {
                // Combine all IDs
                const allIds = new Set([...(data.rating || []).map((item) => item.id), ...(data.total_clients || []).map((item) => item.id), ...(data.earnings || []).map((item) => item.id)]);

                // Create result array
                const result = Array.from(allIds).map((id) => {
                    const ratingData = (data.rating || []).find((item) => item.id === id) || { id, full_name: "", average_rating: 0 };
                    const clientsData = (data.total_clients || []).find((item) => item.id === id) || { id, full_name: "", total_clients: 0 };
                    const earningsData = (data.earnings || []).find((item) => item.id === id) || { id, full_name: "", total_earnings: 0 };

                    return {
                        id,
                        full_name: ratingData.full_name || clientsData.full_name || earningsData.full_name || "",
                        total_earnings: earningsData.total_earnings || 0,
                        total_clients: clientsData.total_clients || 0,
                        average_rating: ratingData.average_rating || 0,
                    };
                });

                return result;
            },
            submit() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(`${this.$store.state.app.apiBasepath}/report/sales-report?month=${this.bodyForm.month}&year=${this.bodyForm.year}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((res) => {
                        this.result = [];
                        // for (var i = 0; i < 17; i++) {
                        //     this.result = this.result.concat(res.data.data);
                        // }
                        this.result = this.generateUserSummary(res.data);
                        console.log(this.result);
                    })
                    .catch((err) => {
                        console.log("500: " + err);
                    });
            },
            receipts(key) {
                return this.result.sort((a, b) => b[key] - a[key]);
            },
            toRp(earnings) {
                // Rupiah uses comma as thousands separator and dot as decimal separator
                const formattedEarnings = earnings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return `Rp ${formattedEarnings}`;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .report-result {
        display: flex;
        gap: 22px;
        justify-content: space-between;

        .card {
            background-color: #fff;
            flex: 1;
            border-radius: 24px;
            padding: 22px;
            font-size: 12px;
            box-shadow: 0 0 8px 1px rgba($color: #000, $alpha: 0.15);
            min-height: 500px;
            max-height: 600px;
            max-width: 30%;

            display: flex;
            flex-direction: column;

            .inner-container {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 24px;
                overflow: hidden;

                .table-header {
                    width: 100%;

                    font-size: 16px;
                    font-weight: 700;
                    display: flex;

                    align-items: center;
                    padding: 22px 32px;
                    gap: 12px;
                    background-color: #3699ff;
                    color: #fff;
                    svg {
                        transform: scale(1);
                    }
                }

                .table-content {
                    width: 100%;
                    flex: 1;
                    flex-direction: column;
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
                        background-color: #fff;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #fff;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #aaa;
                        border-radius: 6px;
                    }

                    overflow: auto;
                    .content-item {
                        display: flex;
                        gap: 12px;
                        padding: 12px;
                        &:nth-of-type(odd) {
                            background-color: #f5f5f5;
                        }
                        div {
                            display: flex;
                            &:nth-child(2) {
                                text-align: center !important;
                                flex: 1;
                            }
                        }

                        .Stars {
                            --percent: calc(var(--rating) * 20%);

                            display: inline-block;
                            font-size: var(--star-size);
                            font-family: Times; // make sure ★ appears correctly
                            line-height: 1;
                            transform: translateY(1.5px);

                            &::before {
                                content: "★★★★★";
                                // content: "★";
                                letter-spacing: 3px;
                                background: linear-gradient(90deg, var(--star-background) var(--percent), rgba(0, 0, 0, 0.2) var(--percent));
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    @keyframes box-shadow-transition {
        0% {
            box-shadow: 0 0 100px 15px rgba(54, 153, 255, 0);
        }
        20% {
            box-shadow: 0 0 50px 15px rgba(54, 153, 255, 0.6);
        }
        80% {
            box-shadow: 0 0 50px 15px rgba(54, 153, 255, 0.6);
        }
        100% {
            box-shadow: 0 0 0 rgba(54, 153, 255, 0);
        }
    }

    .disabled {
        opacity: 1;
        transform: scale(0.95);
        filter: brightness(70%);
        user-select: none;
        pointer-events: none;
    }

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    .animated-box {
        animation: box-shadow-transition 4s infinite;
    }

    .preview-invoice {
        transition: all 0.3s ease;
        padding: 25px;
        width: 90vw;
        height: 90vh;
        background: white;

        display: flex;
        position: fixed;
        left: 5vw;
        top: 5vh;

        z-index: 99999;

        box-shadow: 0 0 50px 15px rgba($color: #000, $alpha: 0.15);

        border-radius: 12px;

        .preview-detail {
            padding-left: 12px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                font-size: 24px;
                font-weight: 600;

                margin-bottom: 22px;
            }

            .content {
                .content-title {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                }
            }

            .actions {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 22px;
            }
        }

        .invoices-list {
            background: rgba($color: #000, $alpha: 0.15);
            overflow-y: auto;
            padding: 22px;

            display: flex;
            flex-direction: column;
            gap: 22px;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #3699ff;
                border-radius: 6px;
            }
            .invoice {
                outline: 10px solid white;
                // margin-top: 22px;
                width: 720px;
                // height: 877px;
            }
        }
    }
    .invoice {
        outline: 0px solid white;
        font-size: 11px;
        border: 1px solid rgba($color: #000000, $alpha: 1);
        display: flex;
        flex-direction: column;
        background: white;
        width: auto;
        // height: 877px;
        padding: 10px;
        margin-top: 10px;
        // justify-content: space-between;

        .signatures {
            display: flex;
            width: 50%;
            justify-content: space-between;
            .delegation {
                display: flex;
                flex-direction: column;

                .title {
                    padding-top: 6px;
                    padding-bottom: 36px;
                    padding-right: 6px;
                    flex-grow: 1;
                }
                .date {
                    border-top: 1px solid black;
                }
            }
        }

        .subtotal {
            display: flex;
            gap: 12px;
            .additional {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 3px;
                .written {
                    display: flex;
                    min-height: 14px;

                    .written-content {
                        border: 1px solid black;
                        flex-grow: 1;
                        min-height: 64px;
                        padding: 3px;
                    }
                    div {
                        min-width: 15px;
                    }
                }

                .description {
                    margin-top: 3px;
                    flex-grow: 1;
                    border: 1px solid black;
                    padding: 3px;

                    .title {
                        position: absolute;

                        background: white;
                        padding: 0 3px;
                        transform: translate(5px, -10px);
                    }

                    .content {
                        font-size: 12px;
                    }
                }
            }

            .summary {
                font-size: 12px;
                font-weight: 600;
                width: 35%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .sub {
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 50px;

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
                .tax {
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 50px;

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
                .total {
                    color: rgba($color: #3699ff, $alpha: 1);
                    font-size: 16px;
                    padding: 3px;
                    display: flex;
                    border: 1px solid black;

                    div {
                        min-width: 25px;

                        &:nth-child(2) {
                            text-align: center;
                        }

                        &:last-child {
                            flex-grow: 1;
                            text-align: end;
                        }
                    }
                }
            }
        }
        .items {
            table {
                border: 1px solid rgba($color: #000000, $alpha: 0.1);
                border-collapse: collapse;
                width: 100%;

                tr {
                    &:last-child {
                        border-bottom: 1px solid black;
                    }
                }

                th {
                    border: 1px solid black;
                }
                td {
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    height: 18.5px;
                    padding: 1px 2px;
                }

                thead {
                    background: rgba($color: #3699ff, $alpha: 0.5);
                }
            }
        }

        .header {
            // flex: 1;
            display: flex;
            width: 100% !important;

            .addresses {
                flex-grow: 1;

                table {
                    margin: 12px;
                    border-collapse: collapse;
                    flex-grow: 1;

                    border: 1px solid rgba($color: #000000, $alpha: 0.2);

                    td {
                        padding: 2px;
                    }
                }

                .logo {
                    max-height: 54px;
                    display: flex;
                    line-height: 11px;
                }

                .company-name {
                    font-weight: 600;

                    border-bottom: 1px dashed black;
                }
            }
            .invoice-detail {
                .title {
                    text-align: end;
                    font-size: 26px;
                    font-weight: 600;
                }

                table {
                    border-collapse: collapse;
                    border: 1px solid rgba($color: #000000, $alpha: 0.2);
                    table-layout: fixed;

                    td {
                        border: 1px dashed black;
                        padding: 0 2px;
                        height: 16px;
                        .col-content {
                            display: flex;
                            flex-direction: column;
                            min-width: 120px !important;

                            div {
                                min-height: 14px;
                            }

                            .cell-content {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn.btn-usfc-outline {
        transition: all 0.3s ease;
        color: #3699ff;
        background: white;
        border: 1px solid #3699ff;
        border-radius: 6px;
        padding: 10px 25px;
        font-weight: 600;

        .blue-filter {
            filter: invert(43%) sepia(96%) saturate(720%) hue-rotate(185deg) brightness(90%) contrast(101%);
        }

        &:hover {
            color: #3699ff;
            border: 1px solid #3699ff;
            background: rgba($color: #3699ff, $alpha: 0.15);
        }
    }
    abbr[title] {
        text-decoration: none;
    }
    .table-wrapper {
        height: 50vh;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }

        table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 0 6px;
                        position: sticky;
                        top: 0;
                        background: #fff;
                        overflow: hidden;
                    }
                }
            }

            tbody {
                tr {
                    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        cursor: pointer;
                        background-color: rgba($color: #3699ff, $alpha: 0.15);
                    }
                    td {
                        padding: 12px 6px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .print-wrapper {
        // position: relative;
        // top: 150px;
        font-size: 12px;
        // height: 50vh;
        // overflow-y: auto;
        // width: 100%;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }

        table {
            width: 100%;

            thead {
                tr {
                    th {
                        padding: 12px;
                        font-size: 14px;
                        font-weight: 600;
                        position: sticky;
                        top: 0;
                        background: #fff;
                        overflow: hidden;
                    }
                }
            }

            tbody {
                font-size: 6px;
                tr {
                    td {
                        padding: 6px 12px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    #chart {
        max-width: 400px;
        max-height: 400px;
    }
    .dp__main + .option-selected {
        transform: translateX(15px) translateY(-22px) !important;
    }
    .label-float {
        label {
            font-size: 12px !important;
            z-index: 99;
            transform: translateX(0px) translateY(-15px) !important;
        }

        &:has(.dp__main) {
            padding-top: 0;
        }
    }
    .report-container {
        height: 87vh;
        display: flex;
        background: transparent;

        flex-direction: column;
        gap: 22px;

        .grade-card-container {
            display: flex;
            padding: 22px;

            .card-list {
                flex: 1;
                display: flex;
                justify-content: space-between;
                flex-flow: 1 1 1;

                .card-item {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 12px;
                    min-width: 200px;
                    padding: 22px;

                    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        cursor: pointer;
                        box-shadow: 0 0 12px 2px rgba($color: #3699ff, $alpha: 0.15);
                        border-radius: 12px;
                    }

                    .circle {
                        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }

                    &:not(:first-of-type) {
                        border-left: 3px solid rgba($color: #000, $alpha: 0.15);
                    }

                    &.grade-a {
                        &:hover {
                            .circle {
                                background-color: rgba($color: #0abc06, $alpha: 0.5);
                            }
                        }
                        &.focus {
                            .circle {
                                background-color: rgba($color: #0abc06, $alpha: 1);
                            }
                        }
                        .circle {
                            border: 3px solid #0abc06;
                        }

                        .number {
                            color: #0abc06;
                        }
                    }

                    &.grade-b {
                        &:hover {
                            .circle {
                                background-color: rgba($color: #f6931f, $alpha: 0.5);
                            }
                        }
                        &.focus {
                            .circle {
                                background-color: rgba($color: #f6931f, $alpha: 1);
                            }
                        }
                        .circle {
                            border: 3px solid #f6931f;
                        }

                        .number {
                            color: #f6931f;
                        }
                    }

                    &.grade-c {
                        &:hover {
                            .circle {
                                background-color: rgba($color: #f25b5b, $alpha: 0.5);
                            }
                        }
                        &.focus {
                            .circle {
                                background-color: rgba($color: #f25b5b, $alpha: 1);
                            }
                        }
                        .circle {
                            border: 3px solid #f25b5b;
                        }

                        .number {
                            color: #f25b5b;
                        }
                    }

                    .header {
                        width: 70%;
                        display: flex;
                        align-items: center;
                        gap: 22px;
                        .circle {
                            border-radius: 50%;
                            height: 18px;
                            width: 18px;
                        }

                        .title {
                            font-size: 18px;
                            font-weight: 700;
                            color: rgba($color: #000, $alpha: 0.25);
                        }
                    }

                    .content {
                        width: 70%;
                        display: flex;
                        gap: 32px;
                        align-items: center;

                        .grade {
                            font-size: 32px;
                            font-weight: 700;
                        }

                        .number {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .report-filter {
            // max-height: 20vh;
            width: 100%;
            background-color: #fff;
            padding: 22px;
            border-radius: 12px;
            .dp__main {
                padding: 15px;
                input {
                    background-color: whitesmoke;
                    padding: 15px 20px;
                    height: 100%;
                    border: none;
                }

                .dp__input_icon {
                    left: auto !important;
                    right: 0 !important;
                }
            }
        }

        .report-content {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 22px;

            .title {
                /* Others/Soft-Black */

                color: #777777;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                padding: 15px 0;

                display: flex;
                justify-content: space-between;
                .export {
                    display: flex;
                    justify-content: flex-end;
                    gap: 6px;

                    margin: 12px;
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .grades {
                    display: flex;
                    flex-direction: column;
                    width: 125px;

                    .grade {
                        text-align: start;
                        justify-content: space-between;
                        display: flex;

                        div {
                            flex-grow: 1;
                            max-width: 2em;
                        }

                        .dot {
                            &_a {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #0abc06;
                            }
                            &_b {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f6931f;
                            }
                            &_c {
                                flex-grow: 0;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background-color: #f25b5b;
                            }
                        }
                    }
                }

                .chart {
                    flex-grow: 1;
                    justify-content: center;
                    justify-items: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
            }
        }
    }

    .label-float input {
        width: 100%;
    }

    .label-float input + label {
        border: none;
        // background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px !important;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: calc(50% - 5px) !important;
        left: 0 !important;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 11px !important;
        top: 15px !important;
        left: -5px !important;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }
</style>

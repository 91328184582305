<template>
  <div class="d-inline">
    <input
      @input="search()"
      v-model="this.$store.state.app.managerForm.search"
      class="searchbar fa"
      type="text"
      style="
        padding: 1% 17% 1% 1% !important;
        font-family: Open Sans, FontAwesome;
      "
      placeholder="&#xF002;  search.. "
    />
  </div>
  <div
    @click="this.$store.state.app.openModal = 'bookingCreate'"
    v-if="this.$store.state.app.managerForm.refSelects.length == 0"
    style="float: right"
  >
    <!-- <button class="btn btn-usfc" style="border-radius: 6px"><span>Book New Shipment</span> <span> + </span></button> -->
  </div>

  <div class="row my-3">
    <!-- <div class="d-inline">
      <span class="mx-2" style="cursor: pointer"><img src="/icons/custom/picker-deselect.svg" /></span>
      <span class="mx-4"> &emsp; </span>
      <span class="mx-2" style="cursor: pointer">
        Name
        <iconGroup class="blue-filter">
          <img src="/icons/all/twotone/arrow-up.svg" class="scaled" />
          <img src="/icons/all/twotone/arrow-down-1.svg" class="scaled" style="position: relative; left: -15px" />
        </iconGroup>
      </span>
    </div> -->
    <TableView />
  </div>
</template>

<script>
import TableView from "./TableView.vue";
import axios from "axios";
/* eslint-disable */

export default {
  name: "providerComponent",
  props: {},
  components: { TableView },
  data() {
    return {
      sortType: "ASC",
      page: 1,
      refSelects: [],
      admins: [],
      allReceipts: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
    };
  },
  computed: {
    getLoadForm() {
      return this.loadForm;
    },
    totalPages() {
      return Math.ceil(
        this.$store.state.app.managerForm.allReceipts.length / 10
      );
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
  },
  methods: {
    deleteMany() {
      let token = JSON.parse(localStorage.getItem("token"));
      if (
        confirm(
          "Delete " +
            this.$store.state.app.managerForm.refSelects.length +
            " selected provider(s)?"
        )
      )
        axios
          .delete(this.$store.state.app.apiBasepath + "/provider/delete", {
            headers: { Authorization: `Bearer ${token}` },
            data: {
              selectedId: this.$store.state.app.managerForm.refSelects,
            },
          })
          .then((res) => {
            this.$store.state.app.managerForm.refSelects = [];
            this.refreshModal();
          })
          .catch((err) => {
            console.log(err);
          });

      let temp = this.$store.state.app.managerForm.currentRefTab;
      this.$store.state.app.managerForm.currentRefTab = "";
      setTimeout(() => {
        this.$store.state.app.managerForm.currentRefTab = temp;
      }, 50);
    },
    toggleModal() {
      this.$store.state.app.overlay.blur = true;
    },
    ascSort() {
      function compare(a, b) {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allReceipts.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allReceipts.sort(compare);
      this.paginate();
    },
    search() {
      let token = JSON.parse(localStorage.getItem("token"));
      const access = this.$store.state.userData.access_level;
      let path;
      if (access === 2) path = "by-client";
      else if (access === 3) path = "by-sales";
      else if (access === 4 || access === 5) path = "all";
      else path = null;
      axios
        .get(
          `${this.$store.state.app.apiBasepath}/receipt/${path}?except=archived&search=` +
            this.$store.state.app.managerForm.search,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data.count > 0) {
            this.$store.state.app.managerForm.noResult = false;
            this.$store.state.app.managerForm.formLoading = false;
          } else {
            this.$store.state.app.managerForm.noResult = true;
            this.$store.state.app.managerForm.formLoading = false;
          }
          this.$store.state.app.managerForm.allReceipts = res.data.rows;
          this.ascSort();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredReceipts[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allReceipts.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.usfc-bg {
  background-color: #fbfbfb;
}

.btn {
  transition: all 0.15s ease;
}

.btn-usfc {
  background-color: #3699ff;
  color: white;
  font-size: 0.9em;
  padding: 10px 15px;
}

.btn-usfc:hover {
  background-color: white;
  color: black;
  border: 1px solid #3699ff;
}

.scaled {
  transform: scale(0.8);
}

.searchbar {
  background-color: #fbfbfb;
  border: none;
  border-radius: 6px;
  padding: 1% 17% 1% 1%;
}

.searchbar::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 0.9em;
}
.cancel:hover {
  font-weight: bold;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.cancel:active {
  font-weight: bold;
  border: solid 1px rgba(0, 0, 0, 1);
}

input.searchbar {
  border: solid 1px rgba(54, 153, 255, 0);
}

input.searchbar:focus {
  outline: none !important;
  border: solid 1px rgba(54, 153, 255, 0.5);
}

.red-filter {
  background: rgba(54, 153, 255, 0.1);
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.red-filter:hover {
  background: rgba(54, 153, 255, 0.25);
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

ul {
  height: 5%;
}

li {
  text-align: center;
  display: inline;
  padding: 1% 0;
  margin: 0;
  width: 8%;
  font-weight: 400;
  cursor: pointer;
  vertical-align: middle;
}

li.active {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  border: 1px solid transparent;
}

select {
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  appearance: none;
  top: 10px;
}

.label-float select {
  border-left: 2px solid rgba(0, 0, 0, 0.15);
  background-color: transparent;
  outline: none;
  margin-left: 20px;
  padding: 10px 20px;
  font-size: 14px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  left: -120px;
  cursor: pointer;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:hover {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}
</style>

<template>
    <div class="row p-2" style="background-color: transparent; height: 80vh">
        <div class="col" style="background: #fbfbfb">
            <div class="row h-100">
                <div class="col-3" style="background: #ffffff">
                    <div class="row w-100" style="height: 100%">
                        <!--  -->
                        <div class="col justify-content-center" style="height: 100%; position: relative">
                            <div class="row ms-4 ps-2 my-2 active" style="font-size: 12px; opacity: 0.5">
                                <div>Navigation</div>
                            </div>

                            <!--  -->
                            <div @click="scrollTo('#info')" id="info_nav" class="row mx-auto childform my-2">
                                <div>Client Info</div>
                            </div>

                            <!--  -->
                            <div @click="scrollTo('#proposed')" id="proposed_nav" class="row mx-auto childform my-2">
                                <div>Proposed Rate</div>
                            </div>

                            <!--  -->
                            <div @click="scrollTo('#original')" id="original_nav" class="row mx-auto childform my-2">
                                <div>Rate Details</div>
                            </div>

                            <div v-if="bodyForm.status == 3 && this.$store.state.userData.access_level == 5" id="actions" class="row mx-auto my-2 w-100" style="position: absolute; bottom: 0">
                                <div @click="approveRequest()" class="btn btn-usfc-primary my-2">Approve</div>

                                <div @click="rejectRequest()" class="btn btn-usfc text-danger">Reject</div>
                            </div>

                            <div v-else id="status" class="row mx-auto my-2 w-100" style="position: absolute; bottom: 0">
                                <div v-if="bodyForm.status == 3 && this.$store.state.userData.access_level < 5" class="btn btn-usfc text-warning">Pending</div>
                                <div v-if="bodyForm.status == 2" class="btn btn-usfc text-success">Approved</div>
                                <div v-if="bodyForm.status == 1" class="btn btn-usfc text-danger">Rejected</div>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                </div>

                <div class="col d-flex justify-content-center">
                    <div class="row w-100">
                        <div id="scrollable" class="col scroll-container">
                            <div id="info" class="row mx-auto my-2 mb-3 w-75 py-3 d-block" style="background: #ffffff">
                                <div class="col">
                                    <h4 class="m-0"><strong>Client Info</strong></h4>
                                    <div id="forms" class="row text-start d-flex flex-nowrap">
                                        <form onsubmit="return false">
                                            <div class="my-2">Company Name</div>
                                            <div class="label-float pt-0">
                                                <input id="fullname" type="text" placeholder=" " v-model="bodyForm.corporate.name" autocomplete="off" />
                                                <label></label>
                                            </div>

                                            <div class="my-2">Sales Name</div>
                                            <div class="label-float pt-0">
                                                <input id="email" type="text" placeholder=" " v-model="bodyForm.sales_full_name" autocomplete="off" />
                                                <label></label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div id="proposed" class="row mx-auto my-2 mb-3 w-75 py-3 d-block" style="background: #ffffff">
                                <div class="col">
                                    <h4 class="m-0"><strong>Proposed Rate</strong></h4>
                                    <div id="forms" class="row text-start d-flex flex-nowrap">
                                        <form onsubmit="return false">
                                            <div class="my-2">Original Rate</div>
                                            <div class="label-float pt-0">
                                                <input id="fullname" type="text" placeholder=" " v-model="bodyForm.old_rate" autocomplete="off" />
                                                <label></label>
                                            </div>
                                            <div class="my-2">New Rate</div>
                                            <div class="label-float pt-0">
                                                <input id="email" type="email" placeholder=" " v-model="bodyForm.new_rate" autocomplete="off" />
                                                <label></label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div id="original" class="row mx-auto my-2 mt-3 pt-3 w-75 d-block" style="background: #ffffff">
                                <div class="col">
                                    <h4 class="m-0"><strong>Rate Details</strong></h4>
                                    <form-wrapper>
                                        <div id="forms" class="row text-start d-flex flex-nowrap pb-3" style="overflow-y: scroll">
                                            <form style="max-height: 80%">
                                                <div class="label-float">
                                                    <input id="fullname" type="text" placeholder=" " v-model="bodyForm.origin.city" autocomplete="off" />
                                                    <label>Origin</label>
                                                </div>

                                                <div class="label-float">
                                                    <input id="fullname" type="text" placeholder=" " v-model="bodyForm.destination.city" autocomplete="off" />
                                                    <label>Destination</label>
                                                </div>
                                                <div class="label-float">
                                                    <input id="fullname" type="text" placeholder=" " v-model="bodyForm.shipping_type.name" autocomplete="off" />
                                                    <label>Shipping Type</label>
                                                </div>
                                                <div class="label-float">
                                                    <input id="fullname" type="text" placeholder=" " v-model="bodyForm.provider.name" autocomplete="off" />
                                                    <label>Provider</label>
                                                </div>
                                                <div class="label-float">
                                                    <input id="fullname" type="text" placeholder=" " v-model="bodyForm.service.name" autocomplete="off" />
                                                    <label>Service</label>
                                                </div>

                                                <div class="label-float">
                                                    <input @change="validateStage(0)" @input="checkNegativeInput('minimum_weight')" v-model="bodyForm.minimum_weight" id="minimum_weight" min="0" type="number" placeholder=" " autocomplete="off" />
                                                    <label>Minimum Weight (Kg)</label>
                                                </div>

                                                <h5 class="pt-3 ms-1">SLA</h5>
                                                <!--  -->
                                                <div class="row pt-0">
                                                    <div class="col pt-0">
                                                        <div class="label-float">
                                                            <input @change="validateStage(0)" @input="checkNegativeInput('day')" v-model="temp.day" id="day" type="number" min="0" placeholder=" " autocomplete="off" />
                                                            <label>Day(s)</label>
                                                        </div>
                                                    </div>
                                                    <div class="col pt-0">
                                                        <div class="label-float">
                                                            <input @change="validateStage(0)" @input="validateHourInput('hour')" v-model="temp.hour" id="hour" type="number" min="0" max="23" placeholder=" " autocomplete="off" />
                                                            <label>Hour(s)</label>
                                                        </div>
                                                    </div>
                                                    <!--  -->
                                                </div>
                                            </form>
                                        </div>
                                    </form-wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableView from "./TableView.vue";
    import $ from "jquery";
    import axios from "axios";
    /* eslint-disable */

    export default {
        name: "UserDetailComponent",
        props: {},
        components: { TableView },
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                currentTab: "company",
                edit: false,
                password: {
                    new: "",
                    conf: "",
                },
                newsales: null,
                currentSales: [],
                newclients: [],
                bodyForm: {
                    new_rate: null,
                    originId: null,
                    destinationId: null,
                    shippingTypeId: null,
                    providerId: null,
                    serviceId: null,
                    minimum_weight: null,
                    rate: null,
                    sla: null,
                    status: null,
                    corporate: {
                        name: null,
                    },
                    sales_full_name: null,
                    origin: {
                        city: null,
                    },
                    destination: {
                        city: null,
                    },
                    shipping_type: {
                        name: null,
                    },
                    provider: {
                        name: null,
                    },
                    service: {
                        name: null,
                    },
                },
                temp: {
                    day: null,
                    hour: null,
                },
                originList: [],
                destinationList: [],
                shippingTypeList: [],
                providerList: [],
                serviceList: [],
            };
        },
        computed: {
            admin() {
                return this.$store.state.app.managerForm.userDetailForm;
            },
            filteredSales() {
                if (this.$store.state.app.managerForm.formSearch == "") {
                    this.ascSort();
                    return this.$store.state.app.managerForm.filteredSales;
                }

                return this.$store.state.app.managerForm.allSales.filter((client) => {
                    return client.full_name.toLowerCase().includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
                });
            },
            filteredClients() {
                if (this.$store.state.app.managerForm.formSearch == "") {
                    this.ascSort();
                    return this.$store.state.app.managerForm.filteredClients;
                }

                return this.$store.state.app.managerForm.allClients.filter((client) => {
                    return client.full_name.toLowerCase().includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
                });
            },
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            adminDetails() {
                return this.getAdminDetails();
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            scrollTo(id) {
                $(id)[0].scrollIntoView({
                    behavior: "smooth", // or "auto" or "instant"
                    block: "start", // or "end"
                });
            },
            getDetail(id) {
                let token = JSON.parse(localStorage.getItem("token"));
                let access_level = this.$store.state.app.managerForm.userDetail;

                axios
                    .get(this.$store.state.app.apiBasepath + "/client-rate-request/details/" + id, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        // ;
                        this.bodyForm = res.data;
                        this.bodyForm.old_rate = "Rp " + res.data.old_rate.toLocaleString("id-ID");
                        this.bodyForm.new_rate = "Rp " + res.data.new_rate.toLocaleString("id-ID");

                        this.temp.day = Math.floor(res.data.sla / 24);
                        this.temp.hour = Math.floor(res.data.sla % 24);
                        this.bodyForm.status = res.data.status;
                        console.log("request number :" + res.data.request_number);
                        this.$store.state.app.breadcrumb.currentSubSubMenu = res.data.request_number;
                        this.$store.state.app.rateForm.request.currentId = this.$route.params.id;
                        if (this.admin.salesId != null) {
                            this.getCurrentSalesDetails();
                        }
                    })
                    .catch(() => {
                        // this.$alertSys("Unauthorized", "err");;
                    });
            },
            approveRequest() {
                let token = JSON.parse(localStorage.getItem("token"));
                let access_level = this.$store.state.app.managerForm.userDetail;

                axios
                    .post(this.$store.state.app.apiBasepath + "/rate/approve-request?request_number=" + this.$store.state.app.breadcrumb.currentSubSubMenu, {}, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log(res.data);
                        this.getDetail(this.$route.params.id);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$alertSys("An error occured", "ERR");
                    });
            },
            rejectRequest() {
                let token = JSON.parse(localStorage.getItem("token"));
                let access_level = this.$store.state.app.managerForm.userDetail;

                axios
                    .post(this.$store.state.app.apiBasepath + "/rate/reject-request?request_number=" + this.$store.state.app.breadcrumb.currentSubSubMenu, {}, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log(res.data);
                        this.getDetail(this.$route.params.id);
                    })
                    .catch(() => {
                        this.$alertSys("An error occured", "ERR");
                    });
            },
        },
        mounted() {
            document.getElementById("scrollable").addEventListener("scroll", (event) => {
                let scroll = $("#scrollable").scrollTop();
                if (scroll < $("#info").offset().top) {
                    $("#info_nav").addClass("active");
                    $("#proposed_nav").removeClass("active");
                    $("#original_nav").removeClass("active");
                } else if (scroll < $("#original").offset().top) {
                    $("#info_nav").removeClass("active");
                    $("#proposed_nav").addClass("active");
                    $("#original_nav").removeClass("active");
                } else {
                    $("#info_nav").removeClass("active");
                    $("#proposed_nav").removeClass("active");
                    $("#original_nav").addClass("active");
                }
                console.log($("#original").offset().top);
                console.log(scroll);
                console.log(scroll < $("#original").offset().top);
            });

            $("#info_nav").addClass("active");
            $("#scrollable").animate(
                {
                    scrollTop: 0,
                },
                400,
                "swing"
            );
            // this.getAllSales();

            // this.getAllClients();
            $("input").prop("disabled", true);
            $("select").prop("disabled", true);
            this.getDetail(this.$route.params.id);

            // setTimeout(() => {
            //   if (this.admin.access_level !== 2) {
            //     this.currentTab = "details";
            //   }
            // }, 250);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .scroll-container {
        overflow-y: scroll;
        height: 78vh;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .scroll-container::-webkit-scrollbar {
        display: none;
    }
    .deleteaccount {
        transition: all 0.3s ease;
        font-size: 14px;
        cursor: pointer;
    }

    span.text-danger:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .deleteaccount:hover {
        text-decoration: underline;
    }

    #forms {
        overflow-y: scroll;
        border: 1px solid transparent;
    }

    #forms.company {
        height: 100%;
    }

    #userDetails {
        width: 550px;
        padding: 0;
    }

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: relative;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgProfile {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: relative;
        bottom: 25px;
        left: 75px;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .childform {
        padding-left: 15px;
        line-height: 40px;
        border-radius: 6px;
        min-width: 150px;
        width: 90%;
        cursor: pointer;
    }

    .childform:hover {
        background-color: rgba(0, 0, 0, 0.15);
        filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg) brightness(103%) contrast(103%);
    }

    .childform.active {
        background-color: rgba(0, 0, 0, 0.15);
        filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg) brightness(103%) contrast(103%);
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    input:disabled {
        color: rgba(0, 0, 0, 0.4);
    }
    select:disabled > option {
        color: rgba(0, 0, 0, 0.4);
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .btn.btn-usfc-edit {
        min-width: 120px;
        color: #3699ff;
        background-color: white;
        border-radius: 6px;
        padding: 10px 25px;
        border: 1px solid #3699ff;
    }

    .btn-usfc-edit:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>

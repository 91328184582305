import { createStore } from "vuex";
import adminModule from "./admin/store.module.js";
import dashboard from "./dashboard.js";

export default createStore({
    // Modules dapat ditambahkan sesuai banyaknya komponen aplikasi
    modules: {
        admin: adminModule,
        dashboard,
    },

    state: {
        showLoader: false,
        app: {
            watchForUpdates: false,
            currentBodyForm: {},
            hasUnreadNotification: false,
            userNotification: [],
            sessionTOken: null,
            screenBlock: {
                dash: false,
                main: false,
                secondary: false,
            },
            MainPopup: {
                type: "",
                message: "",
            },
            modalData: {
                id: null,
                data: [],
            },
            preloader: {
                on: false,
            },
            breadcrumb: {
                currentSubSubMenu: "",
            },
            apiBasepath: process.env.VUE_APP_API_URL,
            showEtcs: false,
            emailReset: "",
            managerForm: {
                currentComp: "",
                currentRefTab: "origin",
                currentReqTab: "approval",
                currentReceiptTab: "active",
                userDetail: "",
                currentForm: "",
                search: "",
                formSearch: "",
                secondFormSearch: "",
                selectedSales: null,
                filteredAdmins: [],
                filteredClients: [],
                filteredSales: [],
                filteredTransporter: [],
                filteredReceipts: [],
                filteredBookings: [],
                filteredRequests: [],
                idSelects: [],
                allAdmins: [],
                allClients: [],
                allSales: [],
                allTransporter: [],

                filteredOrigin: [],
                filteredDestination: [],
                filteredShipping: [],
                filteredProvider: [],
                refSelects: [],
                allOrigin: [],
                allRequests: [],
                allBookings: [],
                allReceipts: [],
                allDestination: [],
                allShipping: [],
                allProvider: [],
                currentTab: "admin",
                refDetailForm: [],
                userDetailForm: [],

                noResult: false,
                formLoading: true,
                noResult_type: "",
            },
            openModal: "",
            rateForm: {
                checkRates: {
                    filters: {
                        mode: "Public",
                        origin: "",
                        destination: "",
                        shipping_type: "",
                        service: "",
                        provider: "",
                    },
                    data: {
                        allData: [],
                        origins: [],
                        destinations: [],
                        shipping_types: [],
                        providers: [],
                        display: [],
                    },
                },
                public: {
                    selects: [],
                    search: "",
                    filteredRates: [],
                    allRates: [],
                    filters: {
                        active: "Origin",
                        available: ["Origin", "Destination", "Shipping Type", "Provider", "Service"],
                    },
                },
                client: {
                    selects: [],
                    search: "",
                    filteredRates: [],
                    allRates: [],
                    filters: {
                        active: "Origin",
                        available: ["Origin", "Destination", "Shipping Type", "Provider", "Service"],
                    },
                },
                request: {
                    currentTab: "",
                    currentId: "",
                },
            },
            recipientsForm: {
                allRecipients: [],
                filteredRecipients: [],
            },
            bookingsForm: {
                currentTab: "",
                currentId: "",
            },
        },
        tempData: null,
        userData: {
            id: 20,
            username: "Default",
            full_name: "Default User",
            email: "default@default.com",
            password: "31a1b35ca36cb4b3767e6e9fcf6da9d8945b2e63",
            access_level: 1,
            photo: null,
            birth_date: "2000-01-09",
            nationality: "English",
            gender: "Male",
            company: null,
            deviceId: null,
            street: null,
            district: null,
            province: null,
            city: null,
            postal_code: null,
            phone: null,
            createdAt: "2022-09-21T09:05:50.000Z",
            updatedAt: "2022-09-21T09:05:50.000Z",
            salesId: null,
        },
        loginData: {
            remember: "false",
            logged: "false",
        },
        globalStates: {
            err: {
                stat: false,
                code: "404",
                desc: "Page Not Found",
            },
        },
        alert: {
            message: null,
            type: null,
        },
    },
    mutations: {
        // Mengakses
        set_userdata(state, payload) {
            state.userData.username = payload.username;
            // FILL AS NEEDED
        },
        tablePreloader(state, payload) {
            if (payload.status == "start") {
                state.app.managerForm.noResult = false;
                state.app.managerForm.formLoading = true;
            } else if (payload.status == "success") {
                state.app.managerForm.noResult = false;
                state.app.managerForm.formLoading = false;
            } else if (payload.status == "noresult") {
                state.app.managerForm.noResult = true;
                state.app.managerForm.formLoading = false;
            }
        },
    },
    actions: {
        // Method untuk dipanggil pada Vue Components
        // Context == this, kontekstual terhadap store
        update_user(context, payload) {
            const newUserData = context.state.userData;
            newUserData.push(payload);
            context.commit("set_userdata", newUserData);
        },
    },
    getters: {
        getUserData: function (state) {
            // Syntax dibawah contoh return sebuah string preprocessed data
            // Jika tidak, bisa langsung return all data dengan payload json
            // return `My name is ${state.userData.fullname}, Call me ${state.userData.nickname}`;
            return state.userData;
        },
    },
});

<template>
  <div class="waybill-container">
    <div id="waybill-print" v-if="bodyForm && bodyForm.details">
      <div class="waybill-document">
        <div class="main-page">
          <div class="main-header">
            <div>
              <img src="/usfc_monogram.svg" style="height: 32px" />
            </div>
            <div class="receipt-date">
              <div class="info">
                <div>TANGGAL</div>
                <div>
                  {{ dateFormat(bodyForm.details.pickup_date).toUpperCase() }}
                </div>
              </div>
              <div class="info">
                <div>JAM OUT</div>
                <div>
                  {{
                    dateFormat(
                      bodyForm.details.pickup_date,
                      "HH:MM"
                    ).toUpperCase()
                  }}
                </div>
              </div>
              <div class="info">
                <div>WAKTU CETAK</div>
                <div>
                  {{ dateFormat(undefined, "DD MMM YYYY HH:MM").toUpperCase() }}
                </div>
              </div>
            </div>
          </div>
          <div class="company">
            <div>PT UNIVESERV FORTUNA CEMERLANG</div>
            <div>JL. BY PASS KM 7 RT. 002 RW.001, KEL. PISANG</div>
            <div>KEC. PAUH, KOTA PADANG</div>
          </div>
          <div class="spacer black">
            <div>SURAT JALAN</div>
            <div class="italic">
              {{ bodyForm.details.booking_number }}
            </div>
          </div>
          <div class="dashed">
            <div class="line">
              <div class="info">
                <div>NO. POLISI</div>
                <div>{{ bodyForm.plate_number || "-" }}</div>
              </div>
              <div class="info">
                <div>DRIVER</div>
                <div>
                  {{
                    findTransporter
                      ? findTransporter.full_name.toUpperCase()
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <div class="line">
              <div class="info">
                <div>NO. TELP DRIVER</div>
                <div>
                  {{
                    findTransporter ? findTransporter.phone.toUpperCase() : "-"
                  }}
                </div>
              </div>
              <div class="info">
                <div>JENIS KENDARAAN</div>
                <div>
                  {{ bodyForm.details.rate.shipping_type.name.toUpperCase() }}
                </div>
              </div>
            </div>
            <div class="line">
              <div class="info">
                <div>RITASE</div>
                <div>{{ "-" }}</div>
              </div>
            </div>
          </div>
          <div class="destination">
            <div class="info">
              <div>TUJUAN :</div>
              <div class="title" style="font-weight: 600">
                {{ bodyForm.details.recipient_company.company.toUpperCase() }}
              </div>
              <div>
                {{
                  bodyForm.details.recipient_company &&
                  bodyForm.details.recipient_company.street
                    ? bodyForm.details.recipient_company.street.toUpperCase()
                    : ""
                }},
                {{
                  bodyForm.details.recipient_company &&
                  bodyForm.details.recipient_company.district
                    ? bodyForm.details.recipient_company.district.toUpperCase()
                    : ""
                }},
                {{
                  bodyForm.details.recipient_company &&
                  bodyForm.details.recipient_company.city
                    ? bodyForm.details.recipient_company.city.toUpperCase()
                    : ""
                }},
                {{
                  bodyForm.details.recipient_company &&
                  bodyForm.details.recipient_company.province
                    ? bodyForm.details.recipient_company.province.toUpperCase()
                    : ""
                }}.
                {{
                  bodyForm.details.recipient_company &&
                  bodyForm.details.recipient_company.postal_code
                    ? bodyForm.details.recipient_company.postal_code.toUpperCase()
                    : ""
                }}
              </div>
            </div>
            <div class="info">
              <div>LAYANAN :</div>
              <div>{{ bodyForm.details.rate.service.name }}</div>
            </div>
          </div>
          <div style="width: 100%; text-align: start; padding: 6px 22px">
            Dikirimkan barang barang sebagai berikut :
          </div>
          <div class="table-container">
            <table style="text-align: center !important">
              <tr>
                <th>NO</th>
                <th>INFO BARANG</th>
                <th>PANJANG (CM)</th>
                <th>LEBAR (CM)</th>
                <th>TINGGI (CM)</th>
                <th>BERAT AKTUAL (KG)</th>
                <th>BERAT TERHITUNG (KG)</th>
              </tr>
              <tr v-for="index in 15" :key="index">
                <template v-if="bodyForm.bagging[index - 1]">
                  <td>{{ index }}</td>
                  <td>{{ bodyForm.bagging[index - 1].good_details || "" }}</td>
                  <td>{{ bodyForm.bagging[index - 1].long || "" }}</td>
                  <td>{{ bodyForm.bagging[index - 1].width || "" }}</td>
                  <td>{{ bodyForm.bagging[index - 1].height || "" }}</td>
                  <td>{{ bodyForm.bagging[index - 1].actual_weight || "" }}</td>
                  <td>
                    {{ bodyForm.bagging[index - 1].accounted_weight || "" }}
                  </td>
                </template>
                <template v-else>
                  <td>{{ index }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td
                ></template>
              </tr>
            </table>
          </div>
        </div>
        <div class="signatures">
          <div class="signature-item">
            <div>DRIVER</div>
            <div>
              (
              {{
                findTransporter ? findTransporter.full_name.toUpperCase() : "-"
              }}
              )
            </div>
          </div>
          <div class="signature-item">
            <div>PENERIMA</div>
            <div>
              (
              {{
                bodyForm.details.recipient_company.pic_name.toUpperCase() || "-"
              }}
              )
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="min-height: 90vh">
    <div id="modalTitle" class="row">
      <div class="col p-2">
        <h5>
          <span class="p-2" style="font-weight: 700; float: left"
            >Booking Approval</span
          >
          <span @click="this.$store.state.app.openModal = ''" id="closeModal"
            ><img
              class="d-flex justify-content-center"
              src="/icons/all/outline/add.svg"
          /></span>
        </h5>
      </div>
    </div>
    <div id="stages" class="row d-flex flex-nowrap">
      <template v-for="stage in stages" v-bind:key="stage.name">
        <!-- <span class="tracker" style="opacity: 0; width: 0; transition: all 0.4s ease" :style="stage.status == 2 ? 'width: 32px; opacity: 1;' : ''">
                    <Transition name="fade" v-if="stage.status == 2"> <i class="fa fa-check" aria-hidden="true"></i> </Transition>
                </span> -->
        <div
          @click="stageIndex = stage.index"
          style="transition: all 0.4s ease"
          :class="stageIndex === stage.index ? 'active' : ''"
          class="col-auto tab"
        >
          {{ stage.name }}
        </div>
      </template>
      <!-- <div class="col-5">
                <span class="tracker"> </span>
            </div> -->
    </div>
    <form-wrapper v-if="stageIndex === 0">
      <div class="height-limit">
        <div class="label-float">
          <input
            v-model="bodyForm.plate_number"
            id="name"
            type="text"
            placeholder=" "
            autocomplete="off"
          />
          <label>Vehicle Plate Number</label>
        </div>

        <div v-if="bodyForm.transporter" class="row my-1">
          <div class="text-center" style="font-size: 14px">
            Primary Transporter
          </div>
          <div class="row form-border">
            <div class="col-2">
              <img
                v-if="bodyForm.transporter.photo === null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  bodyForm.transporter.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
            </div>
            <div class="col">
              <div class="row">{{ bodyForm.transporter.full_name }}</div>
              <div class="row">{{ bodyForm.transporter.phone }}</div>
            </div>
          </div>
          <template v-if="bodyForm.secondary_transporter">
            <div class="text-center" style="font-size: 14px">
              Secondary Transporter
            </div>
            <div class="row form-border">
              <div class="col-2">
                <img
                  v-if="bodyForm.secondary_transporter.photo === null"
                  src="/icons/default.svg"
                  style="
                    object-fit: cover;
                    height: 42px;
                    border-radius: 50%;
                    width: 42px;
                  "
                />
                <img
                  v-else
                  :src="
                    'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                    bodyForm.transporter.photo
                  "
                  style="
                    object-fit: cover;
                    height: 42px;
                    border-radius: 50%;
                    width: 42px;
                  "
                />
              </div>
              <div class="col">
                <div class="row">
                  {{ bodyForm.secondary_transporter.full_name }}
                </div>
                <div class="row">
                  {{ bodyForm.secondary_transporter.phone }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else id="forms" class="row text-start">
          <form class="form-border">
            <div class="text-center" style="font-size: 14px">
              Primary Transporter*
            </div>
            <div class="label-float">
              <div class="d-inline">
                <input
                  v-model="this.$store.state.app.managerForm.formSearch"
                  class="searchbar fa"
                  type="text"
                  style="
                    padding: 1% 17% 1% 1%;
                    font-family: Open Sans, FontAwesome;
                  "
                  placeholder="&#xF002;  search.. "
                />
              </div>
            </div>
            <br />
            <div class="px-3 py-0 my-0 prevent-select">
              <table
                class="table"
                v-if="this.$store.state.app.managerForm.formSearch != ''"
              >
                <tbody style="height: 15vh">
                  <tr
                    @click="choosePrimaryTransporter(sales.id)"
                    class="sales"
                    v-for="sales in filteredTransporter"
                    v-bind:key="sales.id"
                  >
                    <th scope="row" class="col-2">
                      <img
                        src="/icons/default_company.svg"
                        style="
                          object-fit: cover;
                          height: 42px;
                          border-radius: 50%;
                          width: 42px;
                        "
                      />
                    </th>
                    <td class="col text-start">
                      <span style="line-height: 2.5em">{{
                        sales.full_name
                      }}</span>
                    </td>
                    <td
                      v-if="bodyForm.userId === sales.id"
                      scope="row"
                      style="position: relative; top: 5px"
                    >
                      <span style="float: right">
                        <img src="/icons/custom/picker.svg" />
                      </span>
                    </td>
                    <td v-else scope="row" style="position: relative; top: 5px">
                      <span style="float: right">
                        <img src="/icons/custom/picker-deselect.svg" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="text-center"
                v-else
                style="font-size: 14px; opacity: 0.4"
              >
                Type the transporter name above
              </div>
            </div>
          </form>
          <form class="form-border">
            <div class="text-center" style="font-size: 14px">
              Secondary Transporter
            </div>
            <div class="label-float">
              <div class="d-inline">
                <input
                  v-model="this.$store.state.app.managerForm.secondFormSearch"
                  class="searchbar fa"
                  type="text"
                  style="
                    padding: 1% 17% 1% 1%;
                    font-family: Open Sans, FontAwesome;
                  "
                  placeholder="&#xF002;  search.. "
                />
              </div>
            </div>
            <br />
            <div class="px-3 py-0 my-0 prevent-select">
              <table
                class="table"
                v-if="this.$store.state.app.managerForm.secondFormSearch != ''"
              >
                <tbody style="height: 15vh">
                  <tr
                    @click="chooseSecondaryTransporter(sales.id)"
                    class="sales"
                    v-for="sales in filteredSecondaryTransporter"
                    v-bind:key="sales.id"
                  >
                    <th scope="row" class="col-2">
                      <img
                        src="/icons/default_company.svg"
                        style="
                          object-fit: cover;
                          height: 42px;
                          border-radius: 50%;
                          width: 42px;
                        "
                      />
                    </th>
                    <td class="col text-start">
                      <span style="line-height: 2.5em">{{
                        sales.full_name
                      }}</span>
                    </td>
                    <td
                      v-if="bodyForm.secondary_transporterId === sales.id"
                      scope="row"
                      style="position: relative; top: 5px"
                    >
                      <span style="float: right">
                        <img src="/icons/custom/picker.svg" />
                      </span>
                    </td>
                    <td v-else scope="row" style="position: relative; top: 5px">
                      <span style="float: right">
                        <img src="/icons/custom/picker-deselect.svg" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="text-center"
                v-else
                style="font-size: 14px; opacity: 0.4"
              >
                Type the transporter name above
              </div>
            </div>
          </form>
        </div>
      </div>
    </form-wrapper>
    <form-wrapper v-show="stageIndex === 1">
      <selectLocation
        @openSelector="showSelector"
        :show="show"
        @onUpdate="gasLocationsUpdate"
        :locations="gasLocations"
      />
    </form-wrapper>
    <form-wrapper v-if="stageIndex === 2">
      <div
        id="forms"
        class="row m-2"
        style="overflow-y: auto"
        :style="isApproved ? 'max-height: 75vh' : 'max-height: 65vh'"
      >
        <div class="col-12 mb-3" style="border: 1px dashed black">
          <div
            class="row text-start py-2"
            style="background: rgba(0, 0, 0, 0.1)"
          >
            <div style="font-weight: 600; font-size: 16px">Estimated Cost</div>
          </div>
          <div class="p-2">
            <div class="row">
              <div class="col">
                <div
                  class="row pb-2 mb-1"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
                >
                  <div class="col text-start">Rate</div>
                  <div class="col text-start"></div>
                  <div class="col text-start">Weight (kg)</div>
                  <div class="col text-start"></div>
                  <div class="col text-end">Amount</div>
                </div>
                <div class="row">
                  <div class="col text-start">
                    {{ `Rp ${getBodyForm.rate.rate.toLocaleString()}` }}
                  </div>
                  <div class="col">x</div>
                  <div class="col text-start">
                    {{
                      `${getBodyForm.total_accounted_weight.toLocaleString()} (Kg)`
                    }}
                  </div>
                  <div class="col">=</div>
                  <div class="col text-end">
                    Rp {{ Number(calculateEstimation).toLocaleString() }}
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div class="row" style="font-weight: 600; font-size: 18px">
              <div class="col text-start"></div>
              <div class="col text-end">
                Rp {{ Number(calculateEstimation).toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3" style="border: 1px dashed black">
          <div
            class="row text-start py-2"
            style="background: rgba(0, 0, 0, 0.1)"
          >
            <div style="font-weight: 600; font-size: 16px">Additional Fee</div>
          </div>
          <div
            v-if="
              getApproverData.insurance_fee ||
              getApproverData.repack_fee ||
              getApproverData.other_fee
            "
          >
            <div class="p-2">
              <div class="row" v-if="getApproverData.insurance_fee">
                <div class="col text-start">
                  Item Value: Rp
                  {{
                    Number(
                      getApproverData.insurance_item_value
                    ).toLocaleString()
                  }}
                </div>
                <div class="col text-end">
                  Rp
                  {{ Number(getApproverData.insurance_fee).toLocaleString() }}
                  (Insurance)
                </div>
              </div>
              <div class="row" v-if="getApproverData.repack_fee">
                <div class="col text-start">
                  {{ getApproverData.repack_value || "(Not Available)" }}:
                </div>
                <div class="col text-end">
                  Rp
                  {{ Number(getApproverData.repack_fee).toLocaleString() }}
                  (Repack)
                </div>
              </div>
              <div class="row" v-if="getApproverData.other_fee">
                <div class="col text-start">
                  {{ getApproverData.other_value || "(Not Available)" }}:
                </div>
                <div class="col text-end">
                  Rp
                  {{ Number(getApproverData.other_fee).toLocaleString() }}
                  (Other)
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="row" style="font-weight: 600; font-size: 18px">
                <div class="col text-start"></div>
                <div class="col text-end">
                  Rp {{ Number(calculateAdditional).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="p-2">
            <div class="row m-2">
              <div class="row text-center">
                <div class="col" style="font-weight: 600; font-size: 16px">
                  No additional fee added
                </div>
              </div>
              <!-- <div class="row my-2 text-center">
                                <div class="col">Confirm all the details are correct before submitting!</div>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-12" style="border: 1px dashed black">
          <div
            class="row text-start py-2"
            style="background: rgba(0, 0, 0, 0.1)"
          >
            <div style="font-weight: 600; font-size: 16px">Total Cost</div>
          </div>
          <div class="p-2">
            <div class="row">
              <div class="col text-start">Estimated Cost</div>
              <div v-if="getBodyForm.promotion_nominal" class="col text-end">
                Rp
                {{ Number(calculateEstimation).toLocaleString() }}
              </div>
              <div v-else class="col text-end">
                Rp {{ Number(calculateEstimation).toLocaleString() }}
              </div>
            </div>
            <div class="row">
              <div class="col text-start">Additional Fee</div>
              <div class="col text-end">
                Rp {{ Number(getApproverData.added_cost).toLocaleString() }}
              </div>
            </div>
            <div v-if="getBodyForm.promotion_nominal" class="row">
              <div class="col text-start">
                Promo Code : {{ getBodyForm.promotion_code }}
              </div>
              <div class="col text-end" style="color: #f25b5b">
                - Rp
                {{ Number(getBodyForm.promotion_nominal).toLocaleString() }}
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col text-start">Subtotal</div>
              <div class="col text-end">
                Rp {{ Number(calculateTotalCost).toLocaleString() }}
              </div>
            </div>
            <div class="row">
              <div class="col text-start">
                {{ getApproverData.tax_value || "(Not Available)" }}:
              </div>
              <div class="col text-end">
                Rp {{ Number(getApproverData.tax_fee).toLocaleString() }}
              </div>
            </div>

            <br />
            <div class="row" style="font-weight: 600; font-size: 18px">
              <div class="col text-start">Final Cost:</div>
              <div class="col text-end" style="color: #3699ff">
                Rp {{ calculateTotalCostAfterTax.toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row my-2">
            Confirm all the details are correct before submitting!
          </div>
        </div>
      </div>
    </form-wrapper>

    <div v-if="!show" id="navigation" class="row my-2 p-0" style="right: 10px">
      <div class="col">
        <span
          v-if="stages.length == stageIndex + 1"
          @click="approve()"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
          >Approve Booking</span
        >
        <button
          :disabled="!bodyForm.userId && !this.transporterIds.length"
          v-else
          @click="nextStage()"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
        >
          Next
        </button>
        <span
          @click="this.$store.state.app.openModal = ''"
          v-if="stageIndex == 0"
          style="float: right"
          class="mx-2 btn btn-cancel"
        >
          Cancel</span
        >
        <span
          @click="prevStage()"
          v-else
          style="float: right"
          class="mx-2 btn btn-back"
        >
          Back</span
        >
      </div>
    </div>
    <div
      v-if="
        stageIndex === 1 &&
        [2, 3].includes(bodyForm.details.booking_status) &&
        (this.gasLocations != this.tempGasLocations) & !show
      "
      id="navigation"
      class="row my-2 p-0"
      style="right: 10px"
    >
      <div class="col">
        <span
          @click="saveGasLocations()"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
        >
          Update Gas Stations</span
        >
        <span
          @click="this.gasLocations = this.tempGasLocations"
          style="float: right"
          class="mx-2 btn btn-cancel"
          >Revert</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import "jquery/dist/jquery.js";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import $ from "jquery";
import axios from "axios";
import selectLocation from "./selectLocation.vue";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "adminCreate",
  components: { selectLocation },
  computed: {
    findTransporter() {
      const list = this.$store.state.app.managerForm.allTransporter;
      let result;
      if (list && this.bodyForm.userId) {
        list.forEach((transporter) => {
          if (transporter.id === this.bodyForm.userId) result = transporter;
        });
      }

      return result;
    },
    calculateEstimation() {
      if (this.isApproved) return this.getBodyForm.total_cost;
      return this.getBodyForm.total_cost;
    },
    // calculateEstimation() {
    //     const taxRevert = 1.011;
    //     if (this.isApproved) return (+this.calculateTotalCostAfterTax - +this.calculateAdditional - +this.getApproverData.tax_fee) / taxRevert;
    //     return +this.calculateTotalCostAfterTax - +this.calculateAdditional - +this.getApproverData.tax_fee;
    // },
    calculateAdditional() {
      return (
        this.getApproverData.insurance_fee +
        this.getApproverData.repack_fee +
        this.getApproverData.other_fee
      );
    },
    calculateTotalCost() {
      return (
        this.calculateEstimation +
        this.calculateAdditional -
        this.getApproverData.promotion_nominal
      );
    },
    // calculateTotalCost() {
    //     return (this.isApproved ? +this.getBodyForm.total_cost - +this.getApproverData.tax_fee : +this.getBodyForm.total_cost) + (this.isApproved ? 0 : +this.calculateAdditional);
    // },
    calculateTotalCostAfterTax() {
      if (this.isApproved)
        return (
          this.getBodyForm.total_cost +
          this.getApproverData.tax_fee -
          this.getApproverData.promotion_nominal
        );
      // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL 0.011
      return this.calculateTotalCost * 0 + this.calculateTotalCost;
    },
    // calculateTotalCostAfterTax() {
    //     if (this.isApproved) return this.calculateTotalCost;
    //     return +this.calculateTotalCost + +this.getApproverData.tax_fee;
    // },
    isApproved() {
      return this.getBodyForm.booking_status !== 4;
    },
    getBodyForm() {
      return this.$store.state.app.currentBodyForm;
    },
    getApproverData() {
      return this.getBodyForm.approver;
    },
    filteredTransporter() {
      if (this.$store.state.app.managerForm.formSearch == "") {
        this.ascSort();
        return this.$store.state.app.managerForm.filteredTransporter;
      }

      return this.$store.state.app.managerForm.allTransporter.filter(
        (client) => {
          return client.full_name
            .toLowerCase()
            .includes(
              this.$store.state.app.managerForm.formSearch.toLowerCase()
            );
        }
      );
    },
    filteredSecondaryTransporter() {
      if (this.$store.state.app.managerForm.secondFormSearch == "") {
        this.ascSort();
        return this.$store.state.app.managerForm.filteredTransporter;
      }

      return this.$store.state.app.managerForm.allTransporter.filter(
        (client) => {
          return client.full_name
            .toLowerCase()
            .includes(
              this.$store.state.app.managerForm.secondFormSearch.toLowerCase()
            );
        }
      );
    },
    currentRouteName() {
      return this.$route.name;
    },
    userFullName() {
      if (this.$store.state.userData.full_name.length > 30) {
        return this.$store.state.userData.full_name.substring(0, 28) + "...";
      } else {
        return this.$store.state.app.userData.full_name;
      }
    },
    role() {
      if (this.$store.state.userData.access_level == 5) {
        return "Manager";
      } else if (this.$store.state.userData.access_level == 4) {
        return "Admin";
      } else if (this.$store.state.userData.access_level == 3) {
        return "Sales";
      } else if (this.$store.state.userData.access_level == 2) {
        return "Client";
      } else {
        return "Transporter";
      }
    },
  },
  data() {
    return {
      show: false,
      photo64: "",
      photoFile: "",
      access_level: 2,
      stageIndex: 0,
      pwdconf: "",
      stages: [
        // status (0: upcoming, 1: active, 2: done)
        {
          name: "Choose Transporter",
          status: 1,
          index: 0,
        },
        {
          name: "Gas Station Locations",
          status: 0,
          index: 1,
        },
        {
          name: "Order Summary",
          status: 0,
          index: 2,
        },
      ],
      bodyForm: {
        full_name: "",
        email: "",
        access_level: 2,
        username: "",
        phone: "",
        password: "",
        birth_date: "",
        nationality: "",
        gender: "",
        photo: "",
        company: "",
        street: "",
        district: "",
        province: "",
        city: "",
        postal_code: "",
        userId: null,
        secondary_transporterId: null,
      },
      transporterIds: [],
      tempGasLocations: [],
      gasLocations: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
    };
  },
  methods: {
    async generateWaybill() {
      document.body.style.overflow = "hidden";

      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          const element = document.getElementById("waybill-print");
          const options = {
            pagesplit: true,
            pagebreak: { mode: "css" },
            jsPDF: {
              unit: "mm",
              format: "a4",
              orientation: "portrait",
            },
            html2canvas: {
              dpi: 192,
              scale: 4,
              useCORS: true,
            },
          };

          try {
            const pdfDataUri = await html2pdf()
              .from(element)
              .set(options)
              .output("datauristring");

            // Convert data URI to Blob
            const byteString = atob(pdfDataUri.split(",")[1]);
            const mimeString = pdfDataUri
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
              uint8Array[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: mimeString });

            // Create a File object from Blob
            const filename = "waybill.pdf"; // You can specify a filename here
            const file = new File([blob], filename, { type: mimeString });

            document.body.style.overflow = "auto";
            resolve(file);
          } catch (error) {
            reject(error);
          }
        }, 150);
      });
    },
    dateFormat(date = new Date(), format = "DD MMM YYYY") {
      return moment(date).lang("id").format(format);
    },
    isSelected(id) {
      if (this.transporterIds.includes(id)) return true;
      return false;
    },

    async getDetail(id) {
      let token = JSON.parse(localStorage.getItem("token"));

      const data = await axios
        .get(
          this.$store.state.app.apiBasepath + "/booking-receipt/details/" + id,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          this.$store.state.app.breadcrumb.currentSubSubMenu =
            res.data.details.booking_number;

          return res.data;
        });

      if (data) {
        this.bodyForm = data;

        if (data.transporter) this.stages[0].name = "Transporter";
        if (this.$store.state.userData.access_level < 4)
          this.stages.splice(1, 1);
      }

      const gas = await axios
        .get(
          this.$store.state.app.apiBasepath +
            "/booking-receipt/gas-station-locations/" +
            id,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          return res.data.rows;
        });

      if (gas) {
        this.gasLocations = gas;

        await Promise.all(
          this.gasLocations.map(async (gas) => {
            gas.lat = gas.latitude;
            gas.lng = gas.longitude;
            gas.formatted_address = await this.getLocDetails({
              lat: gas.lat,
              lng: gas.lng,
            });
            console.log(gas, "rob");
            return gas;
          })
        );

        this.tempGasLocations = this.gasLocations;
      }
    },

    async getLocDetails(coords) {
      try {
        const loader = new Loader({
          apiKey: "AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM", // Replace with your actual Google Maps API key
          version: "weekly",
          token: this.$store.state.app.sessionToken,
        });

        const google = await loader.load();

        const geocoder = new google.maps.Geocoder();

        // Geocode the coordinates to get place details
        geocoder.geocode({ location: coords }, (results, status) => {
          if (status === "OK" && results[0]) {
            return results[0].formatted_address;
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    async saveGasLocations() {
      let token = JSON.parse(localStorage.getItem("token"));
      const basepath = this.$store.state.app.apiBasepath;

      //localhost:2002/dev/usfc/booking-receipt/gas-station-locations/103?gasLocationId=2

      const data = await axios.delete(
        basepath +
          "/booking-receipt/gas-station-locations/" +
          this.$route.params.id,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const promises = this.gasLocations.map((obj) => {
        return new Promise((resolve, reject) => {
          const modifiedObj = {
            title: obj.title,
            latitude: obj.lat,
            longitude: obj.lng,
          };

          axios
            .post(
              basepath +
                "/booking-receipt/gas-station-locations/" +
                this.$route.params.id,
              modifiedObj,
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              resolve(response.data); // Resolve the promise with the response data
            })
            .catch((error) => {
              reject(error); // Reject the promise with the error
            });
        });
      });

      if (data) {
        Promise.all(promises);
        this.tempGasLocations = this.gasLocations;

        console.log(this.tempGasLocations === this.gasLocations, "same");
      }
      // Execute all promises
    },
    gasLocationsUpdate(e) {
      console.log(this.tempGasLocations, "aaa");
      this.gasLocations = e;
      this.show = false;

      console.log(this.gasLocations, "parent");
    },
    showSelector(e) {
      this.show = e;

      console.log(e, "openselector");
    },
    choosePrimaryTransporter(id) {
      if (this.userId === id) {
        this.bodyForm.userId = null;
      } else {
        this.bodyForm.userId = id;
      }
    },
    chooseSecondaryTransporter(id) {
      if (this.userId === id) {
        this.bodyForm.secondary_transporterId = null;
      } else {
        this.bodyForm.secondary_transporterId = id;
      }
    },
    ascSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allTransporter.sort(compare);
    },
    getallTransporter() {
      let token = JSON.parse(localStorage.getItem("token"));

      console.log(this.getBodyForm, "here");
      axios
        .get(
          this.$store.state.app.apiBasepath +
            "/user/all-transporter?shippingTypeId=" +
            this.getBodyForm.rate.shipping_type.id,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          this.$store.state.app.managerForm.allTransporter = res.data.rows;
          this.ascSort();
        })
        .catch((err) => {
          console.log("500: " + err);
        });
    },
    changePic() {
      $("#pict").click();
    },

    showPreview() {
      let pic = document.getElementById("pict");
      if (pic.files.length > 0) {
        var src = URL.createObjectURL(pic.files[0]);
        var preview = document.getElementById("pictureImg");
        preview.src = src;
      }
    },
    togglePass() {
      $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwd");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    next() {
      console.log(this.bodyForm);
    },
    togglePassConfirm() {
      $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwdconf");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    nextStage() {
      if (document.getElementById("pict")) {
        if (document.getElementById("pict").files.length > 0) {
          this.photoFile = document.getElementById("pict");
        } else {
          this.photoFile = null;
        }
      }

      this.stages[this.stageIndex].status = 2;
      this.stages[this.stageIndex + 1].status = 1;
      $("#stages").animate({ scrollLeft: "+=100" }, 600);
      this.stageIndex++;
    },
    prevStage() {
      this.stages[this.stageIndex].status = 0;
      this.stages[this.stageIndex - 1].status = 1;
      $("#stages").animate({ scrollLeft: "-=100" }, 600);
      this.stageIndex--;
    },
    photoInput() {
      $("#photoInput").click();
    },
    randomizeName() {
      return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021")
        .toString()
        .substring(0, 16);
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
    currentMenu() {
      if (
        ["Publish Rates", "Client Rates", "Request Change"].includes(
          this.$route.name
        )
      ) {
        return "Rates";
      }

      if (["Users", "References"].includes(this.$route.name)) {
        return "Manages";
      }

      if (["Receipt", "SLA"].includes(this.$route.name)) {
        return "Reports";
      } else {
        return this.$route.name;
      }
    },
    currentSubmenu() {
      let menu = this.currentMenu();
      if (["Dashboard", "Receipts"].includes(menu)) {
        return "";
      } else {
        return this.$route.name;
      }
    },
    getMenu(val) {
      this.menu = val;
    },
    getSub(val) {
      this.submenu = val;
    },
    toggleUserAction() {
      this.userAction = !this.userAction;
    },
    setFocusUser(ele) {
      $(ele.target).focus();
      console.log(ele.target);
    },
    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.keepLogin;
          this.$store.state.userData = userData;
        })
        .catch(() => {
          if (!this.$route.query.wv) this.$router.push("/signin");
        });
    },
    encode64() {
      async function listarchivos(fileInput) {
        var base64; //in this variable i need the base64
        var fileToLoad = fileInput;
        base64 = await getBase64(fileToLoad).then((data) => {
          return data;
        });
        console.log(base64);
        return base64;
      }

      //This is my function for get base64, but not return the string base64
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          return Promise.resolve(reader.result);
        });
      }

      let result = listarchivos(this.photoFile);
      return result;
    },
    async approve() {
      this.$store.state.showLoader = true;
      let token = JSON.parse(localStorage.getItem("token"));
      const basepath = this.$store.state.app.apiBasepath;

      const promises = this.gasLocations.map((obj) => {
        return new Promise((resolve, reject) => {
          const modifiedObj = {
            title: obj.title,
            latitude: obj.lat,
            longitude: obj.lng,
          };

          axios
            .post(
              basepath +
                "/booking-receipt/gas-station-locations/" +
                this.$route.params.id,
              modifiedObj,
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              resolve(response.data); // Resolve the promise with the response data
            })
            .catch((error) => {
              reject(error); // Reject the promise with the error
            });
        });
      });

      const pdfFile = await this.generateWaybill();
      console.log(pdfFile);
      await axios
        .get(
          "https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/usfc-upload-documents-get-presigned-url?fileName=" +
            `${this.bodyForm.details.booking_number}_waybill.pdf`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/pdf",
            },
          }
        )
        .then((res) => {
          axios({
            method: "PUT",
            url: res.data.fileUploadURL,
            data: pdfFile,
            headers: {
              "Content-Type": "application/pdf",
            },
          }).then(async () => {
            this.bodyForm.waybill_document =
              "https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/" +
              `${this.bodyForm.details.booking_number}_waybill.pdf`;
            const body = {
              insurance_item_value: this.getApproverData.insurance
                ? this.getApproverData?.insurance_item_value || 0
                : 0,
              repack_value: this.getApproverData.repack
                ? this.getApproverData?.repack_value || null
                : null,
              other_value: this.getApproverData.other
                ? this.getApproverData?.other_value || null
                : null,
              tax_value: this.getApproverData?.tax_value || "PPN Logistic",
              insurance_fee: this.getApproverData.insurance
                ? +this.getApproverData?.insurance_fee || 0
                : 0,
              repack_fee: this.getApproverData.repack
                ? +this.getApproverData?.repack_fee || 0
                : 0,
              other_fee: this.getApproverData.other
                ? +this.getApproverData?.other_fee || 0
                : 0,
              // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL 0.011
              tax_fee: this.calculateTotalCost * 0,
              total_cost: Number(this.calculateTotalCost),
              booking_status: 2,
              userId: this.bodyForm.userId,
              secondary_transporterId: this.bodyForm.secondary_transporterId,
              plate_number: this.bodyForm.plate_number,
              waybill_document: this.bodyForm.waybill_document,
            };

            await axios
              .post(
                basepath +
                  "/booking-receipt/approve-admin/" +
                  this.$route.params.id,
                body,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then(() => {
                // Execute all promises
                Promise.all(promises);
                location.reload();
              })
              .catch(() => {
                this.$alertSys("An error occured", "ERR");
              });
          });
        });
    },
    ascSortClient() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allClients.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return 1;
        if (a.full_name > b.full_name) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allClients.sort(compare);
      this.paginate();
    },
    getAllAdmins() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/user/all-client", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.$store.state.app.managerForm.allClients =
            res.data.AllClient.rows;
          this.ascSortClient();
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredClients[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allClients.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
  },

  async mounted() {
    this.getDetail(this.$route.params.id);
    this.getallTransporter();
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/approveBooking.scss";

.height-limit {
  max-height: 62.5vh;
  overflow: auto;
  padding: 12px;
  margin-bottom: 12px;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3699ff;
  }
}
</style>

<template>
    <div class="col bg-white text-start prevent-select" style="border-radius: 6px">
        <form-wrapper v-if="!this.$route.path.split('/')[3]">
            <div class="row">
                <TabRefSelection />
            </div>
            <div class="row">
                <div>
                    <ActiveReceipts v-if="this.$store.state.app.managerForm.currentReceiptTab == 'active'" />
                    <ArchivedReceipts v-if="this.$store.state.app.managerForm.currentReceiptTab == 'archived'" />
                </div>
            </div>
        </form-wrapper>
        <RouterView />
    </div>
</template>

<script>
    import $ from "jquery";
    import axios from "axios";
    import TabRefSelection from "./tabRefSelection.vue";
    import ActiveReceipts from "./activeComps/activeContents.vue";
    import ArchivedReceipts from "./archivedComps/archivedContents.vue";
    import { RouterView } from "vue-router";

    /* eslint-disable */

    export default {
        name: "ReceiptsComponent",
        props: {},
        components: {
            TabRefSelection,
            ActiveReceipts,
            ArchivedReceipts,
            RouterView,
        },
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
            };
        },
        computed: {
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            forgotPass(val) {
                this.loadForm = "wait";
                setTimeout(() => {
                    this.loadForm = val;
                }, 250);
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        if (!this.$route.query.wv) if (!this.$route.query.wv) this.$router.push("/signin");
                    });
            },
        },
        mounted() {
            let token = localStorage.getItem("token");
            if (token == null || undefined) {
                if (!this.$route.query.wv) this.$router.push("/signin");
            } else {
                this.keepLogin(token);
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .usfc-bg {
        background-color: #fbfbfb;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }
    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>

<template>
  <div style="min-height: 90vh">
    <div id="modalTitle" class="row">
      <div class="col p-2">
        <h5>
          <span class="p-2" style="font-weight: 700; float: left"
            >Edit Promotion</span
          >
          <span @click="this.$store.state.app.openModal = ''" id="closeModal"
            ><img
              class="d-flex justify-content-center"
              src="/icons/all/outline/add.svg"
          /></span>
        </h5>
      </div>
    </div>

    <form-wrapper>
      <div
        id="forms"
        class="row text-start d-flex flex-nowrap profile"
        style="height: 75vh; overflow-y: scroll"
      >
        <form>
          <div class="label-float">
            <input
              @input="validateStage()"
              v-model="bodyForm.name"
              :disabled="!edit"
              id="name"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Promo Name</label>
          </div>
          <div class="label-float">
            <input
              @input="validateStage()"
              v-model="bodyForm.code"
              :disabled="!edit"
              id="code"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Promo Code</label>
          </div>
          <div class="label-float">
            <Datepicker
              format="dd/MM/yyyy"
              :auto-apply="true"
              :disabled="!edit"
              :enable-time-picker="false"
              v-model="bodyForm.start_date"
              position="center"
              placeholder="Start Date"
              :start-date="dateNow"
              :keep-action-row="false"
            />
            <label :class="'option-selected'">Start Date</label>
          </div>
          <div class="label-float">
            <Datepicker
              format="dd/MM/yyyy"
              :auto-apply="true"
              :disabled="!edit"
              :enable-time-picker="false"
              v-model="bodyForm.end_date"
              position="center"
              placeholder="End Date"
              :start-date="bodyForm.end_date"
              :keep-action-row="false"
            />
            <label :class="'option-selected'">End Date</label>
          </div>

          <div class="label-float">
            <label
              :class="bodyForm.discount_type == '' ? '' : 'option-selected'"
              >Discount Type</label
            >
            <label
              v-if="bodyForm.discount_type == ''"
              :class="bodyForm.discount_type == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              v-model="bodyForm.discount_type"
              placeholder=" "
              :disabled="!edit"
            >
              <option disabled></option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>
          <div
            v-if="
              bodyForm.discount_type && bodyForm.discount_type === 'percentage'
            "
            class="label-float"
          >
            <input
              @input="validateStage()"
              v-model="bodyForm.discount_percentage"
              :disabled="!edit"
              id="end_date"
              type="number"
              placeholder=" "
              autocomplete="off"
            />
            <label>Discount Percentage</label>
          </div>
          <div v-if="bodyForm.discount_type !== 'amount'" class="label-float">
            <input
              @input="validateStage()"
              v-model="bodyForm.max_discount"
              :disabled="!edit"
              id="max_discount"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Max. Discount</label>
          </div>
          <div class="label-float">
            <input
              @input="validateStage()"
              v-model="bodyForm.quota"
              :disabled="!edit"
              id="end_date"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Discount Quota</label>
          </div>

          <div class="mt-2" style="font-size: 12px; opacity: 0.75">
            Recommended Ratio (1 : 4.5 / WxH) ex. 320x1440
          </div>
          <div
            class="photo-uploader"
            :class="{ disabled: !edit }"
            @click="chooseImg()"
          >
            <input
              @change="uploadImg()"
              :model="bodyForm.photos"
              type="file"
              accept="image/jpeg"
              id="photos"
              hidden
            />
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="18"
                viewBox="0 0 21 18"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.278 18H12.722C15.843 18 17.404 18 18.525 17.265C19.0087 16.948 19.4253 16.5389 19.751 16.061C20.5 14.961 20.5 13.428 20.5 10.364C20.5 7.29905 20.5 5.76705 19.751 4.66705C19.4253 4.18916 19.0087 3.78005 18.525 3.46305C17.805 2.99005 16.903 2.82105 15.522 2.76105C14.863 2.76105 14.296 2.27105 14.167 1.63605C14.0684 1.17092 13.8123 0.754083 13.4418 0.455997C13.0714 0.157912 12.6095 -0.00314481 12.134 4.65308e-05H8.866C7.878 4.65308e-05 7.027 0.685047 6.833 1.63605C6.704 2.27105 6.137 2.76105 5.478 2.76105C4.098 2.82105 3.196 2.99105 2.475 3.46305C1.99162 3.78011 1.57537 4.18922 1.25 4.66705C0.5 5.76705 0.5 7.29905 0.5 10.364C0.5 13.428 0.5 14.96 1.249 16.061C1.573 16.537 1.989 16.946 2.475 17.265C3.596 18 5.157 18 8.278 18ZM10.5 6.27305C8.199 6.27305 6.333 8.10405 6.333 10.363C6.333 12.623 8.199 14.455 10.5 14.455C12.801 14.455 14.667 12.623 14.667 10.364C14.667 8.10405 12.801 6.27305 10.5 6.27305ZM10.5 7.90905C9.12 7.90905 8 9.00805 8 10.364C8 11.719 9.12 12.818 10.5 12.818C11.88 12.818 13 11.719 13 10.364C13 9.00805 11.88 7.90905 10.5 7.90905ZM15.222 7.09105C15.222 6.63905 15.595 6.27305 16.056 6.27305H17.166C17.626 6.27305 18 6.63905 18 7.09105C17.9979 7.30994 17.909 7.51905 17.7528 7.67243C17.5966 7.82581 17.3859 7.91091 17.167 7.90905H16.056C15.9475 7.9101 15.8399 7.88978 15.7393 7.84924C15.6387 7.80871 15.547 7.74874 15.4696 7.67278C15.3921 7.59683 15.3304 7.50636 15.2879 7.40654C15.2454 7.30673 15.223 7.19952 15.222 7.09105Z"
                  fill="#59AAFF"
                />
              </svg>
            </div>
            <div>{{ bodyForm.photos ? bodyForm.photos : "Add Photo" }}</div>
          </div>

          <div class="label-float-tall">
            <textarea
              v-model="bodyForm.description"
              :disabled="!edit"
              style="font-size: 12px; resize: none"
              rows="4"
              placeholder="Description"
            />
          </div>
        </form>
      </div>
    </form-wrapper>

    <div id="navigation" class="row my-2">
      <div class="col">
        <button
          v-if="!edit"
          @click="edit = !edit"
          style="float: right"
          class="mx-2 btn btn-usfc-edit"
        >
          Edit
        </button>
        <button
          v-else
          @click="register()"
          id="finalize"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
        >
          Update Promotion
        </button>

        <span
          @click="
            edit ? (edit = !edit) : (this.$store.state.app.openModal = '')
          "
          style="float: right"
          class="mx-2 btn btn-cancel"
        >
          Cancel</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.js";

import Datepicker from "@vuepic/vue-datepicker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import $ from "jquery";
import axios from "axios";
import moment from "moment";

export default {
  name: "originCreate",
  components: {
    Datepicker,
  },
  computed: {
    dateNow() {
      return new Date();
    },
    currentRouteName() {
      return this.$route.name;
    },
    userFullName() {
      if (this.$store.state.userData.full_name.length > 30) {
        return this.$store.state.userData.full_name.substring(0, 28) + "...";
      } else {
        return this.$store.state.app.userData.full_name;
      }
    },
    role() {
      if (this.$store.state.userData.access_level == 5) {
        return "Manager";
      } else if (this.$store.state.userData.access_level == 4) {
        return "Admin";
      } else if (this.$store.state.userData.access_level == 3) {
        return "Sales";
      } else if (this.$store.state.userData.access_level == 2) {
        return "Client";
      } else {
        return "Transporter";
      }
    },
  },
  data() {
    return {
      edit: false,
      photo64: "",
      photoFile: "",
      access_level: 4,
      stageIndex: 0,
      pwdconf: "",
      stages: [
        // status (0: upcoming, 1: active, 2: done)
        {
          name: "Fill Personal Profile",
          status: 1,
        },
        {
          name: "Create Password",
          status: 0,
        },
      ],
      selects: "",
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
      bodyForm: {},
    };
  },
  methods: {
    chooseImg() {
      document.getElementById("photos").click();
    },
    uploadImg() {
      this.photoFile = document.getElementById("photos");

      if (document.getElementById("photos").files.length > 0) {
        var fileName =
          this.randomizeName() + "." + this.photoFile.value.split(".").pop();
        this.bodyForm.photos = fileName;
      }

      let dataImage = "";
      if (document.getElementById("photos").value.length > 0)
        dataImage = document.getElementById("photos").files[0];

      axios
        .get(
          "https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" +
            fileName,
          { headers: { "Access-Control-Allow-Origin": "*" } }
        )
        .then((res) => {
          axios({
            method: "PUT",
            url: res.data.fileUploadURL,
            data: dataImage,
            headers: {
              "Content-Type": "image/jpeg",
            },
          }).then(() => {});
        });
    },
    validateStage() {
      let valid = 0;
      if (this.bodyForm.city != "") {
        valid++;
      } else {
        valid--;
      }
      if (this.bodyForm.province != "") {
        valid++;
      } else {
        valid--;
      }

      console.log(valid);
      if (valid == 2) {
        $("#finalize").prop("disabled", false);
      } else {
        $("#finalize").prop("disabled", true);
      }
    },
    changePic() {
      $("#pict").click();
    },

    showPreview() {
      let pic = document.getElementById("pict");
      if (pic.files.length > 0) {
        var src = URL.createObjectURL(pic.files[0]);
        var preview = document.getElementById("pictureImg");
        preview.src = src;
      }
    },
    togglePass() {
      $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwd");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    togglePassConfirm() {
      $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwdconf");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    nextStage() {
      if (document.getElementById("pict").files.length > 0) {
        this.photoFile = document.getElementById("pict");
      } else {
        this.photoFile = null;
      }
      this.stages[this.stageIndex].status = 2;
      this.stages[this.stageIndex + 1].status = 1;
      $("#stages").scrollLeft("2000px");
      this.stageIndex++;
    },
    prevStage() {
      this.stages[this.stageIndex].status = 0;
      this.stages[this.stageIndex - 1].status = 1;
      $("#stages").scrollLeft("200px");
      this.stageIndex--;
    },
    photoInput() {
      $("#photoInput").click();
    },
    randomizeName() {
      return "adimg_" + moment().format("DDMMyyyyhhmmss_SSS");
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
    currentMenu() {
      if (
        ["Publish Rates", "Client Rates", "Request Change"].includes(
          this.$route.name
        )
      ) {
        return "Rates";
      }

      if (["Users", "References"].includes(this.$route.name)) {
        return "Manages";
      }

      if (["Receipt", "SLA"].includes(this.$route.name)) {
        return "Reports";
      } else {
        return this.$route.name;
      }
    },
    currentSubmenu() {
      let menu = this.currentMenu();
      if (["Dashboard", "Receipts"].includes(menu)) {
        return "";
      } else {
        return this.$route.name;
      }
    },
    getMenu(val) {
      this.menu = val;
    },
    getSub(val) {
      this.submenu = val;
    },
    toggleUserAction() {
      this.userAction = !this.userAction;
    },
    setFocusUser(ele) {
      $(ele.target).focus();
      console.log(ele.target);
    },
    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.keepLogin;
          this.$store.state.userData = userData;
        })
        .catch(() => {
          if (!this.$route.query.wv) this.$router.push("/signin");
        });
    },
    encode64() {
      async function listarchivos(fileInput) {
        var base64; //in this variable i need the base64
        var fileToLoad = fileInput;
        base64 = await getBase64(fileToLoad).then((data) => {
          return data;
        });
        console.log(base64);
        return base64;
      }

      //This is my function for get base64, but not return the string base64
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          return Promise.resolve(reader.result);
        });
      }

      let result = listarchivos(this.photoFile);
      return result;
    },
    updateTable() {
      this.$refs.adminTable.this.getAllAdmin();
    },
    register() {
      let token = JSON.parse(localStorage.getItem("token"));

      this.bodyForm.start_date = moment(this.bodyForm.start_date).format(
        "YYYY/MM/DD"
      );
      this.bodyForm.end_date = moment(this.bodyForm.end_date).format(
        "YYYY/MM/DD"
      );
      axios
        .patch(
          this.$store.state.app.apiBasepath +
            "/promotion/edit/" +
            this.$store.state.app.modalData.id,
          this.bodyForm,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          this.$alertSys("Promotion Update!", "success");
          this.$store.state.app.openModal = "";
          location.reload();
        })
        .catch(() => {
          this.$alertSys("An error occured", "ERR");
        });
    },
    ascSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allAdmins.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return 1;
        if (a.full_name > b.full_name) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allAdmins.sort(compare);
      this.paginate();
    },
    getDetail() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(
          this.$store.state.app.apiBasepath +
            "/promotion/details/" +
            this.$store.state.app.modalData.id,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          this.bodyForm = res.data;
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredAdmins[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allAdmins.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.dp__main + .option-selected {
  transform: translateX(15px) translateY(-22px) !important;
}
.dp__main {
  padding: 15px 0;
}
.label-float {
  &:has(.dp__main) {
    padding-top: 0;

    label {
      transform: translateX(0px) translateY(-22px) !important;
    }
  }
}
.photo-uploader {
  background-color: #fbfbfb;
  border: 1px dashed #3699ff;
  color: #3699ff;
  border-radius: 12px;
  margin-bottom: 12px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 42px;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(0.95);
  }
  &:active {
    filter: brightness(0.9);
  }
}
#forms::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#closeModal {
  float: right;
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}

#closeModal:hover {
  border: 1px solid black;
}

#navigation {
  position: relative;
  bottom: 0;
  right: 0;
}

#stages > .col-auto {
  opacity: 0.5;
}

#stages > .col-auto.active {
  opacity: 1;
}

#pictureImg {
  cursor: pointer;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#pictureImgBtn {
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #3699ff;

  position: absolute;
  top: 180px;
  left: 53%;
}

#pictureImgBtn > #pictureBtn {
  transform: scale(1.2);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(103%) contrast(103%);
}

#profilePicture {
  height: 20%;
}

#stages {
  overflow-x: scroll;
}
#stages::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.blur-overlay {
  display: block;
  filter: blur(3px) brightness(90%);
  -webkit-filter: blur(3px) brightness(90%);
  -moz-filter: blur(3px) brightness(90%);
  -o-filter: blur(3px) brightness(90%);
  -ms-filter: blur(3px) brightness(90%);
  pointer-events: none;
}

.spacer {
  min-width: 250px;
  width: 20vw;
}

/* VUE TRANSITION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* css  */
myroute {
  cursor: pointer;
}

.btn-back {
  color: #3699ff;
  outline: 1px solid #3699ff;
}

.btn-back:hover {
  color: #3699ff;
  outline: 1px solid #3699ff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
}

.usfc-bg {
  background-color: #fbfbfb;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input[type="password"] {
  font-family: Verdana;
  letter-spacing: 0.25em;
  font-weight: 900;
}

.toggle-password,
.toggle-password-confirm {
  cursor: pointer;
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 75px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
          border: solid 1px rgba(54, 153, 255, 0.25);
        } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  z-index: 999;
  position: absolute;
  width: auto;
  right: 20px;
  top: 15vh;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 6px;
}

ul.user-button li {
  position: relative;
  padding: 10px 0;
  width: 200px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 2em;
}

ul.user-button {
  position: absolute;
  top: 75px;
  right: 0;
  padding-left: 0;
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

a {
  color: inherit;
  text-decoration: none;
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  border: 1px solid transparent;
}

select {
  width: 100%;
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}

/* FA ICONS CUSTOM */
.fa-check {
  margin: 0 5px;
  position: relative;
  top: -2px;
  font-size: 12px;
  color: white;
  border-radius: 50%;
  padding: 3px;
  background-color: #3699ff;
}

/* * {
      border: 1px solid red;
    } */
</style>

import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
//LOGIN PAGE Components
import Login from "@/views/loginComps/LoginPage.vue";
import Register from "@/views/loginComps/RegisterPage.vue";
import ResetPass from "@/views/loginComps/ResetPage.vue";
import PassChanged from "@/views/loginComps/CheckPage.vue";

// Dashboard -> Dashboard
import Dashboard from "@/views/dashComps/dashboardComponents/dashboardComponent.vue";

// Dashboard -> Receipts
import Receipts from "@/views/dashComps/receiptsComponents/receiptsComponent.vue";
import ReceiptsDetail from "@/views/dashComps/receiptsComponents/archivedComps/userDetail.vue";

// Dashboard - > Recipients
import Recipients from "@/views/dashComps/recipientsComponents/recipientsComponents.vue";

// Dashboard -> Bookings
import Bookings from "@/views/dashComps/bookingsComponents/bookingsComponent.vue";
import BookingApproval from "@/views/dashComps/bookingsComponents/completedComps/userDetail.vue";

// Dashboard -> Manage, Components
import Manager from "@/views/dashComps/managerComponents/managerComponent.vue";
import UserDetail from "@/views/dashComps/managerComponents/usersComps/adminComps/userDetail.vue";

// Dashboard -> Promotions
import Promotion from "@/views/dashComps/promotionComponents/promotionComps.vue";

import Rates from "@/views/dashComps/ratesComponents/ratesComponent.vue";
import CheckRates from "@/views/dashComps/checkRatesComponents/checkRatesComponent.vue";
import ClientRates from "@/views/dashComps/ratesComponents/ratesComps/clientComps/clientRatesComp/clientRatesComp.vue";
import RatesDetail from "@/views/dashComps/ratesComponents/ratesComps/requestComps/completedComps/userDetail.vue";

import axios from "axios";

const routes = [
    // Login Routes
    {
        path: "/",
        alias: ["/signin", "/login"],
        name: "Signin",
        title: "Sign In",
        component: Login,
        meta: {
            title: "USFC - Sign in",
            metaTags: [
                {
                    name: "description",
                    content: "Sign in.",
                },
                {
                    property: "og:description",
                    content: "Sign in.",
                },
            ],
        },
    },
    {
        path: "/register",
        alias: ["/signup", "/daftar"],
        name: "Register",
        title: "Register",
        component: Register,
        meta: {
            title: "USFC - Register",
            metaTags: [
                {
                    name: "description",
                    content: "Register.",
                },
                {
                    property: "og:description",
                    content: "Register.",
                },
            ],
        },
    },
    {
        path: "/resetpassword/:key",
        name: "ResetPass",
        title: "Reset Password",
        component: ResetPass,
        meta: {
            title: "Reset Password",
            metaTags: [
                {
                    name: "description",
                    content: "Reset Password.",
                },
                {
                    property: "og:description",
                    content: "Reset Password.",
                },
            ],
        },
    },
    {
        path: "/passwordchanged/:key",
        name: "PassChanged",
        title: "Password Changed",
        component: PassChanged,
        meta: {
            title: "Password Successfully Changed",
            metaTags: [
                {
                    name: "description",
                    content: "Password Changed.",
                },
                {
                    property: "og:description",
                    content: "Password Changed.",
                },
            ],
        },
    },

    // Dashboard Routes
    {
        name: "Dashboard",
        title: "Dashboard",
        component: Dashboard,
        meta: {
            title: "USFC - Dashboard",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        title: "Dashboard",
        component: Dashboard,
        meta: {
            title: "USFC - Dashboard",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/receipts",
        name: "Receipts",
        title: "Receipts",
        component: Receipts,
        children: [
            {
                path: "details/:id",
                name: "Detail",
                component: ReceiptsDetail,
            },
        ],
        meta: {
            title: "USFC - Receipts",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/waybill/:id",
        name: "Waybill",
        title: "Waybill Tracking",

        component: ReceiptsDetail,
        meta: {
            title: "USFC - Waybill",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/check-rates",
        name: "Check Rates",
        title: "Check Rates",
        component: CheckRates,
        meta: {
            title: "USFC - Check Rates",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/bookings",
        name: "Bookings",
        title: "Bookings",
        component: Bookings,
        children: [
            {
                path: "details/:id",
                name: "Approval",
                component: BookingApproval,
            },
        ],
        meta: {
            title: "USFC - Bookings",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/recipients",
        name: "Recipients",
        title: "Recipients",
        component: Recipients,
        meta: {
            title: "USFC - Recipients",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/rates",
        name: "Rates",
        title: "Rates",
        component: Rates,
        children: [
            { path: "public", name: "Public Rates", component: Rates },
            { path: "client", name: "Client Rates", component: Rates },
            { path: "request", name: "Request Change", component: Rates },
            {
                path: "request/:id",
                name: "Request Change ",
                component: RatesDetail,
            },
            {
                path: "client/:id",
                name: "Client Rates ",
                component: ClientRates,
            },
        ],
        meta: {
            title: "USFC - Rates",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/manage",
        name: "Manage",
        title: "Manage",
        component: Manager,
        children: [
            {
                path: "users",
                name: "Users",
                component: Manager,
            },
            { path: "references", name: "References", component: Manager },
        ],
        meta: {
            title: "USFC - Manage",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/manage",
        name: "Manage",
        title: "Manage",
        component: Manager,
        children: [
            {
                path: "users",
                name: "Users ",
                component: Manager,
                children: [{ path: "admin", name: "Admin Users", component: Manager }],
            },
            { path: "references", name: "References", component: Manager },
            {
                path: "users/:id",
                name: "Users",
                component: UserDetail,
            },
        ],
        meta: {
            title: "USFC - Manage",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/promotions",
        name: "Promotion",
        title: "Promotion",
        component: Promotion,
        children: [
            {
                path: "promo",
                name: "Promo",
                component: Promotion,
            },
            { path: "ads", name: "Ads", component: Promotion },
        ],
        meta: {
            title: "USFC - Manage",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },

    {
        path: "/reports",
        name: "Reports",
        title: "Reports",
        component: Manager,
        children: [
            { path: "receipt", name: "Receipt", component: Manager },
            { path: "sla", name: "SLA", component: Manager },
            { path: "sales-performance", name: "Sales Performance", component: Manager },
            { path: "transporter-performance", name: "Transporter Performance", component: Manager },
            { path: "gasoline-report", name: "Gasoline Report", component: Manager },
        ],
        meta: {
            title: "USFC - Dashboard",
            metaTags: [
                {
                    name: "description",
                    content: "USFC Dashboard",
                },
                {
                    property: "og:description",
                    content: "USFC Dashboard",
                },
            ],
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

function resetStates() {
    store.state.globalStates.err.stat = false;
    store.state.globalStates.err.code = "";
    store.state.globalStates.err.desc = "";
    store.state.app.managerForm.search = "";
    store.state.app.breadcrumb.currentSubSubMenu = "";
    store.state.app.managerForm.currentReceiptTab = "active";
    store.state.app.managerForm.currentRefTab = "origin";
    store.state.app.managerForm.currentReqTab = "approval";
    store.state.app.managerForm.allOrigin = [];
    store.state.app.managerForm.filteredOrigin = [];
}

router.beforeEach((toRoute, fromRoute, next) => {
    resetStates();
    if (toRoute.meta && toRoute.meta.title) {
        window.document.title = toRoute.meta.title;
        toRoute.name;
    } else {
        window.document.title = "404 - Not Found";
        store.state.globalStates.err.stat = true;
        store.state.globalStates.err.code = "404";
        store.state.globalStates.err.desc = "Page Not Found";
    }

    next();
    getNotifications();
});

async function getNotifications() {
    let token = JSON.parse(localStorage.getItem("token"));

    const data = await axios.get(store.state.app.apiBasepath + "/notification/all?is_read=false", { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
        return res.data.rows;
    });

    if (data) {
        // const filteredData = data.rows.filter((item) => {
        //     const diffInDays = moment().diff(moment(item.updatedAt), "days");
        //     return diffInDays <= 7;
        // });

        store.state.app.userNotification = data;

        store.state.app.hasUnreadNotification = data.some((item) => !item.is_read);
    }
}
export default router;

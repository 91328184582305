<template>
  <div>
    <div v-if="show" class="blur"></div>
    <div v-if="show" class="modal-frame">
      <div class="content">
        <div class="text-start mb-4">
          <h5>
            <span style="font-weight: 700">Add Discount </span>
            <span @click="show = false" id="closeModal"
              ><img
                class="d-flex justify-content-center"
                src="/icons/all/outline/add.svg"
            /></span>
          </h5>
        </div>
        <div class="text-start mb-1">Discounted Amount</div>
        <div class="label-float pt-0">
          <input
            @input="validateDiscount()"
            v-model="bodyForm.discount_fee"
            type="text"
            placeholder=" "
            autocomplete="off"
          />
        </div>
        <div class="text-start mb-1">Note</div>
        <div class="label-float-tall">
          <textarea
            v-model="bodyForm.discount_note"
            style="font-size: 12px"
            rows="4"
            placeholder=""
          />
        </div>
        <div
          class="text-start p-2"
          style="
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            background: rgba(0, 0, 0, 0.1);
          "
        >
          <div>After Discount</div>
        </div>
        <div class="mb-0" style="display: flex">
          <div class="text-start mb-0">Total Cost</div>
          <div class="mb-0">
            Rp {{ calculateTotalCost?.toLocaleString() }}
            <span style="color: rgb(153, 25, 25); font-style: italic"
              >({{
                this.bodyForm.discount_fee
                  ? "-" + this.bodyForm.discount_fee?.toLocaleString()
                  : "No Discount"
              }})</span
            >
          </div>
        </div>
        <div class="mb-0" style="display: flex">
          <div class="text-start mb-0">New PPN Logistic</div>
          <div class="mb-0">Rp {{ calculateNewTax?.toLocaleString() }}</div>
        </div>
        <div style="display: flex">
          <div class="text-start">New Payable Amount</div>
          <div>Rp {{ this.calculateNewTotal?.toLocaleString() }}</div>
        </div>

        <div
          id="navigation"
          class="row mb-0 mt-3"
          style="transform: translateX(26px)"
        >
          <div class="mb-0">
            <button
              @click="applyDiscount()"
              style="float: right"
              class="mx-2 btn btn-usfc-primary"
            >
              Apply Discount
            </button>
            <span
              @click="show = false"
              style="float: right"
              class="mx-2 btn btn-cancel"
            >
              Cancel</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- triggers -->
    <!-- <div v-if="!bookingReceipt.discount_fee && (isAdmin || isManager)" @click="show = true" class="hoverable" style="color: #3699ff">+ Add Discount</div>
        <div v-else @mouseenter="considerDelete = true" @mouseleave="considerDelete = false">
            <span @click="removeDiscount()" v-if="considerDelete && (isAdmin || isManager)" class="hoverable">delete</span>
            <span style="color: rgb(153, 25, 25)">-{{ bookingReceipt.discount_fee?.toLocaleString() }}</span>
        </div> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    bookingReceipt: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      considerDelete: false,
      show: false,
      bodyForm: {
        discount_fee: 0,
        discount_note: "",
        tax_fee: 0,
        total_cost: 0,
      },
    };
  },
  computed: {
    getBodyForm() {
      return this.$store.state.app.currentBodyForm;
    },
    calculateTotalCost() {
      return (
        this.bookingReceipt.total_cost +
        this.calculateAdditional -
        this.bookingReceipt.promotion_nominal
      );
    },
    calculateAdditional() {
      return (
        this.bookingReceipt.insurance_fee +
        this.bookingReceipt.repack_fee +
        this.bookingReceipt.other_fee
      );
    },
    calculateNewTax() {
      // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL 0.011
      return Math.round(
        (+this.calculateTotalCost - +this.bodyForm.discount_fee) * 0
      );
    },
    calculateNewTotal() {
      return (
        +this.calculateTotalCost +
        +this.calculateAdditional -
        +this.bodyForm.discount_fee +
        +this.calculateNewTax
      );
    },
    isClient() {
      if (this.$store.state.userData.access_level === 2) return true;
      return false;
    },
    isSales() {
      if (this.$store.state.userData.access_level === 3) return true;
      return false;
    },
    isAdmin() {
      if (this.$store.state.userData.access_level === 4) return true;
      return false;
    },
    isManager() {
      if (this.$store.state.userData.access_level === 5) return true;
      return false;
    },
  },
  methods: {
    validateDiscount() {
      if (this.bodyForm.discount_fee > this.calculateTotalCost)
        this.bodyForm.discount_fee = Number(
          this.calculateTotalCost.toString().replace(/^0+/, "")
        );
      if (this.bodyForm.discount_fee < 0) this.bodyForm.discount_fee = 0;
      this.bodyForm.discount_fee = Number(
        this.bodyForm.discount_fee.toString().replace(/^0+/, "")
      );
    },
    applyDiscount() {
      let token = JSON.parse(localStorage.getItem("token"));
      const basepath = this.$store.state.app.apiBasepath;
      const body = {
        discount_fee: +this.bodyForm.discount_fee,
        discount_note: this.bodyForm.discount_note,
        total_cost: this.calculateNewTotal - this.calculateNewTax,
        tax_fee: this.calculateNewTax,
      };

      axios
        .post(basepath + "/receipt/discount/" + this.bookingReceipt.id, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          location.reload();
        })
        .catch(() => {
          this.$alertSys("An error occured", "ERR");
        });
    },

    removeDiscount() {
      let token = JSON.parse(localStorage.getItem("token"));
      const basepath = this.$store.state.app.apiBasepath;
      const body = {
        discount_fee: 0,
        discount_note: "",
        total_cost:
          +this.calculateTotalCost +
          +this.bookingReceipt.discount_fee +
          +this.calculateAdditional,
        // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL 0.011
        tax_fee: (this.calculateTotalCost + this.calculateAdditional) * 0,
      };

      axios
        .post(basepath + "/receipt/discount/" + this.bookingReceipt.id, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          location.reload();
        })
        .catch(() => {
          this.$alertSys("An error occured", "ERR");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.hoverable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
#closeModal {
  float: right;
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}

#closeModal:hover {
  border: 1px solid black;
}
.blur {
  opacity: 1;
  z-index: 190;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(20vw);
  min-height: 100vh;
  min-width: 80vw;
  background-color: rgba($color: #000000, $alpha: 0.2);
}
.modal-frame {
  z-index: 199;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(20vw);
  min-height: 100vh;
  min-width: 80vw;

  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: text;

  .content {
    padding: 12px;
    align-self: center;
    background: white;
    min-width: 600px;
    min-height: 200px;
    border-radius: 4px;

    div {
      width: 100%;
      margin-bottom: 6px;
    }
  }
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  border: 1px solid transparent;
}

select {
  width: 100%;
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  appearance: none;
  top: 10px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  left: 6px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}
</style>

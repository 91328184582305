<template>
    <div class="d-flex align-items-center mt-4 gap-4">
        <ul class="mx-2">
            <li @click="switchTo('Public')" :class="currentReqTab == 'Public' ? 'active' : ''" class="px-5 py-2 mx-1">Public Rates</li>
            <li @click="switchTo('Client')" :class="currentReqTab == 'Client' ? 'active' : ''" class="px-5 py-2 mx-1">My Rates</li>
        </ul>
        <div v-if="currentReqTab === 'Client'" class="wa">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path
                        d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
            </div>
            <div @click="openTab('https://wa.wizard.id/23a08c')">Request Rate!</div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import axios from "axios";

    export default {
        name: "ManagerComponent",
        props: {},
        components: {},
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                NeedApproval: null,
            };
        },
        computed: {
            currentReqTab() {
                return this.$store.state.app.rateForm.checkRates.filters.mode;
            },
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            openTab(url) {
                window.open(url, "_blank");
            },
            switchTo(target) {
                this.$store.state.app.rateForm.checkRates.data.display = [];

                this.$store.state.app.rateForm.checkRates.filters.origin = "";
                this.$store.state.app.rateForm.checkRates.filters.destination = "";
                this.$store.state.app.rateForm.checkRates.filters.shipping_type = "";
                this.$store.state.app.rateForm.checkRates.filters.provider = "";

                this.$store.state.app.screenBlock.main = true;
                this.$store.state.app.rateForm.checkRates.filters.mode = target;
                setTimeout(() => {
                    this.$store.state.app.screenBlock.main = false;
                }, 50);
            },
            getApproval() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/client-rate-request/all?filter=" + 3, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        if (res.data.count > 0) {
                            this.$store.state.app.managerForm.noResult = false;
                            this.$store.state.app.managerForm.formLoading = false;
                        } else {
                            this.$store.state.app.managerForm.noResult = true;
                            this.$store.state.app.managerForm.formLoading = false;
                        }
                        this.NeedApproval = res.data.count;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        },
        mounted() {
            this.getApproval();
            // setInterval(() => {
            //   this.getApproval();
            // }, 10000);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .wa {
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 12px;
        gap: 12px;
        margin: 22px;
        background-color: #25d366;
        border-radius: 8px;
        color: #fff;

        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);

        &:hover {
            cursor: pointer;
            filter: brightness(0.9);
        }

        &:active {
            filter: brightness(0.8);
        }
    }
    .usfc-bg {
        background-color: #fbfbfb;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 7%;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
      border: solid 1px rgba(54, 153, 255, 0.25);
    } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        position: absolute;
        right: 3vw;
        top: 15vh;
        width: 12vw;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        border-radius: 6px;
    }

    ul {
        height: 5%;
    }

    li {
        text-align: center;
        display: inline;
        margin: 0;
        font-weight: 400;
        cursor: pointer;
        vertical-align: middle;
    }

    li.active {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    .frame-content {
        min-height: 80vh;
    }
    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>

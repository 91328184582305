<template>
    <Transition name="fade" mode="out-in">
        <div v-if="this.$parent.loadForm == 'register'" class="form mx-sm-4">
            <form v-if="stageIndex === 0" class="mt-5">
                <div class="text-center">
                    <img src="usfc_monogram.svg" style="width: 35%; padding-bottom: 10%" />
                </div>
                <div class="form-group">
                    <h3 style="font-weight: bold">Register your account</h3>
                    <span>Fill in your credentials to log in</span>
                </div>
                <div class="label-float">
                    <input v-model="bodyForm.username" id="fullname" type="text" placeholder=" " autocomplete="off" />
                    <label>Username</label>
                </div>

                <div class="label-float">
                    <input v-model="bodyForm.email" id="email" type="email" placeholder=" " autocomplete="off" />
                    <label>Email</label>
                </div>
                <div class="label-float">
                    <input v-model="bodyForm.phone" id="tel" type="tel" placeholder=" " autocomplete="off" />
                    <label>Phone Number</label>
                </div>
                <br />
                <div class="label-float" style="top: -20px">
                    <input v-model="bodyForm.password" id="pwd" type="password" placeholder=" " autocomplete="off" />
                    <label>Password</label>
                    <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePass()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password"></span>
                </div>
                <div class="label-float adjusted" style="top: -25px">
                    <input v-model="bodyForm.confirm" id="pwd-confirm" type="password" placeholder=" " autocomplete="off" />
                    <label>Confirm Password</label>
                    <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePass()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password"></span>
                </div>
                <div class="text-end pb-1" style="position: relative; top: -15px">
                    <div @click.stop="toLogin()" style="cursor: pointer; text-decoration: none; color: inherit" to="/">Have an Account ? Login.</div>
                </div>
                <!-- ADD DISABLED SETELAH APPLY API -->'
                <button @click.stop="validateUser()" :disabled="invalidCreds" class="btn btn-usfc-primary w-100" style="position: relative; top: -25px">Submit</button>
                <div id="responseBlock" class="spantexts text-danger pb-1" style="position: relative; top: -15px">
                    <span class="spantexts" id="responseMessage"> {{ errMsg }}</span>
                </div>
            </form>
            <form v-if="stageIndex === 1">
                <div class="form-group">
                    <div class="text-start button-back">
                        <div @click="stageIndex--" style="cursor: pointer; text-decoration: none; color: #3699ff; font-weight: bold"><i class="bi bi-arrow-left"></i> Back</div>
                    </div>
                    <h3 style="font-weight: bold">Register your account</h3>
                    <span>Enter your details to register your account</span>
                </div>
                <div class="form-group">
                    <div class="label-float">
                        <input v-model="bodyForm.fullname" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>Fullname</label>
                    </div>
                    <div class="label-float">
                        <input id="birthdate" type="date" placeholder=" " v-model="bodyForm.birth_date" autocomplete="off" :disabled="edit == false" />
                        <label>Birthdate</label>
                    </div>
                    <div class="label-float">
                        <label :class="bodyForm.gender == '' ? '' : 'option-selected'">Gender</label>
                        <label v-if="bodyForm.gender == ''" :class="bodyForm.gender == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                        <select v-model="bodyForm.gender" placeholder=" " :disabled="edit == false">
                            <option disabled></option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.nationality" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>Nationality</label>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.province" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>Province</label>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.city" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>City</label>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.district" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>District</label>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.street" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>Street</label>
                    </div>
                    <div class="label-float">
                        <input v-model="bodyForm.postal_code" id="fullname" type="text" placeholder=" " autocomplete="off" />
                        <label>Postal Code</label>
                    </div>
                </div>

                <br />

                <!-- ADD DISABLED SETELAH APPLY API -->'
                <button @click="stageIndex++" id="loginBtn" class="btn btn-usfc-primary w-100" :disabled="invalidFirstInfo" style="position: relative; top: -25px">Next (1 of 2)</button>
                <div id="responseBlock" class="spantexts text-danger pb-1" style="position: relative; top: -15px">
                    <span class="spantexts" id="responseMessage"> {{ errMsg }}</span>
                </div>
            </form>
            <form v-if="stageIndex === 2">
                <div class="form-group">
                    <div class="text-start button-back">
                        <div @click="stageIndex--" style="cursor: pointer; text-decoration: none; color: #3699ff; font-weight: bold"><i class="bi bi-arrow-left"></i> Back</div>
                    </div>
                    <h3 style="font-weight: bold">Register your account</h3>
                    <span>Add your profile photo, or add it later!</span>
                </div>
                <div class="form-group" style="padding: 75px 0">
                    <div class="photo-uploader" @click="chooseImg()">
                        <input @change="uploadImg()" :model="bodyForm.photos" type="file" accept="image/jpeg" id="photos" hidden />
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M8.278 18H12.722C15.843 18 17.404 18 18.525 17.265C19.0087 16.948 19.4253 16.5389 19.751 16.061C20.5 14.961 20.5 13.428 20.5 10.364C20.5 7.29905 20.5 5.76705 19.751 4.66705C19.4253 4.18916 19.0087 3.78005 18.525 3.46305C17.805 2.99005 16.903 2.82105 15.522 2.76105C14.863 2.76105 14.296 2.27105 14.167 1.63605C14.0684 1.17092 13.8123 0.754083 13.4418 0.455997C13.0714 0.157912 12.6095 -0.00314481 12.134 4.65308e-05H8.866C7.878 4.65308e-05 7.027 0.685047 6.833 1.63605C6.704 2.27105 6.137 2.76105 5.478 2.76105C4.098 2.82105 3.196 2.99105 2.475 3.46305C1.99162 3.78011 1.57537 4.18922 1.25 4.66705C0.5 5.76705 0.5 7.29905 0.5 10.364C0.5 13.428 0.5 14.96 1.249 16.061C1.573 16.537 1.989 16.946 2.475 17.265C3.596 18 5.157 18 8.278 18ZM10.5 6.27305C8.199 6.27305 6.333 8.10405 6.333 10.363C6.333 12.623 8.199 14.455 10.5 14.455C12.801 14.455 14.667 12.623 14.667 10.364C14.667 8.10405 12.801 6.27305 10.5 6.27305ZM10.5 7.90905C9.12 7.90905 8 9.00805 8 10.364C8 11.719 9.12 12.818 10.5 12.818C11.88 12.818 13 11.719 13 10.364C13 9.00805 11.88 7.90905 10.5 7.90905ZM15.222 7.09105C15.222 6.63905 15.595 6.27305 16.056 6.27305H17.166C17.626 6.27305 18 6.63905 18 7.09105C17.9979 7.30994 17.909 7.51905 17.7528 7.67243C17.5966 7.82581 17.3859 7.91091 17.167 7.90905H16.056C15.9475 7.9101 15.8399 7.88978 15.7393 7.84924C15.6387 7.80871 15.547 7.74874 15.4696 7.67278C15.3921 7.59683 15.3304 7.50636 15.2879 7.40654C15.2454 7.30673 15.223 7.19952 15.222 7.09105Z"
                                    fill="#59AAFF" />
                            </svg>
                        </div>
                        <div>{{ bodyForm.photos ? "Change" : "Add Photo" }}</div>
                    </div>
                </div>

                <br />

                <!-- ADD DISABLED SETELAH APPLY API -->'
                <button @click="register()" id="loginBtn" class="btn btn-usfc-primary w-100" :disabled="invalidFirstInfo" style="position: relative; top: -25px">Register</button>
                <div id="responseBlock" class="spantexts text-danger pb-1" style="position: relative; top: -15px">
                    <span class="spantexts" id="responseMessage"> {{ errMsg }}</span>
                </div>
            </form>
        </div>
    </Transition>
</template>

<script>
    import $ from "jquery";
    import axios from "axios";
    import { nextTick } from "vue";

    export default {
        name: "SigninForm",
        props: {},
        data() {
            return {
                bodyForm: {
                    password: "",
                    confirm: "",
                },
                demo: {
                    email: "wib@mail.com",
                    username: "wib",
                    password: "@Test123",
                    confirm: "@Test123",
                    name: "Wibu Satu",
                    street: "jalan wibu",
                    district: "district wibu",
                    province: "province wibu",
                    city: "city wibu",
                    postal_code: 12738782,
                    fullname: "Wibu Satu",
                    gender: "Male",
                    nationality: "Indonesia",
                    birth_date: "1999-02-03",
                    photo: "photo.jpg",
                },
                stageIndex: 0,
                errMsg: "",
            };
        },
        computed: {
            invalidFirstInfo() {
                const keysToCheck = ["street", "district", "province", "city", "postal_code", "fullname", "gender", "nationality", "birth_date"];

                for (const key of keysToCheck) {
                    if (!this.bodyForm[key]) {
                        return true;
                    }
                }

                return false;
            },
            invalidCreds() {
                // Check if password and confirm password match
                if (this.bodyForm.password !== this.bodyForm.confirm) {
                    this.errorTag("Password does not match");
                    return true;
                } else {
                    this.errorTag();
                }

                // Check if any field is empty
                if (!this.bodyForm.username || !this.bodyForm.email || !this.bodyForm.password || !this.bodyForm.confirm) {
                    return true;
                }

                // Check password complexity
                const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
                if (!passwordRegex.test(this.bodyForm.password)) {
                    this.errorTag("Password must contain at least eight characters, at least one number and both lower and uppercase letters, and special characters (!@#$%^&*)");
                    return true;
                }

                // Clear error message if all checks pass
                this.errorTag();

                // If all checks pass, return false
                return false;
            },
        },
        methods: {
            register() {
                event.preventDefault();

                this.bodyForm.name = this.bodyForm.fullname;
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/register-public", this.bodyForm)
                    .then(() => {
                        this.$alertSys("Account Registered Successfully! Logging In...", "success");

                        setTimeout(() => {
                            axios.post(this.$store.state.app.apiBasepath + "/user/login", { email: this.bodyForm.email, password: this.bodyForm.password }).then((response) => {
                                localStorage.setItem("token", JSON.stringify(response.data.token));
                                setTimeout(() => {
                                    this.$store.state.app.preloader.on = false;
                                    location.reload();
                                }, 250);
                            });
                        }, 1500);
                    })
                    .catch(() => {
                        this.$alertSys("An error occured", "err");
                    });
            },
            chooseImg() {
                document.getElementById("photos").click();
            },
            randomizeName() {
                return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021").toString().substring(0, 16);
            },
            uploadImg() {
                this.photoFile = document.getElementById("photos");

                if (document.getElementById("photos").files.length > 0) {
                    var fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                }

                let dataImage = "";
                if (document.getElementById("photos").value.length > 0) dataImage = document.getElementById("photos").files[0];

                axios.get("https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" + fileName, { headers: { "Access-Control-Allow-Origin": "*" } }).then((res) => {
                    axios({
                        method: "PUT",
                        url: res.data.fileUploadURL,
                        data: dataImage,
                        headers: {
                            "Content-Type": "image/jpeg",
                        },
                    }).then(() => {
                        this.bodyForm.photos = fileName;

                        document.querySelector(".photo-uploader").style.backgroundImage = `url('https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/${fileName}')`;
                    });
                });
            },
            errorTag(msg = "") {
                this.errMsg = msg;
            },
            validateUser() {
                event.preventDefault();
                let token =
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AbWFjcmEuY28uaWQiLCJwYXNzd29yZCI6IjAyYTI2NWQ2YzlmZjZkYTcwOWE2Y2Y1NTUzMzZlZDgxYWQyMGFjYzQiLCJmdWxsX25hbWUiOiJNYW5hZ2VyIiwiYWNjZXNzX2xldmVsIjo1LCJwaG90byI6bnVsbCwiYmlydGhfZGF0ZSI6IjIwMjQtMDMtMjYiLCJuYXRpb25hbGl0eSI6IkluZG8iLCJnZW5kZXIiOiJNYWxlIiwiY29tcGFueSI6bnVsbCwiaWF0IjoxNzExOTgwODk3fQ.YgUOwKz3awmsRgStG-gClmc5Qx9UAeqSq4LL9FWiWGc";

                const client = {
                    username: this.bodyForm.username,
                    email: this.bodyForm.email,
                };
                console.log(token);
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/validate-pic", client, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        this.stageIndex = 1;
                    })
                    .catch(() => {
                        this.errMsg = "Username / Email has been used.";
                    });
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
                var cfm = $("#pwd-confirm");
                if (cfm.attr("type") == "password") {
                    cfm.attr("type", "text");
                } else {
                    cfm.attr("type", "password");
                }
            },
            async toLogin() {
                await nextTick();
                this.$router.push("/");
            },
            login() {
                event.preventDefault();

                $("#loginBtn").attr("disabled", true);

                let creds = {
                    email: $("#email").val(),
                    password: $("#pwd").val(),
                };

                axios
                    .post(this.$store.state.app.apiBasepath + "/user/login", creds)
                    .then((response) => {
                        if (response.status == 200) {
                            let userData = response.data.userLogin;
                            if (userData.access_level == 1) {
                                $("#responseBlock").attr("hidden", false);
                                $("#responseMessage").html("Access Denied");
                                userData = "";
                                setTimeout(() => {
                                    $("#loginBtn").attr("disabled", false);
                                }, 1000);
                                setTimeout(() => {
                                    $("#responseBlock").attr("hidden", true);
                                }, 5000);
                            } else {
                                localStorage.setItem("token", JSON.stringify(response.data.token));
                                setTimeout(() => {
                                    this.$store.state.app.preloader.on = false;
                                    location.reload();
                                }, 250);
                            }
                        } else {
                            $("#responseBlock").attr("hidden", false);
                            $("#responseMessage").html(response.data.message);
                            setTimeout(() => {
                                $("#loginBtn").attr("disabled", false);
                            }, 1000);
                            setTimeout(() => {
                                $("#responseBlock").attr("hidden", true);
                            }, 5000);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        $("#responseBlock").attr("hidden", false);
                        $("#responseMessage").html(error.response.data.message);
                        setTimeout(() => {
                            $("#loginBtn").attr("disabled", false);
                        }, 1000);
                        setTimeout(() => {
                            $("#responseBlock").attr("hidden", true);
                        }, 5000);
                    });
            },

            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.userLogin;
                        this.$store.state.userData = userData;
                        this.$router.push("/dashboard");
                        setTimeout(() => {
                            this.$store.state.app.preloader.on = false;
                            location.reload();
                        }, 100);
                    })
                    .catch((error) => {
                        this.$store.state.app.preloader.on = false;
                        console.log(error);
                        $("#responseBlock").attr("hidden", false);
                        $("#responseMessage").html("keep-login: " + error.data.message);
                        setTimeout(() => {
                            $("#loginBtn").attr("disabled", false);
                        }, 1000);
                        setTimeout(() => {
                            $("#responseBlock").attr("hidden", true);
                        }, 5000);
                    });
            },
            demoInit() {
                this.bodyForm = this.demo;

                setTimeout(() => {
                    this.validateUser();
                }, 1000);
            },
        },
        created() {},
        mounted() {
            this.$store.state.app.preloader.on = true;
            setTimeout(() => {
                let token = localStorage.getItem("token");
                if (token) {
                    this.keepLogin(token);
                } else {
                    this.$store.state.app.preloader.on = false;
                }

                // this.demoInit();
            }, 1000);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .photo-uploader {
        background-color: #fbfbfb;
        border: 1px dashed #3699ff;
        color: #3699ff;
        border-radius: 50%;
        margin: 10% 0;
        align-self: center;

        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 42px;

        cursor: pointer;
        user-select: none;
        transition: all 0.2s ease;

        &:hover {
            filter: brightness(0.7);
        }
        &:active {
            filter: brightness(0.9);
        }
    }
    .form-group {
        max-height: 60vh;
        overflow: auto;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3699ff;
            border-radius: 6px;
        }
    }
    .form {
        padding: 10%;
        text-align: left;
    }

    .right-text {
        text-align: right;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 0px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: 1px solid lightgrey;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: whitesmoke;
        padding: 5px;
        box-sizing: border-box;
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        background-color: transparent;
    }
    .adjusted input:focus + label,
    .adjusted input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 20px;
        color: #3951b2;
        background-color: transparent;
    }
</style>

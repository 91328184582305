<template>
    <div v-if="show" class="loader-wrapper">
        <img src="https://static.wixstatic.com/media/f244d7_98c8fcdb45ea489999e790176f813eef~mv2.gif" />
    </div>
</template>
<script>
    export default {
        computed: {
            show() {
                return this.$store.state.showLoader;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .loader-wrapper {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);

        img {
            mix-blend-mode: difference;
            transform: scale(0.35);
        }
    }
</style>

<template>
    <div>
        <PromotionView v-if="currentRoute.name == 'Promo'" />
        <AdsView v-if="currentRoute.name == 'Ads'" />
    </div>
</template>
<script>
    import PromotionView from "./promotionView.vue";
    import AdsView from "./adsView.vue";

    export default {
        components: { PromotionView, AdsView },
        computed: {
            currentRoute() {
                return this.$route;
            },
        },
    };
</script>
<style lang="scss" scoped></style>

<template>
    <div class="row p-2 h-100">
        <div class="col-3" style="display: flex; flex-direction: column; margin: 14px 0 0 14px">
            <div v-if="admin.access_level == 2" @click="currentTab = 'company'" :class="currentTab == 'company' ? 'active' : ''" class="row childform my-1">
                <div>Company Details</div>
            </div>
            <div @click="currentTab = 'details'" :class="currentTab == 'details' ? 'active' : ''" class="row childform my-1">
                <div v-if="admin.access_level != 2">Personal Profile</div>
                <div v-else>PIC Profile</div>
            </div>
            <div @click="currentTab = 'password'" :class="currentTab == 'password' ? 'active' : ''" class="row childform my-1"><div>Change Password</div></div>
            <div v-if="admin.access_level == 2" @click="currentTab = 'sales'" :class="currentTab == 'sales' ? 'active' : ''" class="row childform my-1"><div>Sales</div></div>
            <div v-if="admin.access_level == 3" @click="currentTab = 'clients'" :class="currentTab == 'clients' ? 'active' : ''" class="row childform my-1"><div>Clients</div></div>
            <div v-if="admin.access_level == 1" @click="currentTab = 'shippingTypes'" :class="currentTab == 'shippingTypes' ? 'active' : ''" class="row childform my-1"><div>Shipping Types</div></div>

            <div class="row mt-auto text-center text-danger mb-3 deleteaccount">
                <div v-if="this.$store.state.userData.access_level == 5" @click="deleteUser()" class="col-10">Delete Account</div>
            </div>
        </div>
        <div class="col-auto">
            <form-wrapper v-show="currentTab == 'password'">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto" id="userDetails">
                        <form-wrapper>
                            <div id="forms" class="row text-start d-flex flex-nowrap">
                                <form onsubmit="return false">
                                    <span style="font-size: 14px"
                                        ><span class="blue-filter" style="font-weight: bold; display: inline-block; border-radius: 50%; background-color: rgba(0, 0, 0, 0.15); width: 22px; text-align: center">!</span> Create new password, and make sure it’s different from the previous one.</span
                                    >
                                    <div class="label-float">
                                        <input v-model="password.new" id="pwd" type="password" placeholder=" " autocomplete="off" />
                                        <label>New password</label>
                                        <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePass()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password"></span>
                                    </div>
                                    <br />
                                    <div class="label-float" style="top: -20px">
                                        <input v-model="password.conf" id="pwdconf" type="password" placeholder=" " autocomplete="off" />
                                        <label>Re-enter new password</label>
                                        <span style="float: right; position: relative; top: -40px; left: -10px" @click="togglePassConfirm()" toggle="#password-field" class="bi bi-eye-slash-fill toggle-password-confirm"></span>
                                    </div>
                                    <div id="navigation" class="row mt-3">
                                        <div class="col p-0">
                                            <span id="warn" style="font-size: 14px" class="text-danger"></span>
                                            <span id="response" style="font-size: 14px" class="text-success"></span>
                                        </div>
                                        <div class="col p-0">
                                            <button v-if="admin.id == this.$store.state.userData.id" @click="changePassword()" style="float: right" class="btn btn-usfc-primary" z:disabled="password.new != password.conf || password.new.length < 8">Change Password</button>
                                            <button v-else @click="changeSelfPassword()" style="float: right" class="btn btn-usfc-primary" :disabled="password.new != password.conf || password.new.length < 8">Change Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <form-wrapper v-show="currentTab == 'sales'">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto" id="userDetails">
                        <div v-show="this.$store.state.userData.access_level == 5" class="label-float">
                            <div class="d-inline">
                                <input v-model="this.$store.state.app.managerForm.formSearch" class="searchbar fa" type="text" style="border: 1px solid transparent; padding: 1% 17% 1% 1% !important; font-family: Open Sans, FontAwesome" placeholder="&#xF002;  search.. " />
                            </div>
                        </div>
                        <form-wrapper>
                            <div id="forms" class="row text-start">
                                <form>
                                    <br />
                                    <div class="px-3 py-0 my-0 prevent-select">
                                        <table class="table" v-if="admin.salesId != null">
                                            <tbody>
                                                <tr class="sales">
                                                    <th v-if="admin.sales.photo == null" scope="row" class="col-2"><img src="/icons/default.svg" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>
                                                    <th v-else scope="row" class="col-2"><img :src="'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' + admin.sales.photo" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>

                                                    <td class="col text-start">
                                                        <div style="line-height: 2.5em">{{ admin.sales.full_name }}</div>
                                                        <div v-if="admin.assigned_sales_date" style="line-height: 2.5em">Assigned: {{ admin.assigned_sales_date }}</div>
                                                    </td>
                                                    <td scope="row" style="position: relative; vertical-align: middle">
                                                        <span v-show="this.$store.state.userData.access_level == 5" @click="removeSalesModal(admin.id)" class="text-danger" style="float: right; font-weight: bold; font-size: 12px"> Remove </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" v-if="this.$store.state.app.managerForm.formSearch != ''">
                                            <tbody>
                                                <tr @click="chooseSales(sales.id)" class="sales" v-for="sales in filteredSales" v-bind:key="sales.id">
                                                    <th scope="row" class="col-2"><img src="/icons/default.svg" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>
                                                    <td class="col text-start">
                                                        <span style="line-height: 2.5em">{{ sales.full_name }}</span>
                                                    </td>
                                                    <td v-if="newsales == sales.id" scope="row" style="position: relative; top: 5px">
                                                        <span style="float: right; vertical-align: middle">
                                                            <span v-if="sales.id == admin.salesId" class="mx-3" style="font-size: 12px; opacity: 0.5">Current</span>
                                                            <img src="/icons/custom/picker.svg" />
                                                        </span>
                                                    </td>
                                                    <td v-else scope="row" style="position: relative; top: 5px">
                                                        <span style="float: right; vertical-align: middle">
                                                            <span v-if="sales.id == admin.salesId" class="mx-3" style="font-size: 12px; opacity: 0.5">Current</span>
                                                            <img src="/icons/custom/picker-deselect.svg" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div v-show="this.$store.state.userData.access_level == 5" class="text-center" v-else style="font-size: 14px; opacity: 0.4">Type the sales name above</div>
                                    </div>
                                </form>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <form-wrapper v-show="currentTab == 'clients'">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto" id="userDetails">
                        <form-wrapper>
                            <div id="forms" class="row text-start">
                                <form>
                                    <br />
                                    <div class="px-3 py-0 my-0 prevent-select">
                                        <table class="table">
                                            <tbody>
                                                <tr v-for="each in admin.client" v-bind:key="each.id" class="sales">
                                                    <th v-if="each.photo == null" scope="row" class="col-2" style="vertical-align: middle"><img src="/icons/default.svg" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>
                                                    <th v-else scope="row" class="col-2" style="vertical-align: middle"><img :src="'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' + each.photo" style="object-fit: cover; height: 42px; border-radius: 50%; width: 42px" /></th>
                                                    <td class="col text-start">
                                                        <div style="line-height: 2.5em">{{ each.company }}</div>
                                                        <div style="line-height: 0.5em; font-size: 12px; opacity: 0.6">PIC : {{ each.full_name }}</div>
                                                    </td>
                                                    <td scope="row" style="position: relative; vertical-align: middle">
                                                        <span v-if="this.$store.state.userData.access_level == 5 && edit" @click="removeSalesModal(each.id)" class="text-danger" style="float: right; font-weight: bold; font-size: 12px"> Remove </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div v-if="this.$store.state.userData.access_level == 5" class="text-center" style="font-size: 14px; opacity: 0.4">Add more Client to this account by adding this Sales Account on the Client edit page</div>
                                    </div>
                                </form>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <form-wrapper v-show="currentTab == 'company'">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto" id="userDetails">
                        <form-wrapper>
                            <div v-if="admin.photo == null" id="profilePicture" class="row mx-auto mt-2">
                                <div class="col-auto mx-auto">
                                    <input @change="showPreview()" type="file" accept="image/jpeg" id="pict" hidden />
                                    <img @click="changePic()" id="pictureImg" src="/icons/default_company.svg" />
                                    <span @click="changePic()" id="pictureImgBtn" class="p-2 d-flex justify-content-center"> <img id="pictureBtn" src="/icons/all/outline/edit-2.svg" /></span>
                                </div>
                            </div>
                            <div v-else id="profilePicture" class="row mx-auto mt-2">
                                <div class="col-auto mx-auto">
                                    <input @change="showPreview()" type="file" accept="image/jpeg" id="pict" hidden />
                                    <img @click="changePic()" id="pictureImg" :src="'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' + admin.photo" />
                                    <span @click="changePic()" id="pictureImgBtn" class="p-2 d-flex justify-content-center"> <img id="pictureBtn" src="/icons/all/outline/edit-2.svg" /></span>
                                </div>
                            </div>
                            <div id="forms" class="row text-start d-flex flex-nowrap">
                                <form onsubmit="return false">
                                    <div class="label-float">
                                        <input id="fullname" type="text" placeholder=" " v-model="admin.company" autocomplete="off" :disabled="edit == false" />
                                        <label>Company Name</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="email" type="email" placeholder=" " v-model="admin.street" autocomplete="off" :disabled="edit == false" />
                                        <label>Address</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="username" type="text" placeholder=" " v-model="admin.district" autocomplete="off" :disabled="edit == false" />
                                        <label>District</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="nationality" type="text" placeholder=" " v-model="admin.city" autocomplete="off" :disabled="edit == false" />
                                        <label>City</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="nationality" type="text" placeholder=" " v-model="admin.province" autocomplete="off" :disabled="edit == false" />
                                        <label>Province</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="nationality" type="text" placeholder=" " v-model="admin.postal_code" autocomplete="off" :disabled="edit == false" />
                                        <label>Postal Code</label>
                                    </div>
                                </form>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <form-wrapper v-show="currentTab == 'details'">
                <div class="row d-flex justify-content-center mb-4">
                    <div class="col-auto" id="userDetails">
                        <form-wrapper>
                            <div v-show="admin.access_level != 2" v-if="admin.photo == null" id="profilePicture" class="row mx-auto mt-2">
                                <div class="col-auto mx-auto">
                                    <input @change="showPreviewProfile()" type="file" accept="image/jpeg" id="pictprofile" hidden />
                                    <img @click="changePicProfile()" id="pictureImgProfile" src="/icons/default.svg" />
                                    <span @click="changePicProfile()" id="pictureImgBtn" class="p-2 d-flex justify-content-center"> <img id="pictureBtn" src="/icons/all/outline/edit-2.svg" /></span>
                                </div>
                            </div>
                            <div v-show="admin.access_level != 2" v-else id="profilePicture" class="row mx-auto mt-2">
                                <div class="col-auto mx-auto">
                                    <input @change="showPreviewProfile()" type="file" accept="image/jpeg" id="pictprofile" hidden />
                                    <img @click="changePicProfile()" id="pictureImgProfile" :src="'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' + admin.photo" />
                                    <span @click="changePicProfile()" id="pictureImgBtn" class="p-2 d-flex justify-content-center"> <img id="pictureBtn" src="/icons/all/outline/edit-2.svg" /></span>
                                </div>
                            </div>
                            <div id="forms" class="row text-start d-flex flex-nowrap">
                                <form onsubmit="return false">
                                    <div class="label-float">
                                        <input id="fullname" type="text" placeholder=" " v-model="admin.full_name" autocomplete="off" :disabled="edit == false" />
                                        <label>Full Name </label>
                                    </div>
                                    <div class="label-float">
                                        <input id="email" type="email" placeholder=" " v-model="admin.email" autocomplete="off" :disabled="edit == false" />
                                        <label>Email</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="username" type="text" placeholder=" " v-model="admin.username" autocomplete="off" :disabled="edit == false" />
                                        <label>Username</label>
                                    </div>
                                    <div class="label-float">
                                        <label :class="admin.gender == '' ? '' : 'option-selected'">Gender</label>
                                        <label v-if="admin.gender == ''" :class="admin.gender == '' ? '' : 'option-selected'" style="display: block; width: 90%; text-align: right; opacity: 0.4"><img style="transform: scale(0.9)" src="/icons/all/outline/arrow-down-1.svg" /></label>
                                        <select v-model="admin.gender" placeholder=" " :disabled="edit == false">
                                            <option disabled></option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </select>
                                    </div>
                                    <div class="label-float">
                                        <input id="nationality" type="text" placeholder=" " v-model="admin.nationality" autocomplete="off" :disabled="edit == false" />
                                        <label>Nationality</label>
                                    </div>
                                    <div class="label-float">
                                        <input id="birthdate" type="date" placeholder=" " v-model="admin.birth_date" autocomplete="off" :disabled="edit == false" />
                                        <label>Birthdate</label>
                                    </div>

                                    <div class="label-float">
                                        <input id="phone" type="tel" placeholder=" " v-model="admin.phone" autocomplete="off" :disabled="edit == false" />
                                        <label>Phone Number</label>
                                    </div>
                                </form>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <form-wrapper v-show="currentTab == 'shippingTypes'">
                <div class="row d-flex justify-content-center mb-4">
                    <div class="col-auto" id="userDetails">
                        <form-wrapper>
                            <div class="d-inline text-start">
                                <input v-model="querySearch" class="searchbar fa" type="text" style="padding: 12px 1% !important; font-family: Open Sans, FontAwesome" placeholder="&#xF002;  search.. " />
                            </div>
                            <div class="row mx-2 my-3" style="font-weight: 600" :class="[!edit ? 'disabled' : '']">
                                <div class="col-4 py-2">Transportation Mode :</div>
                                <div @click="filterShipping('')" class="col-1 mx-2 py-2 trans-option-item" :class="filterState == '' ? 'active' : ''">All</div>
                                <div @click="filterShipping('air')" class="col-1 mx-2 py-2 trans-option-item" :class="filterState == 'air' ? 'active' : ''">Air</div>
                                <div @click="filterShipping('land')" class="col-2 py-2 trans-option-item" :class="filterState == 'land' ? 'active' : ''">Land</div>
                                <div @click="filterShipping('water')" class="col-2 py-2 trans-option-item" :class="filterState == 'water' ? 'active' : ''">Water</div>
                            </div>
                            <div id="forms" class="row text-start" :class="[!edit ? 'disabled' : '']">
                                <div class="px-3 py-0 my-0 prevent-select" style="height: 50vh; overflow: auto">
                                    <table class="table">
                                        <tbody>
                                            <tr style="line-height: 36px; border: 1px solid transparent" v-for="admin in filteredShipping" :key="admin?.id" @click="add(admin?.id)">
                                                <td style="cursor: pointer; width: 30px" class="blue-filter" scope="row">
                                                    <img v-if="admin?.transportation_mode?.toLowerCase() == 'air'" src="/icons/all/twotone/airplane.svg" />
                                                    <img v-else-if="admin?.transportation_mode?.toLowerCase() == 'water'" src="/icons/all/twotone/ship.svg" />
                                                    <img v-else src="/icons/all/twotone/truck.svg" />
                                                </td>
                                                <td style="cursor: pointer">{{ admin.name }}</td>

                                                <td class="text-end" v-if="admin?.is_real_time" style="cursor: pointer; font-size: 12px; opacity: 0.5">Live Tracking Available</td>
                                                <td class="text-end" v-else style="cursor: pointer; font-size: 12px"></td>
                                                <th style="cursor: pointer; width: 30px" v-if="this.selectedShippings.includes(admin?.id)" scope="row">
                                                    <img src="/icons/custom/picker.svg" />
                                                </th>
                                                <th style="cursor: pointer; width: 30px" v-else scope="row">
                                                    <img src="/icons/custom/picker-deselect.svg" />
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form-wrapper>
                    </div>
                </div>
            </form-wrapper>
            <div v-if="currentTab != 'password'" id="navigation" class="row">
                <div class="col p-0 mx-2">
                    <span v-show="this.$store.state.userData.access_level == 5" v-if="edit == false" @click="edit = true" style="float: right" class="mx-2 btn btn-usfc-edit">Edit </span>
                    <span v-if="edit == true" @click="update()" style="float: right" class="mx-2 btn btn-usfc-primary">Update & Save</span>
                    <span v-if="edit == true" @click="revert()" style="float: right" class="mx-2 btn btn-cancel">Cancel</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableView from "./TableView.vue";
    import $ from "jquery";
    import axios from "axios";
    import moment from "moment";
    /* eslint-disable */

    export default {
        name: "UserDetailComponent",
        props: {},
        components: { TableView },
        data() {
            return {
                selectedShippings: [],
                querySearch: "",
                allShipping: {},
                modeFilter: "",
                filterState: "",
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                currentTab: "company",
                edit: false,
                password: {
                    new: "",
                    conf: "",
                },
                newsales: null,
                currentSales: [],
                newclients: [],
            };
        },
        computed: {
            filteredShipping() {
                if (this.querySearch == "") {
                    return this.allShipping;
                }

                return this.allShipping.filter((shipping) => {
                    return shipping.name.toLowerCase().includes(this.querySearch.toLowerCase());
                });
            },
            admin() {
                return this.$store.state.app.managerForm.userDetailForm;
            },
            filteredSales() {
                if (this.$store.state.app.managerForm.formSearch == "") {
                    this.ascSort();
                    return this.$store.state.app.managerForm.filteredSales;
                }

                return this.$store.state.app.managerForm.allSales.filter((client) => {
                    return client.full_name.toLowerCase().includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
                });
            },
            filteredClients() {
                if (this.$store.state.app.managerForm.formSearch == "") {
                    this.ascSort();
                    return this.$store.state.app.managerForm.filteredClients;
                }

                return this.$store.state.app.managerForm.allClients.filter((client) => {
                    return client.full_name.toLowerCase().includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
                });
            },
            getLoadForm() {
                return this.loadForm;
            },
            currentMenu() {
                return this.menu;
            },
            currentSubmenu() {
                return this.submenu;
            },
            adminDetails() {
                return this.getAdminDetails();
            },
            isUserAction() {
                return this.userAction;
            },
        },
        methods: {
            getAllAdmins(param = "") {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/shipping-type/all" + param, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: " + res);
                        this.allShipping = res.data.rows;
                        this.ascSort();
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            add(id) {
                if (this.edit) {
                    if (this.selectedShippings.includes(id)) {
                        this.removeSelected(id);
                    } else {
                        this.selectedShippings.push(id);
                    }
                }
            },
            removeSelected(val) {
                if (this.edit) {
                    const index = this.selectedShippings.indexOf(val);
                    if (index > -1) {
                        // only splice array when item is found
                        this.selectedShippings.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
            },
            searchShipping(query = "", filter = this.modeFilter) {
                this.filterState = filter;
                if (!query) {
                    this.getAllAdmins("?filter=" + filter);
                } else {
                    this.getAllAdmins("?filter=" + filter + "&search=" + query);
                }
            },
            filterShipping(query = this.modeFilter) {
                if (!query) {
                    this.filterState = "";

                    this.getAllAdmins();
                } else {
                    this.filterState = query;
                    this.getAllAdmins("?filter=" + query);
                }
            },
            chooseSales(id) {
                if (this.newsales == id) {
                    this.newsales = null;
                } else {
                    this.newsales = id;
                }

                this.edit = true;
            },
            ascSort() {
                function compare(a, b) {
                    if (a.full_name < b.full_name) return -1;
                    if (a.full_name > b.full_name) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.$store.state.app.managerForm.allSales.sort(compare);
                this.$store.state.app.managerForm.allClients.sort(compare);
            },
            getAllSales() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/all-sales", { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.$store.state.app.managerForm.allSales = res.data.rows;
                        this.ascSort();
                    })
                    .catch((err) => {
                        console.log("500: " + err);
                    });
            },
            getAllClients() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/user/all-clients", { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.$store.state.app.managerForm.allClients = res.data.rows;
                        this.ascSort();
                    })
                    .catch((err) => {
                        console.log("500: " + err);
                    });
            },
            changePic() {
                $("#pict").click();
            },
            changeSelfPic() {
                $("#pict").click();
            },
            changePicProfile() {
                $("#pictprofile").click();
            },
            showPreview() {
                if (this.$route.path.split("/").pop() == this.$store.state.userData.id) {
                    return this.showSelfPreview();
                }

                console.log("fail");
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                    this.edit = true;
                }
            },
            showSelfPreview() {
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                }

                let newForm = {};

                this.photoFile = document.getElementById("pict");

                if (document.getElementById("pict").files.length > 0) {
                    var fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                    newForm.photo = fileName;
                }
                if (document.getElementById("pictprofile").files.length > 0) {
                    var fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                    newForm.photo = fileName;
                }

                let dataImage = "";
                if (document.getElementById("pictprofile").value.length > 0) {
                    dataImage = document.getElementById("pictprofile").files[0];
                } else {
                    dataImage = document.getElementById("pict").files[0];
                }

                let token = JSON.parse(localStorage.getItem("token"));
                axios
                    .get("https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" + fileName, { headers: { "Access-Control-Allow-Origin": "*" } })
                    .then((res) => {
                        axios({
                            method: "PUT",
                            url: res.data.fileUploadURL,
                            data: dataImage,
                            headers: {
                                "Content-Type": "image/jpeg",
                            },
                        }).then(() => {
                            axios
                                .patch(this.$store.state.app.apiBasepath + "/user/change-photo/", newForm, { headers: { Authorization: `Bearer ${token}` } })
                                .then(() => {
                                    this.$alertSys("Profile Details Updated!", "success");
                                    this.edit = false;
                                    this.$store.state.userData.photo == null;
                                    this.getDetail(this.$route.params.id);
                                })
                                .catch(() => {
                                    this.$alertSys("An Error Occured", "err");
                                });
                        });
                    })
                    .catch(() => {
                        this.$alertSys("An error occured", "err");

                        this.edit = false;
                        this.getDetail(this.$route.params.id);
                    });
            },
            showPreviewProfile() {
                if (this.$route.path.split("/").pop() == this.$store.state.userData.id) {
                    return this.showSelfPreview();
                }
                let pic = document.getElementById("pictprofile");
                console.log(pic);
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImgProfile");
                    preview.src = src;
                    this.edit = true;
                }
            },
            revert() {
                this.edit = false;
                this.getDetail(this.$route.params.id);
            },
            randomizeName() {
                var result = "";
                var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                var charactersLength = characters.length;
                for (var i = 0; i < 16; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },
            async update() {
                this.edit = false;
                let token = JSON.parse(localStorage.getItem("token"));
                let newForm = {
                    full_name: this.admin.full_name,
                    username: this.admin.username,
                    phone: this.admin.phone,
                    email: this.admin.email,
                    gender: this.admin.gender,
                    birth_date: this.admin.birth_date,
                    nationality: this.admin.nationality,
                    company: this.admin.company,
                    street: this.admin.street,
                    district: this.admin.district,
                    city: this.admin.city,
                    province: this.admin.province,
                    postal_code: this.admin.postal_code,
                    salesId: this.admin.salesId,
                };

                if (this.currentTab === "company" || this.currentTab === "sales") {
                    delete newForm.full_name;
                    delete newForm.username;
                    delete newForm.phone;
                    delete newForm.email;
                    delete newForm.birth_date;
                    delete newForm.nationality;
                    delete newForm.gender;
                }

                if (this.currentTab == "details" && newForm.company) {
                    delete newForm.company;
                    delete newForm.street;
                    delete newForm.city;
                    delete newForm.district;
                    delete newForm.province;
                    delete newForm.postal_code;
                }
                if (this.admin.access_level === 1 && this.currentTab === "shippingTypes") {
                    const promises = [];
                    this.shippingBuffer.forEach((item) => {
                        const deleteOne = { userId: this.$route.params.id, shippingTypeId: item };

                        const promise = axios.delete(this.$store.state.app.apiBasepath + "/user/transporter-shipping-type", {
                            headers: { Authorization: `Bearer ${token}` },
                            data: deleteOne,
                        });
                        promises.push(promise);
                    });

                    const shipping = {
                        // userId: this.admin.id,
                        shippingTypeId: this.selectedShippings,
                    };

                    Promise.all(promises).then((res) => {
                        axios
                            .post(this.$store.state.app.apiBasepath + "/user/transporter-shipping-type/" + this.$route.params.id, shipping, { headers: { Authorization: `Bearer ${token}` } })
                            .then((res) => {
                                this.$alertSys("Shipping Types Updated!", "success");
                                this.edit = false;
                                this.getDetail(this.$route.params.id);
                            })
                            .catch(() => {
                                this.$alertSys("An error occured", "err");
                                this.edit = true;
                            });
                    });

                    return;
                }

                if (this.newsales != null) {
                    newForm.salesId = this.newsales;
                    newForm.assigned_sales_date = moment().format("YYYY-MM-DD");
                }

                if ($("#pwd").val()) {
                    newForm.password = $("#pwd").val();
                }

                this.photoFile = document.getElementById("pict");

                if (document.getElementById("pict").files.length > 0) {
                    var fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                    newForm.photo = fileName;
                }
                if (document.getElementById("pictprofile").files.length > 0) {
                    var fileName = this.randomizeName() + "." + this.photoFile.value.split(".").pop();
                    newForm.photo = fileName;
                }

                if (document.getElementById("pict").value.length > 0 || document.getElementById("pictprofile").value.length > 0) {
                    let dataImage = "";
                    if (document.getElementById("pictprofile").value.length > 0) {
                        dataImage = document.getElementById("pictprofile").files[0];
                    } else {
                        dataImage = document.getElementById("pict").files[0];
                    }
                    axios
                        .get("https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" + fileName, { headers: { "Access-Control-Allow-Origin": "*" } })
                        .then((res) => {
                            axios({
                                method: "PUT",
                                url: res.data.fileUploadURL,
                                data: dataImage,
                                headers: {
                                    "Content-Type": "image/jpeg",
                                },
                            }).then(() => {
                                axios
                                    .patch(this.$store.state.app.apiBasepath + "/user/edit/" + this.$route.params.id, newForm, { headers: { Authorization: `Bearer ${token}` } })
                                    .then(() => {
                                        this.$alertSys("Profile Updated!", "success");
                                        this.edit = false;
                                        this.$store.state.app.managerForm.formSearch = "";
                                        this.getDetail(this.$route.params.id);
                                    })
                                    .catch(() => {
                                        this.$alertSys("An error occured", "err");
                                    });
                            });
                        })
                        .catch((err) => {
                            this.$alertSys("An error occured", "err");
                            this.edit = false;
                            this.getDetail(this.$route.params.id);
                        });
                } else {
                    axios
                        .patch(this.$store.state.app.apiBasepath + "/user/edit/" + this.$route.params.id, newForm, { headers: { Authorization: `Bearer ${token}` } })
                        .then(() => {
                            this.$alertSys("Profile Updated!", "success");
                            this.edit = false;
                            this.$store.state.app.managerForm.formSearch = "";
                            this.getDetail(this.$route.params.id);
                        })
                        .catch(() => {
                            console.log("aaa" + err);
                            this.$alertSys("An error occured", "err");
                        });
                }
            },
            changePassword() {
                let body = {
                    id: this.$route.params.id,
                    password: this.password.new,
                };

                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .patch(this.$store.state.app.apiBasepath + "/user/change-password-another/", body, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        $("#response").html("Password Changed");
                        setTimeout(() => {
                            $("#response").html("");
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        $("#warn").html(err.response.data.message);
                        setTimeout(() => {
                            $("#warn").html("");
                        }, 3000);
                    });
            },
            changeSelfPassword() {
                let body = {
                    id: this.$route.params.id,
                    password: this.password.new,
                };

                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .patch(this.$store.state.app.apiBasepath + "/user/change-password-another/", body, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        $("#response").html("Password Changed");
                        setTimeout(() => {
                            $("#response").html("");
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        $("#warn").html(err.response.data.message);
                        setTimeout(() => {
                            $("#warn").html("");
                        }, 3000);
                    });
            },

            getDetail(id) {
                let token = JSON.parse(localStorage.getItem("token"));
                let access_level = this.$store.state.app.managerForm.userDetail;
                let access_link = null;

                if (access_level == 5) {
                    access_link = "manager-details";
                } else if (access_level == 4) {
                    access_link = "admin-details";
                } else if (access_level == 3) {
                    access_link = "sales-details";
                } else if (access_level == 2) {
                    access_link = "client-details";
                } else if (access_level == 1) {
                    access_link = "transporter-details";
                } else {
                    history.back();
                }

                if (access_link) {
                    axios
                        .get(this.$store.state.app.apiBasepath + "/user/" + access_link + "/" + id, { headers: { Authorization: `Bearer ${token}` } })
                        .then((res) => {
                            console.log("200: " + res);
                            this.$store.state.app.managerForm.userDetailForm = res.data;
                            if (this.$route.path.split("/").pop() == this.$store.state.userData.id) {
                                this.$store.state.userData.photo = null;
                                this.$store.state.userData.photo = res.data.photo;
                            }

                            let level = "";
                            if (this.admin.access_level == 1) {
                                level = "Transporter";
                                let selected = [];
                                if (this.admin.shipping_types)
                                    this.admin.shipping_types.map((shipping) => {
                                        selected.push(shipping.id);
                                    });
                                this.selectedShippings = selected;
                                this.shippingBuffer = this.selectedShippings;
                            }
                            if (this.admin.access_level == 2) {
                                level = "Client";
                            }
                            if (this.admin.access_level == 3) {
                                level = "Sales";
                            }
                            if (this.admin.access_level == 4) {
                                level = "Admin";
                            }
                            if (this.admin.access_level == 5) {
                                level = "Manager";
                            }
                            this.$store.state.app.breadcrumb.currentSubSubMenu = level + "  /  " + this.admin.full_name;

                            this.selects = res.data.gender;
                            if (this.admin.salesId != null) {
                                this.getCurrentSalesDetails();
                            }
                        })
                        .catch(() => {
                            this.$alertSys("Unauthorized", "err");
                        });
                }
            },
            deleteUser() {
                this.$store.state.app.managerForm.idSelects.push(this.$route.params.id);
                this.$store.state.app.openModal = "adminDeleteConfirmation";
            },
            removeSalesModal(id) {
                this.$store.state.app.managerForm.idSelects.push(id);
                this.$store.state.app.openModal = "salesRemoveConfirmation";
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").scrollLeft("2000px");
                this.stageIndex++;
            },
            getAdminDetails() {
                let token = localStorage.getItem("token");
                axios.post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                    localStorage.setItem("token", JSON.stringify(response.data.token));

                    let userData = response.data.userLogin;
                    this.$store.state.userData = userData;
                    console.log(response);
                    this.$router.push("/dashboard");
                });
            },
            getCurrentSalesDetails() {
                let token = JSON.parse(localStorage.getItem("token"));
                axios.get(this.$store.state.app.apiBasepath + "/user/sales-details/" + this.admin.salesId, { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                    let userData = response.data;
                    this.currentSales = userData;
                    console.log(response);
                });
            },
            getCurrentClientDetails() {
                let token = JSON.parse(localStorage.getItem("token"));
                axios.get(this.$store.state.app.apiBasepath + "/user/client-details/" + this.admin.salesId, { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
                    let userData = response.data;
                    this.currentSales = userData;
                    console.log(response);
                });
            },
            removeClient(id) {
                let token = JSON.parse(localStorage.getItem("token"));
                axios
                    .patch(this.$store.state.app.apiBasepath + "/user/remove-sales/" + id, {}, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        this.$alertSys("Profile Details Updated!", "success");
                        this.edit = false;
                        this.getDetail(this.$route.params.id);
                    })
                    .catch(() => {
                        this.$alertSys("An Error Occured", "ERR");
                    });
            },
            removeSales() {
                let token = JSON.parse(localStorage.getItem("token"));
                axios
                    .patch(this.$store.state.app.apiBasepath + "/user/remove-sales/" + this.$route.params.id, {}, { headers: { Authorization: `Bearer ${token}` } })
                    .then(() => {
                        this.$alertSys("Profile Details Updated!", "success");
                        this.edit = false;
                        this.getDetail(this.$route.params.id);
                    })
                    .catch(() => {
                        this.$alertSys("An Error Occured", "ERR");
                    });
            },
        },
        mounted() {
            this.getAllSales();
            this.getAllAdmins();
            this.getAllClients();
            this.getDetail(this.$route.params.id);

            setTimeout(() => {
                if (this.admin.access_level !== 2) {
                    this.currentTab = "details";
                }
            }, 250);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    form-wrapper {
        transition: all 0.3s linear;
    }
    .disabled {
        opacity: 0.4;
    }
    .trans-option-item {
        text-align: center;
        opacity: 1;
        cursor: pointer;
        height: 36px;
        transition: all 0.3s linear;
    }

    .trans-option-item.active {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }
    .deleteaccount {
        transition: all 0.3s ease;
        font-size: 14px;
        cursor: pointer;
    }

    span.text-danger:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    .deleteaccount:hover {
        text-decoration: underline;
    }

    #forms {
        height: 365px;
        overflow-y: scroll;
        border: 1px solid transparent;
        margin-top: 10px;
    }

    #forms.company {
        height: 100%;
    }

    #userDetails {
        width: 550px;
        padding: 0;
    }

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: relative;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgProfile {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: relative;
        bottom: 25px;
        left: 75px;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .childform {
        padding-left: 15px;
        line-height: 40px;
        border-radius: 6px;
        min-width: 150px;
        width: 80%;
        cursor: pointer;
    }

    .childform:hover {
        background-color: rgba(0, 0, 0, 0.15);
        filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg) brightness(103%) contrast(103%);
    }

    .childform.active {
        background-color: rgba(0, 0, 0, 0.15);
        filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg) brightness(103%) contrast(103%);
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    input:disabled {
        color: rgba(0, 0, 0, 0.4);
    }
    select:disabled > option {
        color: rgba(0, 0, 0, 0.4);
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .btn.btn-usfc-edit {
        min-width: 120px;
        color: #3699ff;
        background-color: white;
        border-radius: 6px;
        padding: 10px 25px;
        border: 1px solid #3699ff;
    }

    .btn-usfc-edit:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>

<template>
  <div class="px-3 py-0 my-0 prevent-select">
    <span style="font-size: 10px; opacity: 0.5"
      >Fill the following fields in order</span
    >
    <div class="row mb-2 fade anim-1">
      <div class="col">
        <div class="label-float">
          <label :class="shipping_type == '' ? '' : 'option-selected'">
            <span v-if="shipping_type != ''">Shipping Type</span>
            <span v-else
              ><span style="font-size: 10px; position: relative; top: -2px"
                >(3)</span
              >
              Select Shipping Type</span
            >
          </label>
          <label
            v-if="shipping_type == ''"
            :class="shipping_type == '' ? '' : 'option-selected'"
            style="display: block; width: 90%; text-align: right; opacity: 0.4"
            ><img
              style="transform: scale(0.9)"
              src="/icons/all/outline/arrow-down-1.svg"
          /></label>
          <select
            @input="validateDrop(0)"
            id="stage3"
            v-model="shipping_type"
            placeholder=" "
          >
            <option v-if="shipping_type != ''" value="">
              <span v-if="shipping_type != ''">Select Shipping Type </span>
            </option>
            <option
              v-show="
                data.shipping_type.name !=
                allData[index - 1]?.shipping_type.name
              "
              v-for="(data, index) in allData"
              :key="data.id"
            >
              {{ data.shipping_type.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="label-float">
          <label :class="origin == '' ? '' : 'option-selected'">
            <span v-if="origin != ''">Origin</span>
            <span v-else
              ><span style="font-size: 10px; position: relative; top: -2px"
                >(1)</span
              >
              Select Origin</span
            >
          </label>
          <label
            v-if="origin == ''"
            :class="origin == '' ? '' : 'option-selected'"
            style="display: block; width: 90%; text-align: right; opacity: 0.4"
            ><img
              style="transform: scale(0.9)"
              src="/icons/all/outline/arrow-down-1.svg"
          /></label>
          <select
            @input="validateDrop(1)"
            id="stage1"
            :disabled="shipping_type == ''"
            v-model="origin"
            placeholder=" "
          >
            <option v-if="origin != ''" value="">
              <span v-if="origin != ''">Select Origin </span>
            </option>
            <option
              v-show="data.origin.city != allData[index - 1]?.origin.city"
              v-for="(data, index) in allData"
              :key="data.id"
            >
              {{ data.origin.city }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="label-float">
          <label :class="destination == '' ? '' : 'option-selected'">
            <span v-if="destination != ''">Destination</span>
            <span v-else
              ><span style="font-size: 10px; position: relative; top: -2px"
                >(2)</span
              >
              Select Destination</span
            >
          </label>
          <label
            v-if="destination == ''"
            :class="destination == '' ? '' : 'option-selected'"
            style="display: block; width: 90%; text-align: right; opacity: 0.4"
            ><img
              style="transform: scale(0.9)"
              src="/icons/all/outline/arrow-down-1.svg"
          /></label>
          <select
            @input="validateDrop(2)"
            id="stage2"
            :disabled="origin == ''"
            v-model="destination"
            placeholder=" "
          >
            <option v-if="destination != ''" value="">
              <span v-if="destination != ''"
                >Select Destination {{ destination }}</span
              >
            </option>
            <option
              v-show="
                data.destination.city !=
                filteredDestination[index - 1]?.destination.city
              "
              v-for="(data, index) in filteredDestination"
              :key="data.id"
            >
              {{ data.destination.city }}
            </option>
          </select>
        </div>
      </div>

      <div class="col">
        <div class="label-float">
          <label :class="provider == '' ? '' : 'option-selected'">
            <span v-if="provider != ''">Provider</span>
            <span v-else
              ><span style="font-size: 10px; position: relative; top: -2px"
                >(4)</span
              >
              Select Provider</span
            >
          </label>
          <label
            v-if="provider == ''"
            :class="provider == '' ? '' : 'option-selected'"
            style="display: block; width: 90%; text-align: right; opacity: 0.4"
            ><img
              style="transform: scale(0.9)"
              src="/icons/all/outline/arrow-down-1.svg"
          /></label>
          <select
            @input="validateDrop(3)"
            id="stage4"
            :disabled="shipping_type == ''"
            v-model="provider"
            placeholder=" "
          >
            <option v-if="provider != ''" value="">
              <span v-if="provider != ''">Select Provider {{ provider }}</span>
            </option>
            <option
              v-show="
                data.provider.name != filteredProvider[index - 1]?.provider.name
              "
              v-for="(data, index) in filteredProvider"
              :key="data.id"
            >
              {{ data.provider.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row fade anim-3">
      <div class="col"></div>
      <div class="col py-0 my-0">
        <button
          :disabled="
            !(
              origin != '' &&
              destination != '' &&
              shipping_type &&
              provider != ''
            )
          "
          class="btn btn-usfc-primary my-3"
          style="font-size: 12px; float: right; width: 150px"
          @click="displayData()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";
import $ from "jquery";
import EmptyState from "@/reusables/EmptyState.vue";
import FormLoading from "@/reusables/formLoading.vue";
import Paginate from "vuejs-paginate-next";

export default {
  name: "ManagerComponent",
  props: {},
  components: {
    Paginate,
    EmptyState,
    FormLoading,
  },
  data() {
    return {
      sortType: "ASC",
      page: 1,
      refSelects: [],
      admins: [],
      allOrigin: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      bodyForm: {
        gender: "",
      },
    };
  },
  computed: {
    // 2 way computed data of DATA MODELS
    allData() {
      return this.$store.state.app.rateForm.checkRates.data.allData;
    },
    origin: {
      get() {
        return this.$store.state.app.rateForm.checkRates.filters.origin;
      },
      set(value) {
        this.$store.state.app.rateForm.checkRates.filters.origin = value;
      },
    },
    destination: {
      get() {
        return this.$store.state.app.rateForm.checkRates.filters.destination;
      },
      set(value) {
        this.$store.state.app.rateForm.checkRates.filters.destination = value;
      },
    },
    shipping_type: {
      get() {
        return this.$store.state.app.rateForm.checkRates.filters.shipping_type;
      },
      set(value) {
        this.$store.state.app.rateForm.checkRates.filters.shipping_type = value;
      },
    },
    provider: {
      get() {
        return this.$store.state.app.rateForm.checkRates.filters.provider;
      },
      set(value) {
        this.$store.state.app.rateForm.checkRates.filters.provider = value;
      },
    },

    // OPTION DATASETS

    filteredOrigin() {
      function compare(a, b) {
        if (a.origin.city.toLowerCase() < b.origin.city.toLowerCase())
          return -1;
        if (a.origin.city.toLowerCase() > b.origin.city.toLowerCase()) return 1;
      }

      if (this.$store.state.app.rateForm.checkRates.filters.origin == "") {
        return this.$store.state.app.rateForm.checkRates.allData;
      }

      return this.$store.state.app.rateForm.checkRates.data.allData
        .filter((obj) => {
          return obj.shipping_type.name
            .toLowerCase()
            .includes(
              this.$store.state.app.rateForm.checkRates.filters.shipping_type.toLowerCase()
            );
        })
        .sort(compare);
    },

    filteredProvider() {
      function compare(a, b) {
        if (
          a.shipping_type.name.toLowerCase() <
          b.shipping_type.name.toLowerCase()
        )
          return -1;
        if (
          a.shipping_type.name.toLowerCase() >
          b.shipping_type.name.toLowerCase()
        )
          return 1;
      }

      if (this.$store.state.app.rateForm.checkRates.filters.destination == "") {
        return this.filteredDestination;
      }

      return this.filteredDestination
        .filter((obj) => {
          return obj.destination.city
            .toLowerCase()
            .includes(
              this.$store.state.app.rateForm.checkRates.filters.destination.toLowerCase()
            );
        })
        .sort(compare);
    },

    filteredDestination() {
      function compare(a, b) {
        if (a.provider.name.toLowerCase() < b.provider.name.toLowerCase())
          return -1;
        if (a.provider.name.toLowerCase() > b.provider.name.toLowerCase())
          return 1;
      }

      if (this.$store.state.app.rateForm.checkRates.filters.origin == "") {
        return this.filteredOrigin;
      }

      return this.filteredOrigin
        .filter((obj) => {
          return obj.origin.city
            .toLowerCase()
            .includes(
              this.$store.state.app.rateForm.checkRates.filters.origin.toLowerCase()
            );
        })
        .sort(compare);
    },

    getLoadForm() {
      return this.loadForm;
    },
    totalPages() {
      return Math.ceil(this.$store.state.app.managerForm.allOrigin.length / 10);
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
    getSortType() {
      return this.sortType;
    },
  },
  methods: {
    displayData() {
      this.$store.state.app.rateForm.checkRates.data.display =
        this.filteredProvider;

      console.log(this.$store.state.app.rateForm.checkRates.data.display);

      this.$store.state.app.screenBlock.secondary = true;
      setTimeout(() => {
        this.$store.state.app.screenBlock.secondary = false;
      }, 50);
    },
    validateDrop(index_start) {
      if (index_start === 0) {
        let optData = "";
        if (
          this.$store.state.app.rateForm.checkRates.filters.mode == "Client"
        ) {
          optData = "/" + this.$store.state.userData.corporateId;
        }

        let token = JSON.parse(localStorage.getItem("token"));
        setTimeout(() => {
          axios
            .get(
              this.$store.state.app.apiBasepath +
                "/rate/all" +
                optData +
                "?search=origin&value=" +
                this.origin,
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
              this.$store.state.app.rateForm.checkRates.data.origins =
                res.data.rows;
              this.origin = "";
              this.destination = "";
              this.provider = "";
            })
            .catch((err) => {
              console.log(err);
            });
        }, 100);
      }

      if (index_start === 1) {
        setTimeout(() => {
          this.destination = "";
          this.provider = "";
        }, 100);
      }

      if (index_start === 2) {
        setTimeout(() => {
          this.provider = "";
        }, 100);
      }
    },
    openDetails(id, access_level) {
      this.$store.state.app.managerForm.userDetail = access_level;
      this.$router.push("./request/" + id);
    },

    sort() {
      if (this.getSortType == "DESC") {
        this.ascSort();
      } else {
        this.descSort();
      }

      this.$store.state.app.managerForm.refSelects = [];
    },
    ascSort() {
      function compare(a, b) {
        if (a.origin.city.toLowerCase() < b.origin.city.toLowerCase())
          return -1;
        if (a.origin.city.toLowerCase() > b.origin.city.toLowerCase()) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.rateForm.checkRates.data.allData.sort(compare);
      // this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.origin.city.toLowerCase() < b.origin.city.toLowerCase()) return 1;
        if (a.origin.city.toLowerCase() > b.origin.city.toLowerCase())
          return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allOrigin.sort(compare);
      this.paginate();
    },
    getAllAdmins() {
      let token = JSON.parse(localStorage.getItem("token"));
      this.$store.state.app.managerForm.noResult = false;
      this.$store.state.app.managerForm.formLoading = true;
      let path = "";

      if (this.$store.state.userData.access_level == 5) {
        path = "all";
      } else {
        path = "by-sales";
      }

      axios
        .get(
          this.$store.state.app.apiBasepath +
            "/client-rate-request/" +
            path +
            "?filter=" +
            3,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data.count > 0) {
            this.$store.state.app.managerForm.noResult = false;
            this.$store.state.app.managerForm.formLoading = false;
          } else {
            this.$store.state.app.managerForm.noResult = true;
            this.$store.state.app.managerForm.formLoading = false;
          }
          this.$store.state.app.managerForm.allOrigin = res.data.rows;
          this.ascSort();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredOrigin[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allOrigin.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
    ascendingSort() {
      this.allOrigin.sort((a, b) => a - b);
    },
    selectAll() {
      Object.values(this.filteredOrigin[this.page - 1]).forEach((val) => {
        this.$store.state.app.managerForm.refSelects.push(val.id);
      });
    },
    removeSelected(val) {
      const index = this.$store.state.app.managerForm.refSelects.indexOf(val);
      if (index > -1) {
        // only splice array when item is found
        this.$store.state.app.managerForm.refSelects.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
  },
  mounted() {
    let optData = "";
    if (this.$store.state.app.rateForm.checkRates.filters.mode == "Client") {
      optData = "/" + this.$store.state.userData.corporateId;
    }

    let token = JSON.parse(localStorage.getItem("token"));
    axios
      .get(this.$store.state.app.apiBasepath + "/rate/all" + optData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        this.$store.state.app.rateForm.checkRates.data.allData = res.data.rows;
        this.ascSort();
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      $(".anim-1").removeClass("fade");
      setTimeout(() => {
        $(".anim-2").removeClass("fade");
        setTimeout(() => {
          $(".anim-3").removeClass("fade");
        }, 200);
      }, 200);
    }, 200);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 0px;
}
th:hover > img {
  filter: drop-shadow(0 0 0 #3699ff);
}

td {
  line-height: 6vh;
}

option {
  line-height: 50px;
}

.row {
  transition: all 0.5s ease;
}

.pagination {
  cursor: pointer;
}
.pagination:hover {
  filter: none;
  background-color: transparent;
}

.point {
  cursor: pointer;
}

.point:hover {
  outline: 1px solid #3699ff;
}

.idSelected {
  outline: 1px solid #3699ff;
}

.page-item:hover {
  filter: none;
}
.usfc-bg {
  background-color: #fbfbfb;
}

.white-filter {
  filter: invert(100%) brightness(1) contrast(200%);
}
.scaled {
  transform: scale(0.8);
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

li.page-item {
  color: black;
}

th > img {
  cursor: pointer;
}

/* pagination stylers */
.pagination > li {
  outline: none;
  border: none;
  filter: none;
}

.page-item.active .page-link {
  color: black;
  font-weight: bold;
}

li {
  text-align: center;
  width: 35px;
  margin: 0 5px;
  transition: all 0.2s ease;
}

li:hover {
  filter: none;
  translate: 0 -10px;
  cursor: pointer;
}

.page-item .page-link {
  color: darkgrey;
}

.page-item.disabled .page-link {
  opacity: 0.5;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  background-color: #3699ff;
  color: white;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: #3699ff;
}

.page-link {
  border-radius: 10px;
}

tbody tr {
  line-height: 290%;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}

.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  border: 1px solid transparent;
}

select {
  width: 100%;
  border: 1px solid transparent;
}

select:disabled {
  opacity: 0.5;
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 10px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 5px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}
</style>

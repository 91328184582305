<template>
    <div style="min-height: 90vh">
        <div id="modalTitle" class="row">
            <div class="col p-2">
                <h5>
                    <span class="p-2" style="font-weight: 700; float: left">Gas Ref Details</span>
                    <span @click="closeModal()" id="closeModal"><img class="d-flex justify-content-center" src="/icons/all/outline/add.svg" /></span>
                </h5>
            </div>
        </div>

        <form-wrapper v-if="stageIndex == 0">
            <div id="forms" class="row text-start d-flex flex-nowrap profile" style="height: 150px; overflow-y: scroll">
                <form>
                    <div class="label-float">
                        <input :disabled="!edit" v-model="bodyForm.type" id="gasTypeForm" type="text" placeholder=" " autocomplete="off" />
                        <label>Type</label>
                    </div>
                    <div class="label-float">
                        <input :disabled="!edit" v-model="bodyForm.price" id="gasPriceForm" type="number" placeholder=" " autocomplete="off" />
                        <label>Price</label>
                    </div>
                </form>
            </div>
        </form-wrapper>

        <div id="navigation" class="row my-2">
            <div class="col">
                <button v-if="edit" @click="update()" id="finalize" style="float: right" class="mx-2 btn btn-usfc-primary">Update and Save</button>

                <span v-if="!edit" @click="edit = true" style="float: right" class="mx-2 btn btn-usfc-edit"> Edit</span>
                <span v-if="edit && stageIndex == 1" @click="prevStage()" style="float: right" class="mx-2 btn btn-usfc-edit"> Cancel</span>

                <span v-if="edit && stageIndex == 0" @click="edit = false" style="float: right" class="mx-2 btn btn-usfc-edit"> Cancel</span>
                <span v-if="!edit" @click="this.$store.state.app.openModal = ''" style="float: right" class="mx-2 btn btn-cancel"> Cancel</span>
            </div>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";

    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import axios from "axios";

    export default {
        name: "adminCreate",
        components: {},
        computed: {
            modeFilter() {
                return this.filterState;
            },
            filteredShipping() {
                if (this.querySearch == "") {
                    return this.allShipping;
                }

                return this.allShipping.filter((shipping) => {
                    return shipping.name.toLowerCase().includes(this.querySearch.toLowerCase());
                });
            },
            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },
        data() {
            return {
                edit: false,
                selectedShippings: [],
                assignedShippingIds: [],
                assignedShipping: [],
                assignedProvider: {},
                querySearch: "",
                allShipping: {},
                filterState: "",
                photo64: "",
                photoFile: "",
                access_level: 3,
                stageIndex: 0,
                pwdconf: "",
                stages: [
                    // status (0: upcoming, 1: active, 2: done)
                    {
                        name: "Fill Provider Info",
                        status: 1,
                    },
                    {
                        name: "Choose Shipping Type",
                        status: 0,
                    },
                ],
                selects: "",
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
                bodyForm: {
                    name: "",
                },
            };
        },
        methods: {
            update() {
                let token = JSON.parse(localStorage.getItem("token"));
                if (this.selectedShippings.length > 0) {
                    axios.post(this.$store.state.app.apiBasepath + "/provider/assign-type", { shippingTypeId: this.selectedShippings, providerId: [this.$store.state.app.modalData.id] }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
                        this.refreshModal();
                    });
                }

                if (this.assignedProvider.name != this.bodyForm.name) {
                    axios.patch(this.$store.state.app.apiBasepath + "/provider/edit/" + this.assignedProvider?.id, { name: this.bodyForm.name }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
                        this.refreshModal();
                    });
                }
            },
            closeModal() {
                this.$store.state.app.openModal = "";
                let temp = this.$store.state.app.managerForm.currentRefTab;
                this.$store.state.app.managerForm.currentRefTab = "";
                setTimeout(() => {
                    this.$store.state.app.managerForm.currentRefTab = temp;
                }, 50);
            },
            refreshModal() {
                var temp = {};
                temp.id = this.$store.state.app.modalData.id;
                temp.type = this.$store.state.app.openModal;
                console.log(temp);

                this.$store.state.app.modalData.id = "";
                this.$store.state.app.openModal = "";
                setTimeout(() => {
                    this.$store.state.app.modalData.id = temp.id;
                    this.$store.state.app.openModal = temp.type;
                }, 50);
            },
            add(id) {
                if (this.selectedShippings.includes(id)) {
                    this.removeSelected(id);
                } else {
                    this.selectedShippings.push(id);
                }
            },
            changePic() {
                $("#pict").click();
            },
            remove(provider, shippingId) {
                let token = JSON.parse(localStorage.getItem("token"));
                if (confirm("Remove Shipping Type from Provider?") == true) {
                    axios
                        .delete(this.$store.state.app.apiBasepath + "/provider/remove-type", {
                            headers: { Authorization: `Bearer ${token}` },
                            data: {
                                shippingTypeId: [shippingId],
                                providerId: [provider],
                            },
                        })
                        .then(() => {
                            this.refreshModal();
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            },
            showPreview() {
                let pic = document.getElementById("pict");
                if (pic.files.length > 0) {
                    var src = URL.createObjectURL(pic.files[0]);
                    var preview = document.getElementById("pictureImg");
                    preview.src = src;
                }
            },
            togglePass() {
                $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwd");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            togglePassConfirm() {
                $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
                var input = $("#pwdconf");
                if (input.attr("type") == "password") {
                    input.attr("type", "text");
                } else {
                    input.attr("type", "password");
                }
            },
            nextStage() {
                this.stages[this.stageIndex].status = 2;
                this.stages[this.stageIndex + 1].status = 1;
                $("#stages").scrollLeft("2000px");
                this.stageIndex++;
            },
            prevStage() {
                this.stages[this.stageIndex].status = 0;
                this.stages[this.stageIndex - 1].status = 1;
                $("#stages").scrollLeft("200px");
                this.stageIndex--;
            },
            photoInput() {
                $("#photoInput").click();
            },
            randomizeName() {
                return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021").toString().substring(0, 16);
            },
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        if (!this.$route.query.wv) this.$router.push("/signin");
                    });
            },
            encode64() {
                async function listarchivos(fileInput) {
                    var base64; //in this variable i need the base64
                    var fileToLoad = fileInput;
                    base64 = await getBase64(fileToLoad).then((data) => {
                        return data;
                    });
                    console.log(base64);
                    return base64;
                }

                //This is my function for get base64, but not return the string base64
                function getBase64(file) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                        return Promise.resolve(reader.result);
                    });
                }

                let result = listarchivos(this.photoFile);
                return result;
            },
            register() {
                let token = JSON.parse(localStorage.getItem("token"));
                axios
                    .post(this.$store.state.app.apiBasepath + "/provider/add", this.bodyForm, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        axios.post(this.$store.state.app.apiBasepath + "/provider/assign-type", { shippingTypeId: this.selectedShippings, providerId: [res.data.newProvider.id] }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
                            this.$alertSys("New Provider Added!", "success");
                            this.$store.state.app.openModal = "";
                        });
                    })
                    .catch(() => {
                        this.$alertSys("An error occured", "err");
                    });
            },
            ascSort() {
                function compare(a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                }

                this.page = 1;
                this.sortType = "ASC";
                this.allShipping.sort(compare);
            },
            descSort() {
                function compare(a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
                }

                this.page = 1;
                this.sortType = "DESC";
                this.allShipping.sort(compare);
            },
            filterShipping(query = this.modeFilter) {
                if (!query) {
                    this.filterState = "";

                    this.getAllAdmins();
                } else {
                    this.filterState = query;
                    this.getAllAdmins("?filter=" + query);
                }
            },
            searchShipping(query = "", filter = this.modeFilter) {
                this.filterState = filter;
                if (!query) {
                    this.getAllAdmins("?filter=" + filter);
                } else {
                    this.getAllAdmins("?filter=" + filter + "&search=" + query);
                }
            },
            getAllAdmins(param = "") {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/shipping-type/all" + param, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        console.log("200: " + res);
                        this.allShipping = res.data.rows;
                        this.ascSort();
                    })
                    .catch(() => {
                        console.log("500: " + token);
                    });
            },
            getDetails() {
                let token = JSON.parse(localStorage.getItem("token"));

                axios
                    .get(this.$store.state.app.apiBasepath + "/gasoline/details/" + this.$store.state.app.modalData.id, { headers: { Authorization: `Bearer ${token}` } })
                    .then((res) => {
                        this.bodyForm.type = res.data.type;
                        this.bodyForm.price = res.data.price;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            removeSelected(val) {
                const index = this.selectedShippings.indexOf(val);
                if (index > -1) {
                    // only splice array when item is found
                    this.selectedShippings.splice(index, 1); // 2nd parameter means remove one item only
                }
            },
        },
        mounted() {
            // alert("a");
            // this.getAllAdmins();
            this.getDetails();
        },
    };
</script>

<style scoped>
    .btn.btn-usfc-edit {
        min-width: 120px;
        color: #3699ff;
        background-color: white;
        border-radius: 6px;
        padding: 10px 25px;
        border: 1px solid #3699ff;
    }

    .btn-usfc-edit:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }
    tbody > tr:hover {
        outline: 1px solid #3699ff;
    }
    input.searchbar {
        width: 95%;
        border-radius: 6px;
        background-color: #fbfbfb;
        border: solid 1px rgba(54, 153, 255, 0);
    }

    input.searchbar:focus {
        outline: none !important;
        border: solid 1px rgba(54, 153, 255, 0.5);
    }

    .trans-option-item {
        opacity: 1;
        cursor: pointer;
    }

    .trans-option-item.active {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .trans-option-item:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    #forms::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    #forms.profile {
        max-height: 45vh;
    }

    /* #forms {
        overflow-y: scroll;
        height: 55vh;
    } */

    #closeModal {
        float: right;
        transform: rotate(45deg);
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid transparent;
    }

    #closeModal:hover {
        border: 1px solid black;
    }

    #navigation {
        position: relative;
        bottom: 0;
        right: 0;
    }

    #stages > .col-auto {
        opacity: 0.5;
    }

    #stages > .col-auto.active {
        opacity: 1;
    }

    #pictureImg {
        cursor: pointer;
        width: 110px;
        height: 110px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    .remove-btn {
        width: 50px;
    }
    .remove-btn:hover {
        text-decoration: underline #f25b5b;
    }

    #pictureImgBtn {
        cursor: pointer;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: inline-block;
        background-color: #3699ff;

        position: absolute;
        top: 180px;
        left: 53%;
    }

    #pictureImgBtn > #pictureBtn {
        transform: scale(1.2);
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
    }

    #profilePicture {
        height: 20%;
    }

    #stages {
        overflow-x: scroll;
    }
    #stages::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .btn-back {
        color: #3699ff;
        outline: 1px solid #3699ff;
    }

    .btn-back:hover {
        color: #3699ff;
        outline: 1px solid #3699ff;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        display: block;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* INPUT FORM LABEL STYLE */
    .label-float {
        position: relative;
        padding-top: 13px;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
    }

    select {
        width: 100%;
        border: 1px solid transparent;
    }

    .btn {
        font-size: small;
        min-width: 90px;

        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel {
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn.btn-cancel:hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .btn.btn-cancel:active {
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .btn.btn-usfc-primary {
        background-color: #3699ff;
        color: white;
        border-radius: 6px;
        padding: 10px 25px;
    }

    .btn-usfc-primary:hover {
        background-color: #3699ff;
        color: white;
        box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .label-float input {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float select {
        border: none;
        background-color: whitesmoke;
        border-radius: 5px;
        outline: none;
        min-width: 250px;
        padding: 15px 20px;
        font-size: 16px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -webkit-appearance: none;
        top: 10px;
    }

    .label-float input:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float select:focus {
        border: 2px solid #3699ff;
        background-color: transparent;
    }

    .label-float input::placeholder {
        color: black;
    }

    .label-float select::placeholder {
        color: black;
    }

    .label-float label {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.5);
    }

    .label-float input:required:invalid + label {
        color: red;
    }
    .label-float input:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float input:required:invalid + label:before {
        content: "*";
    }
    .label-float input:focus + label,
    .label-float input:not(:placeholder-shown) + label {
        font-size: 13px;
        top: 10px;
        color: #3951b2;
        opacity: 1;
        background-color: transparent;
    }

    .label-float select:required:invalid + label {
        color: red;
    }
    .label-float select:focus:required:invalid {
        border: 2px solid red;
    }
    .label-float select:required:invalid + label:before {
        content: "*";
    }

    label.option-selected {
        font-size: 13px;
        transform: translateY(calc(-55%));
        color: #3951b2;
        background-color: transparent;
    }

    /* FA ICONS CUSTOM */
    .fa-check {
        margin: 0 5px;
        position: relative;
        top: -2px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        padding: 3px;
        background-color: #3699ff;
    }

    /* * {
    border: 1px solid red;
  } */
</style>

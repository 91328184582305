<template>
  <div class="px-3 py-0 my-0 prevent-select" style="min-height: 400px">
    <table class="table">
      <thead>
        <tr>
          <!-- <th
            @click="
              this.$store.state.app.managerForm.idSelects = [];
              selectAll();
            "
            v-if="this.$store.state.app.managerForm.idSelects.length < 6"
            scope="col"
            class="col-1"
            style="width: 3vw">
            <img src="/icons/custom/picker-deselect.svg" />
          </th> -->
          <!-- <th @click="this.$store.state.app.managerForm.idSelects = []" v-else scope="col" class="col-1" style="width: 3vw"><img src="/icons/custom/picker.svg" /></th> -->
          <th scope="col" class="col-1" style="width: 4vw"></th>
          <th scope="col" class="col-11">
            <span @click="sort()" style="cursor: pointer">
              Name
              <iconGroup class="blue-filter">
                <img
                  v-if="getSortType == 'ASC'"
                  src="/icons/all/twotone/arrow-up.svg"
                  class="scaled"
                />
                <img
                  v-else
                  src="/icons/all/twotone/arrow-down-1.svg"
                  class="scaled"
                />
              </iconGroup>
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="this.$store.state.app.managerForm.search == ''">
        <tr
          @click="openProfile(admin.id, admin.access_level)"
          v-for="admin in filteredAdmins[page - 1]"
          :key="admin.id"
          :class="
            this.$store.state.app.managerForm.idSelects.includes(admin.id)
              ? 'idSelected'
              : 'point'
          "
        >
          <!-- <th v-if="this.$store.state.app.managerForm.idSelects.includes(admin.id)"  scope="row">
            <img @click="removeSelected(admin.id)" src="/icons/custom/picker.svg" />
          </th>
          <th v-else scope="row">
            <img @click="this.$store.state.app.managerForm.idSelects.push(admin.id)" src="/icons/custom/picker-deselect.svg" />
          </th> -->
          <td v-if="this.$store.state.app.managerForm.idSelects.length < 1">
            <span
              @click="removeSelected(admin.id)"
              class="m-auto h-100"
              style="float: left; text-align: left; width: 60%"
            >
              <img
                v-if="admin.photo == null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  admin.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
            </span>
          </td>
          <td v-else>
            <span
              @click="
                this.$store.state.app.managerForm.idSelects.push(admin.id)
              "
              class="m-auto h-100"
              style="cursor: pointer; float: left; text-align: left; width: 60%"
            >
              <img
                v-if="admin.photo == null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  admin.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
            </span>
          </td>
          <td
            @click="removeSelected(admin.id)"
            v-if="this.$store.state.app.managerForm.idSelects.length < 1"
          >
            {{ admin.full_name }}
          </td>
          <td
            style="cursor: pointer"
            @click="this.$store.state.app.managerForm.idSelects.push(admin.id)"
            v-else
          >
            {{ admin.full_name }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          @click="openProfile(admin.id, admin.access_level)"
          v-for="admin in filteredAdmins"
          :key="admin.id"
          :class="
            this.$store.state.app.managerForm.idSelects.includes(admin.id)
              ? 'idSelected'
              : 'point'
          "
        >
          <!-- <th v-if="this.$store.state.app.managerForm.idSelects.includes(admin.id)"  scope="row">
            <img @click="removeSelected(admin.id)" src="/icons/custom/picker.svg" />
          </th>
          <th v-else scope="row">
            <img @click="this.$store.state.app.managerForm.idSelects.push(admin.id)" src="/icons/custom/picker-deselect.svg" />
          </th> -->
          <td v-if="this.$store.state.app.managerForm.idSelects.length < 1">
            <span
              @click="removeSelected(admin.id)"
              class="m-auto h-100"
              style="float: left; text-align: left; width: 60%"
            >
              <img
                v-if="admin.photo == null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  admin.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
            </span>
          </td>
          <td v-else>
            <span
              @click="
                this.$store.state.app.managerForm.idSelects.push(admin.id)
              "
              class="m-auto h-100"
              style="cursor: pointer; float: left; text-align: left; width: 60%"
            >
              <img
                v-if="admin.photo == null"
                src="/icons/default.svg"
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
              <img
                v-else
                :src="
                  'https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/' +
                  admin.photo
                "
                style="
                  object-fit: cover;
                  height: 42px;
                  border-radius: 50%;
                  width: 42px;
                "
              />
            </span>
          </td>
          <td
            @click="removeSelected(admin.id)"
            v-if="this.$store.state.app.managerForm.idSelects.length < 1"
          >
            {{ admin.full_name }}
          </td>
          <td
            style="cursor: pointer"
            @click="this.$store.state.app.managerForm.idSelects.push(admin.id)"
            v-else
          >
            {{ admin.full_name }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav
    v-if="this.$store.state.app.managerForm.search == ''"
    aria-label="Page navigation example"
  >
    <ul class="pagination justify-content-end">
      <paginate
        v-model="page"
        style="list-style-type: none"
        class="d-flex"
        :page-count="totalPages"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'className'"
      >
      </paginate>
    </ul>
  </nav>
</template>

<script>
/* eslint-disable */

import axios from "axios";

import Paginate from "vuejs-paginate-next";

export default {
  name: "ManagerComponent",
  props: {},
  components: {
    Paginate,
  },
  data() {
    return {
      sortType: "ASC",
      page: 1,
      idSelects: [],
      admins: [],
      allAdmins: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
    };
  },
  computed: {
    filteredAdmins() {
      if (this.$store.state.app.managerForm.search == "") {
        return this.$store.state.app.managerForm.filteredAdmins;
      }

      return this.$store.state.app.managerForm.allAdmins.filter((admin) => {
        return admin.full_name
          .toLowerCase()
          .includes(this.$store.state.app.managerForm.search.toLowerCase());
      });
    },

    getLoadForm() {
      return this.loadForm;
    },
    totalPages() {
      return Math.ceil(this.$store.state.app.managerForm.allAdmins.length / 10);
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
    getSortType() {
      return this.sortType;
    },
  },
  methods: {
    openProfile(id, access_level) {
      this.$store.state.app.managerForm.userDetail = access_level;
      this.$router.push("./users/" + id);
    },

    sort() {
      if (this.getSortType == "DESC") {
        this.ascSort();
      } else {
        this.descSort();
      }

      this.$store.state.app.managerForm.idSelects = [];
    },
    ascSort() {
      function compare(a, b) {
        if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
        if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allAdmins.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) return 1;
        if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allAdmins.sort(compare);
      this.paginate();
    },
    getAllAdmins() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/user/all-admin", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.$store.state.app.managerForm.allAdmins = res.data.rows;
          this.ascSort();
        })
        .catch((err) => {
          console.log("500: " + token);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredAdmins[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allAdmins.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
    ascendingSort() {
      this.allAdmins.sort((a, b) => a - b);
    },
    selectAll() {
      Object.values(this.admins).forEach((val) => {
        this.$store.state.app.managerForm.idSelects.push(val.id);
      });
    },
    removeSelected(val) {
      const index = this.$store.state.app.managerForm.idSelects.indexOf(val);
      if (index > -1) {
        // only splice array when item is found
        this.$store.state.app.managerForm.idSelects.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
  },
  mounted() {
    this.getAllAdmins();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td {
  max-height: 25px;
}

.pagination {
  cursor: pointer;
}
.pagination:hover {
  filter: none;
  background-color: transparent;
}

.point {
  cursor: pointer;
}

.point:hover {
  outline: 1px solid #3699ff;
}

.idSelected {
  outline: 1px solid #3699ff;
}

.page-item:hover {
  filter: none;
}
.usfc-bg {
  background-color: #fbfbfb;
}

.white-filter {
  filter: invert(100%) brightness(1) contrast(200%);
}
.scaled {
  transform: scale(0.8);
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

li.page-item {
  color: black;
}

th > img {
  cursor: pointer;
}

/* pagination stylers */
.pagination > li {
  outline: none;
  border: none;
  filter: none;
}

.page-item.active .page-link {
  color: black;
  font-weight: bold;
}

li {
  text-align: center;
  width: 35px;
  margin: 0 5px;
  transition: all 0.2s ease;
}

li:hover {
  filter: none;
  translate: 0 -10px;
  cursor: pointer;
}

.page-item .page-link {
  color: darkgrey;
}

.page-item.disabled .page-link {
  opacity: 0.5;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  background-color: #3699ff;
  color: white;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: #3699ff;
}

.page-link {
  border-radius: 10px;
}

tbody tr {
  line-height: 290%;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
</style>

<template>
    <div class="container-fluid vh-100 usfc-bg">
        <div class="row h-100">
            <div class="col-md-4 col-sm-12 bg-white">
                <SigninForm />
                <ForgotForm />
                <EmailReset />
            </div>
            <div class="d-none d-md-flex col-md-8 illustration justify-content-center">
                <img src="/illustration.svg" style="width: 60%" />
            </div>
        </div>
    </div>
</template>

<script>
    import SigninForm from "./forms/Signin.vue";
    import ForgotForm from "./forms/ForgotPass.vue";
    import EmailReset from "./forms/EmailReset.vue";

    /* eslint-disable */

    export default {
        name: "LoginPage",
        props: {},
        components: {
            SigninForm,
            ForgotForm,
            EmailReset,
        },
        data() {
            return {
                loadForm: "signin",
            };
        },
        computed: {
            getLoadForm() {
                return this.loadForm;
            },
            illustration() {
                return require("@/assets/illustration.svg");
            },
        },
        methods: {
            forgotPass(val) {
                this.loadForm = "wait";
                setTimeout(() => {
                    this.loadForm = val;
                }, 250);
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    html {
        zoom: 1;
    }
    .usfc-bg {
        background: linear-gradient(237.99deg, #2dc9ff 0%, #0f80c1 76.43%);
    }

    .illustration {
        align-items: center;
        flex-wrap: wrap;
    }

    .vh-100 {
        height: 100vh;
    }
    h3 {
        margin: 40px 0 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }
</style>

<template>
  <div class="frame">
    <div class="nav">
      <div
        class="nav-item"
        :class="[currentTab === 'details' ? 'active' : '']"
        @click="currentTab = 'details'"
      >
        Receipt Details
      </div>
      <div
        class="nav-item"
        :class="[currentTab === 'tracking' ? 'active' : '']"
        @click="currentTab = 'tracking'"
      >
        Tracking
      </div>
      <div
        v
        v-if="isAdmin || isManager"
        class="nav-item"
        :class="[currentTab === 'gas' ? 'active' : '']"
        @click="currentTab = 'gas'"
      >
        Gas Stations
      </div>
      <div
        v
        v-if="isAdmin || isManager"
        class="nav-item"
        :class="[currentTab === 'case' ? 'active' : '']"
        @click="currentTab = 'case'"
      >
        Cases
      </div>
      <div
        class="nav-item"
        :class="[currentTab === 'summary' ? 'active' : '']"
        @click="currentTab = 'summary'"
      >
        Order Summary
      </div>
      <div
        v-if="(isAdmin || isManager) && getReceipt.delivery_status === 1"
        @click="this.$store.state.app.openModal = 'changeTransporter'"
        class="btn btn-usfc-outline"
        style="position: absolute; bottom: 25px"
      >
        Change Transporter
      </div>
      <div
        v-if="isClient && getReceipt.delivery_status === 3"
        @click="this.$store.state.app.openModal = 'receivePackage'"
        class="btn btn-usfc-outline"
        style="position: absolute; bottom: 25px"
      >
        Package Received
      </div>
    </div>
    <div v-if="currentTab === 'details'" class="detail">
      <div class="receipt">
        <div class="checkpoint mb-2">
          <div class="title my-2">
            <span class="dot mx-1"> </span> Origin Location
          </div>
          <div class="content lined">
            <div class="item">
              {{ receipt.detail?.booking_receipt?.pickup_address }}
            </div>
            <div class="item">
              <div
                @click="currentTab = 'tracking'"
                class="mx-1 mt-1 py-2 px-2 row update-location process"
              >
                <div class="col">
                  <div style="float: left" class="pulsating-circle mx-2"></div>

                  <span style="float: left; font-weight: bold; font-size: "
                    >See Current Location</span
                  >
                  <span style="float: right"
                    ><img
                      src="/icons/all/twotone/arrow-left-1.svg"
                      style="
                        transform: rotate(180deg) scale(1.4);
                        height: 12px;
                      "
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="checkpoint">
          <div class="title"><span class="dot mx-1"> </span> Destination</div>
          <div class="content">
            <div class="item">
              {{
                `${getRecipient.city},${getRecipient.province}. ${getRecipient.postal_code}`
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">Transporter Information</div>
        <div class="content">
          <div class="title">Name (Primary Transporter)</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ receipt?.transporter?.full_name }}
            <span style="opacity: 0.7">
              ({{ receipt?.transporter?.phone }})</span
            >
          </div>
        </div>
        <div v-if="receipt.secondary_transporter" class="content">
          <div class="title">Name (Secondary Transporter)</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ receipt?.secondary_transporter?.full_name }}
            <span style="opacity: 0.7">
              ({{ receipt?.secondary_transporter?.phone }})</span
            >
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">Shipping Information</div>
        <div class="content">
          <div class="title">Delivery Route</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getRate?.origin?.city }} &rarr; {{ getRate?.destination?.city }}
          </div>
        </div>
        <div class="content">
          <div class="title">Provider</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getRate?.provider?.name }}
          </div>
        </div>
        <div class="content">
          <div class="title">Service</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getRate?.service?.name }}
          </div>
        </div>
        <div class="content">
          <div class="title">Transportation Mode</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            <span class="blue-filter" style="transform: scale(0.75)">
              <img
                v-if="admin?.transportation_mode?.toLowerCase() == 'air'"
                src="/icons/all/twotone/airplane.svg"
              />
              <img
                v-else-if="admin?.transportation_mode?.toLowerCase() == 'water'"
                src="/icons/all/twotone/ship.svg"
              />
              <img v-else src="/icons/all/twotone/truck.svg" />
            </span>
            <span>{{ getRate?.shipping_type?.name }}</span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">Pickup Information</div>
        <div class="content">
          <div class="title">Address Details</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getBookingReceipt?.pickup_address }}
          </div>
        </div>
        <div class="content">
          <div class="title">PIC Name</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getBookingReceipt?.pic_name }}
          </div>
        </div>
        <div class="content">
          <div class="title">PIC Phone Number</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ getBookingReceipt?.pic_phone }}
          </div>
        </div>
        <div class="content">
          <div class="row">
            <div class="col">
              <div class="title">Pickup Date</div>
              <div
                class="content"
                style="display: flex; flex-direction: row; gap: 6px"
              >
                {{ getTime(getBookingReceipt?.pickup_date, "date") }}
              </div>
            </div>
            <div class="col">
              <div class="title">Pickup Time</div>
              <div
                class="content"
                style="display: flex; flex-direction: row; gap: 6px"
              >
                {{ getTime(getBookingReceipt?.pickup_date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">Bagging Information</div>
        <div
          v-for="(bag, index) in receipt.baggings"
          :key="bag.id"
          class="content"
          :class="index != 0 ? 'mt-5' : ''"
        >
          <div class="title__numbered" style="font-weight: 600">
            [ Bagging #{{ index + 1 }} ]
          </div>
          <div class="title">Good Details</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            {{ bag.good_details }}
          </div>
          <div class="title">Dimension Measurements</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            Actual Dimension: (L){{ bag.long }} cm x (W){{ bag.width }} cm x
            (H){{ bag.height }} cm
          </div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            Volumetric:
            {{
              (
                bag.long *
                0.01 *
                (bag.width * 0.01) *
                (bag.height * 0.01)
              ).toFixed(2)
            }}
            m<sup>3</sup>
          </div>
          <div class="title">Weight Measurements</div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            Actual Weight: {{ bag.actual_weight }} kg
          </div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            Dimensional Weight: {{ bag.dimensional_weight }} kg
          </div>
          <div
            class="content"
            style="display: flex; flex-direction: row; gap: 6px"
          >
            Accounted Weight:
            {{
              bag.accounted_weight > bag.dimensional_weight
                ? bag.accounted_weight
                : bag.dimensional_weight
            }}
            kg
          </div>
        </div>
      </div>
      <div class="shipper">
        <div class="col">
          <div class="title">Shipper Information</div>
          <div class="content">
            <div class="title">Company</div>
            <div class="content">{{ getShipper.company }}</div>
          </div>
          <div class="content">
            <div class="title">PIC Name</div>
            <div class="content">{{ getShipper.full_name }}</div>
          </div>
          <div class="content">
            <div class="title">Phone Number</div>
            <div class="content">{{ getShipper.phone }}</div>
          </div>
          <div class="content">
            <div class="title">Province</div>
            <div class="content">{{ getShipper.province }}</div>
          </div>
          <div class="content">
            <div class="title">City</div>
            <div class="content">{{ getShipper.city }}</div>
          </div>
          <div class="content">
            <div class="title">District</div>
            <div class="content">{{ getShipper.district }}</div>
          </div>
          <div class="content">
            <div class="title">Postal Code</div>
            <div class="content">{{ getShipper.postal_code }}</div>
          </div>
          <div class="content">
            <div class="title">Address</div>
            <div class="content">{{ getShipper.street }}</div>
          </div>
        </div>
        <div class="col">
          <div class="title">Recipient Information</div>
          <div class="content">
            <div class="title">Company</div>
            <div class="content">{{ getRecipient.company }}</div>
          </div>
          <div class="content">
            <div class="title">PIC Name</div>
            <div class="content">{{ getRecipient.pic_name }}</div>
          </div>
          <div class="content">
            <div class="title">Phone Number</div>
            <div class="content">{{ getRecipient.phone }}</div>
          </div>
          <div class="content">
            <div class="title">Province</div>
            <div class="content">{{ getRecipient.province }}</div>
          </div>
          <div class="content">
            <div class="title">City</div>
            <div class="content">{{ getRecipient.city }}</div>
          </div>
          <div class="content">
            <div class="title">District</div>
            <div class="content">{{ getRecipient.district }}</div>
          </div>
          <div class="content">
            <div class="title">Postal Code</div>
            <div class="content">{{ getRecipient.postal_code }}</div>
          </div>
          <div class="content">
            <div class="title">Address</div>
            <div class="content">{{ getRecipient.street }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- LIVE -->
    <div
      v-if="
        currentTab === 'tracking' &&
        getBookingReceipt.rate.shipping_type.is_real_time
      "
      class="tracking"
    >
      <!-- @click="markerCallback($event.latLng)" -->
      <div class="waybill">
        <div class="button" @click="showWaybill = !showWaybill">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <g clip-path="url(#clip0_6132_53832)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 2.5C16.1377 2.49996 16.7513 2.7436 17.2152 3.18107C17.6792 3.61854 17.9584 4.21676 17.9958 4.85333L18 5V16.6667C18 16.8054 17.9653 16.9419 17.8991 17.0639C17.8329 17.1858 17.7374 17.2893 17.6211 17.3649C17.5048 17.4406 17.3715 17.486 17.2332 17.4971C17.0949 17.5082 16.956 17.4845 16.8292 17.4283L16.7292 17.3767L14.4583 15.9783L12.1867 17.3767C12.0703 17.4482 11.9382 17.49 11.8019 17.4984C11.6656 17.5068 11.5293 17.4816 11.405 17.425L11.3133 17.3767L9.04167 15.9783L6.77 17.3767C6.65094 17.4499 6.51536 17.4921 6.37574 17.4992C6.23612 17.5064 6.09694 17.4783 5.97103 17.4175C5.84512 17.3568 5.73652 17.2653 5.65522 17.1516C5.57393 17.0378 5.52255 16.9055 5.50583 16.7667L5.5 16.6667V11.6667H3.83333C3.62922 11.6666 3.43222 11.5917 3.27969 11.4561C3.12716 11.3204 3.02971 11.1335 3.00583 10.9308L3 10.8333V4.58333C2.99992 4.0544 3.20103 3.54524 3.56253 3.15913C3.92404 2.77302 4.41887 2.53886 4.94667 2.50417L5.08333 2.5H15.5ZM13 10H9.66667C9.44565 10 9.23369 10.0878 9.07741 10.2441C8.92113 10.4004 8.83333 10.6123 8.83333 10.8333C8.83333 11.0543 8.92113 11.2663 9.07741 11.4226C9.23369 11.5789 9.44565 11.6667 9.66667 11.6667H13C13.221 11.6667 13.433 11.5789 13.5893 11.4226C13.7455 11.2663 13.8333 11.0543 13.8333 10.8333C13.8333 10.6123 13.7455 10.4004 13.5893 10.2441C13.433 10.0878 13.221 10 13 10ZM5.08333 4.16667C4.97283 4.16667 4.86685 4.21057 4.78871 4.28871C4.71057 4.36685 4.66667 4.47283 4.66667 4.58333V10H5.5V4.58333C5.5 4.47283 5.4561 4.36685 5.37796 4.28871C5.29982 4.21057 5.19384 4.16667 5.08333 4.16667ZM13.8333 6.66667H9.66667C9.45427 6.6669 9.24997 6.74823 9.09553 6.89404C8.94108 7.03985 8.84814 7.23913 8.83569 7.45116C8.82324 7.6632 8.89223 7.87198 9.02855 8.03486C9.16488 8.19774 9.35825 8.30241 9.56917 8.3275L9.66667 8.33333H13.8333C14.0457 8.3331 14.25 8.25177 14.4045 8.10596C14.5589 7.96015 14.6519 7.76087 14.6643 7.54884C14.6768 7.3368 14.6078 7.12802 14.4714 6.96514C14.3351 6.80226 14.1417 6.69759 13.9308 6.6725L13.8333 6.66667Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_6132_53832">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>Show Waybill</div>
        </div>
        <div v-if="showWaybill" class="items">
          <div
            class="item"
            @click.stop="openInNewTab(getBookingReceipt.waybill_document)"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <path
                  d="M13.0625 15.9375C13.7183 15.9375 14.25 15.4058 14.25 14.75C14.25 14.0942 13.7183 13.5625 13.0625 13.5625C12.4067 13.5625 11.875 14.0942 11.875 14.75C11.875 15.4058 12.4067 15.9375 13.0625 15.9375Z"
                  fill="black"
                />
                <path
                  d="M17.6801 14.4407C17.3132 13.506 16.6802 12.6996 15.8593 12.1213C15.0385 11.5431 14.0661 11.2184 13.0625 11.1875C12.0589 11.2184 11.0865 11.5431 10.2657 12.1213C9.44484 12.6996 8.81177 13.506 8.44491 14.4407L8.3125 14.75L8.44491 15.0599C8.81186 15.9945 9.44497 16.8008 10.2658 17.3789C11.0866 17.9571 12.059 18.2817 13.0625 18.3125C14.066 18.2817 15.0384 17.9571 15.8592 17.3789C16.68 16.8008 17.3131 15.9945 17.6801 15.0599L17.8125 14.75L17.6801 14.4407ZM13.0625 17.125C12.5928 17.125 12.1336 16.9857 11.743 16.7247C11.3525 16.4638 11.048 16.0928 10.8683 15.6589C10.6885 15.2249 10.6415 14.7474 10.7331 14.2867C10.8248 13.826 11.051 13.4028 11.3831 13.0706C11.7153 12.7385 12.1385 12.5123 12.5992 12.4206C13.0599 12.329 13.5374 12.376 13.9714 12.5558C14.4053 12.7355 14.7763 13.04 15.0372 13.4305C15.2982 13.8211 15.4375 14.2803 15.4375 14.75C15.4367 15.3796 15.1862 15.9833 14.741 16.4285C14.2958 16.8737 13.6921 17.1242 13.0625 17.125ZM4.15625 10.5938H7.125V11.7812H4.15625V10.5938ZM4.15625 7.625H11.2812V8.8125H4.15625V7.625ZM4.15625 4.65625H11.2812V5.84375H4.15625V4.65625Z"
                  fill="black"
                />
                <path
                  d="M13.0625 1.6875H2.375C2.06034 1.68844 1.75884 1.81385 1.53635 2.03635C1.31385 2.25884 1.18844 2.56034 1.1875 2.875V17.125C1.18844 17.4397 1.31385 17.7412 1.53635 17.9637C1.75884 18.1861 2.06034 18.3116 2.375 18.3125H7.125V17.125H2.375V2.875H13.0625V9.40625H14.25V2.875C14.2491 2.56034 14.1236 2.25884 13.9012 2.03635C13.6787 1.81385 13.3772 1.68844 13.0625 1.6875Z"
                  fill="black"
                />
              </svg>
            </div>

            <div>View Waybill</div>
          </div>
          <div
            class="item"
            @click.stop="downloadFile(getBookingReceipt.waybill_document)"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
              >
                <path
                  d="M3.95834 16.3333H15.0417V14.75H3.95834V16.3333ZM15.0417 7.625H11.875V2.875H7.12501V7.625H3.95834L9.50001 13.1667L15.0417 7.625Z"
                  fill="black"
                />
              </svg>
            </div>

            <div>
              {{
                getBookingReceipt.waybill_document ? "Download" : "Not Found"
              }}
            </div>
          </div>
        </div>
      </div>
      <GoogleMap
        class="map"
        api-key="AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM"
        :center="gmaps.center"
        :controlSize="24"
        :fullscreen-control="false"
        :clickableIcons="false"
        :map-type-control="false"
        :street-view-control="false"
        :zoom-control="false"
        :styles="gmaps.styles"
        :zoom="gmaps.zoom"
      >
        <Marker
          class="marker"
          v-if="this.gmaps.marker.lat != null"
          :options="{
            position: gmaps.marker,
            draggable: false,
            icon: getMarkerIcon,
          }"
          @dragend="markerCallback($event.latLng)"
        />
        <Marker
          class="marker"
          v-if="this.gmaps.historyMarker.lat != null"
          :options="{
            position: gmaps.historyMarker,
            draggable: false,
            icon: '/icons/location_picker.svg',
            label: 'Location History',
            title: 'Location History',
          }"
          @dragend="markerCallback($event.latLng)"
        />
        <Marker
          class="marker"
          v-if="this.gmaps.originMarker.lat != null"
          :options="{
            position: gmaps.originMarker,
            draggable: false,
            icon: '/icons/custom/map_origin.svg',
          }"
          @dragend="markerCallback($event.latLng)"
        />
        <Marker
          class="marker"
          v-if="this.gmaps.destinationMarker.lat != null"
          :options="{
            position: gmaps.destinationMarker,
            draggable: false,
            icon: '/icons/custom/map_destination.svg',
          }"
          @dragend="markerCallback($event.latLng)"
        />
      </GoogleMap>
      <div
        v-if="(isAdmin || isManager) && getReceipt.delivery_status < 3"
        @click="this.$store.state.app.openModal = 'updateTrip'"
        class="btn btn-usfc-outline"
        style="position: absolute; bottom: 25px; right: 50px; z-index: 99"
      >
        Update Trip
      </div>
    </div>

    <!-- STATIC -->
    <div
      v-if="currentTab === 'tracking'"
      class="trip-history"
      :class="[
        tripShow ? 'show' : '',
        getBookingReceipt.rate.shipping_type.is_real_time
          ? 'floating'
          : 'static',
      ]"
    >
      <div
        v-if="getBookingReceipt.rate.shipping_type.is_real_time"
        @click="tripShow = !tripShow"
        class="handle"
      >
        {{ tripShow ? "Hide Trip History" : "Show Trip History" }}
        <span
          ><img
            class="toggler blue-filter"
            :class="[tripShow ? 'show' : '']"
            src="/icons/all/twotone/arrow-up-1.svg"
        /></span>
      </div>
      <div class="trip">
        <div class="latest">
          <div class="status mb-2">
            <div class="title text-blue">{{ getCurrentDeliveryStatus }}</div>
            <div
              class="update-status"
              :class="`receipt__${getReceipt.delivery_status}`"
            ></div>
          </div>
          <div class="position">
            <div>
              <img
                class="me-4"
                :class="[tripShow ? 'show' : '']"
                src="/icons/custom/location_sm.svg"
              />
            </div>
            <div class="place">
              <div>{{ getLatestStatus ?? "Latest address unavailable" }}</div>
              <div
                v-if="
                  getBookingReceipt.rate.shipping_type.is_real_time &&
                  getReceipt.delivery_status > 1
                "
                @click="viewLocationHistory()"
                class="text-blue"
                style="cursor: pointer"
              >
                {{
                  getLatestAddress ? "View Location" : "Location Unavailable"
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            delayedTripShow ||
            !getBookingReceipt.rate.shipping_type.is_real_time
          "
          class="trip-show"
        >
          <div class="status">
            <div class="title">Trip History</div>
          </div>

          <!-- Delivered -->
          <div v-if="getReceipt.delivery_status > 2" class="history">
            <div class="detail">
              <div class="time">
                <div class="dot"></div>
                <div>{{ formatDate(getReceipt.delivered_time) }}</div>
              </div>
              <div class="content">
                <div class="address">
                  {{
                    getReceipt.delivered_address_address ??
                    "Update address unavailable"
                  }}
                </div>
                <div class="note">
                  <div class="title">Note</div>
                  <div class="content">
                    {{ getReceipt.delivered_note ?? "-" }}
                  </div>
                </div>
                <div
                  v-if="
                    !getReceipt.delivered_photo &&
                    !getReceipt.delivered_document
                  "
                  class="note"
                >
                  <div class="title">Updated By</div>
                  <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                </div>
              </div>
            </div>
            <div
              @click="
                openPhotoViewer(
                  3,
                  getReceipt.delivered_photo,
                  getReceipt.delivered_document
                )
              "
              class="photo"
            >
              <img
                :src="
                  'https://s3-usfc-arrived-image.s3.ap-southeast-1.amazonaws.com/' +
                  getReceipt.delivered_photo
                "
                onerror="this.src='/placeholders/unavailable-image.jpg'"
                alt="Fallback Image"
              />
            </div>
          </div>

          <!-- Update -->
          <div
            v-for="item in getReceipt.receipt_updates.slice().reverse()"
            :key="item.id"
            class="history"
          >
            <div class="detail">
              <div class="time">
                <div class="dot"></div>
                <div>{{ formatDate(item.update_time) }}</div>
              </div>
              <div class="content">
                <div class="address">
                  {{ item.update_address ?? "Update address unavailable" }}
                </div>
                <div class="note">
                  <div class="title">Note</div>
                  <div class="content">{{ item.update_note ?? "-" }}</div>
                </div>
                <div v-if="item.updated_by" class="note">
                  <div class="title">Updated By</div>
                  <div class="content">{{ item.updated_by ?? "-" }}</div>
                </div>
              </div>
            </div>
            <div
              @click="
                openPhotoViewer(2, item.update_photo, item.update_document)
              "
              class="photo"
            >
              <img
                :src="
                  'https://s3-usfc-delivery-status-image.s3.ap-southeast-1.amazonaws.com/' +
                  item.update_photo
                "
                onerror="this.src='/placeholders/unavailable-image.jpg'"
                alt="Fallback Image"
              />
            </div>
          </div>

          <div
            v-if="getReceipt.delivery_status > 1 && getReceipt.update_time"
            class="history"
          >
            <div class="detail">
              <div class="time">
                <div class="dot"></div>
                <div>{{ formatDate(getReceipt.update_time) }}</div>
              </div>
              <div class="content">
                <div class="address">
                  {{
                    getReceipt.update_address ?? "Update address unavailable"
                  }}
                </div>
                <div class="note">
                  <div class="title">Note</div>
                  <div class="content">{{ getReceipt.update_note ?? "-" }}</div>
                </div>
                <div
                  v-if="!getReceipt.update_photo && !getReceipt.update_document"
                  class="note"
                >
                  <div class="title">Updated By</div>
                  <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                </div>
              </div>
            </div>
            <div
              @click="
                openPhotoViewer(
                  2,
                  getReceipt.update_photo,
                  getReceipt.update_document
                )
              "
              class="photo"
            >
              <img
                :src="
                  'https://s3-usfc-delivery-status-image.s3.ap-southeast-1.amazonaws.com/' +
                  getReceipt.update_photo
                "
                onerror="this.src='/placeholders/unavailable-image.jpg'"
                alt="Fallback Image"
              />
            </div>
          </div>

          <!-- Pickup -->
          <div v-if="getReceipt.delivery_status > 1" class="history">
            <div class="detail">
              <div class="time">
                <div class="dot"></div>
                <div>{{ formatDate(getReceipt.actual_pickup_time) }}</div>
              </div>
              <div class="content">
                <div class="address">
                  {{
                    getReceipt.pickup_address ?? "Pickup address unavailable"
                  }}
                </div>
                <div class="note">
                  <div class="title">Note</div>
                  <div class="content">{{ getReceipt.pickup_note ?? "-" }}</div>
                </div>
                <div
                  v-if="!getReceipt.pickup_photo && !getReceipt.pickup_document"
                  class="note"
                >
                  <div class="title">Updated By</div>
                  <div class="content">{{ getReceipt.updated_by ?? "-" }}</div>
                </div>
              </div>
            </div>
            <div
              @click="
                openPhotoViewer(
                  1,
                  getReceipt.pickup_photo,
                  getReceipt.pickup_document
                )
              "
              class="photo"
            >
              <img
                :src="
                  'https://s3-usfc-pickup-image.s3.ap-southeast-1.amazonaws.com/' +
                  getReceipt.pickup_photo
                "
                onerror="this.src='/placeholders/unavailable-image.jpg'"
                alt="Fallback Image"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          (isAdmin || isManager) &&
          !getBookingReceipt.rate.shipping_type.is_real_time
        "
        @click="this.$store.state.app.openModal = 'updateTrip'"
        class="btn btn-usfc-outline"
        style="position: absolute; bottom: 25px; right: 50px"
      >
        Update Trip
      </div>
    </div>

    <div v-if="currentTab === 'gas'" class="gas-locations">
      <div v-if="gasLocation && gasLocations.length > 0">
        <div
          class="location"
          v-for="(gasLocation, index) in gasLocations"
          :key="gasLocation.id"
        >
          <div class="header">
            <div class="title">{{ index + 1 }}. {{ gasLocation.title }}</div>
            <div class="address">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.80985 4.245C2.79485 -0.0849988 9.20986 -0.0799987 10.1899 4.25C10.7649 6.79 9.18486 8.94 7.79986 10.27C6.79486 11.24 5.20486 11.24 4.19485 10.27C2.81485 8.94 1.23485 6.785 1.80985 4.245Z"
                  fill="#3699FF"
                  stroke="#76B9FF"
                />
                <path
                  d="M6.00043 6.71521C6.86199 6.71521 7.56043 6.01678 7.56043 5.15521C7.56043 4.29365 6.86199 3.59521 6.00043 3.59521C5.13887 3.59521 4.44043 4.29365 4.44043 5.15521C4.44043 6.01678 5.13887 6.71521 6.00043 6.71521Z"
                  fill="white"
                />
              </svg>
              <span class="ms-2">{{ gasLocation?.formatted_address }} </span>
            </div>
          </div>
          <div class="label-float" style="width: 100%">
            <input
              type="text"
              placeholder=" "
              :value="`${gasLocation.kilometer.toLocaleString('id-ID')} KM`"
              autocomplete="off"
              disabled
              style="width: 100%"
            />
            <label style="width: 100%">KM (NUMBER)</label>
          </div>
          <div class="label-float" style="width: 100%">
            <input
              type="text"
              placeholder=" "
              :value="`${gasLocation.fuel_litre} LITRES`"
              autocomplete="off"
              disabled
              style="width: 100%"
            />
            <label style="width: 100%">FUEL PURCHASE (LITRES)</label>
          </div>
          <div class="label-float" style="width: 100%">
            <input
              type="text"
              placeholder=" "
              :value="`${gasLocation.fuel_price.toLocaleString('id-ID', {
                style: 'currency',
                currency: 'IDR',
              })}`"
              autocomplete="off"
              disabled
              style="width: 100%"
            />
            <label style="width: 100%">FUEL PRICE</label>
          </div>
          <div class="label-float" style="width: 100%">
            <input
              type="text"
              placeholder=" "
              :value="`${gasLocation.fuel_total_cost.toLocaleString('id-ID', {
                style: 'currency',
                currency: 'IDR',
              })}`"
              autocomplete="off"
              disabled
              style="width: 100%"
            />
            <label style="width: 100%">TOTAL COST</label>
          </div>
          <div class="label-float" style="width: 100%">
            <input
              type="text"
              placeholder=" "
              :value="`${gasLocation.notes || '-'}`"
              autocomplete="off"
              disabled
              style="width: 100%"
            />
            <label style="width: 100%">*Notes</label>
          </div>
          <div class="attachments">
            <div class="content text-center">
              <div>Odometer</div>
              <img
                @click="
                  openGasReceipt(
                    gasLocation.kilometer_photo,
                    `KM Odometer:  ${gasLocation.kilometer}`
                  )
                "
                :src="getPhotoUrl(gasLocation.kilometer_photo)"
              />
            </div>

            <div class="content text-center">
              <div>Gas Receipt</div>
              <img
                @click="
                  openGasReceipt(
                    gasLocation.photo,
                    `Gas Receipt:  ${gasLocation.title}`
                  )
                "
                :src="getPhotoUrl(gasLocation.photo)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center text-danger p-4"
        style="
          opacity: 0.5;
          border: 1px solid rgba(0, 0, 0, 0.35);
          border-radius: 6px;
        "
      >
        No Gas Station Allocated for This Booking
      </div>
    </div>

    <div v-if="currentTab === 'case'" class="cases">
      <template v-if="cases.length > 0">
        <div v-for="(caseItem, i) in cases" :key="`caseItem_${i}`">
          <div class="title">Case {{ i + 1 }}</div>
          <div class="case-item">
            <div class="header">
              <div class="title">{{ caseItem.title.toUpperCase() }}</div>
              <div class="description">{{ caseItem.description }}</div>
            </div>

            <div class="attachments">
              <div
                v-for="(item, index) in caseItem.case_photos"
                :key="`case_${index}`"
                class="content text-center"
              >
                <img
                  @click="
                    openGasReceipt(
                      item.filename,
                      `[CASE No.  ${caseItem.description}`
                    )
                  "
                  :src="getPhotoUrl(item.filename)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="text-center text-danger p-4"
        style="
          opacity: 0.5;
          border: 1px solid rgba(0, 0, 0, 0.35);
          border-radius: 6px;
        "
      >
        No Case on This Receipt
      </div>
    </div>

    <div v-if="currentTab === 'summary'" class="detail">
      <div class="container">
        <div class="order-summary">
          <div>
            <div
              style="
                padding: 12px 0;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
              "
            >
              <span style="font-size: 18px; font-weight: 600"
                >Order Summary</span
              >
            </div>
          </div>
          <div class="text-end">
            <div
              style="
                padding: 12px 0;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
              "
            >
              <span style="font-size: 18px; font-weight: 600">{{
                getBookingReceipt.booking_number
              }}</span>
            </div>
          </div>
        </div>
        <div id="forms" class="row m-1 mt-3" style="overflow-y: auto">
          <div class="col-12 mb-3" style="border: 1px dashed black">
            <div
              class="row text-start py-2"
              style="background: rgba(0, 0, 0, 0.1)"
            >
              <div style="font-weight: 600; font-size: 16px">
                Estimated Cost
              </div>
            </div>
            <div class="p-2">
              <div class="row">
                <div class="col">
                  <div
                    class="row pb-2 mb-1"
                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
                  >
                    <div class="col text-start">Rate</div>
                    <div class="col text-start"></div>
                    <div class="col text-start">Weight (kg)</div>
                    <div class="col text-start"></div>
                    <div class="col text-end">Amount</div>
                  </div>
                  <div class="row">
                    <div class="col text-start">
                      Rp {{ getBookingReceipt?.rate?.rate.toLocaleString() }}
                    </div>
                    <div class="col text-center">x</div>
                    <div class="col text-start">
                      {{ getBookingReceipt?.total_accounted_weight }} (kg)
                    </div>
                    <div class="col text-center">=</div>
                    <div class="col text-end">
                      Rp {{ calculateEstimation.toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="row" style="font-weight: 600; font-size: 18px">
                <div class="col text-start"></div>
                <div class="col text-end">
                  Rp {{ calculateEstimation.toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3" style="border: 1px dashed black">
            <div
              class="row text-start py-2"
              style="background: rgba(0, 0, 0, 0.1)"
            >
              <div style="font-weight: 600; font-size: 16px">
                Additional Fee
              </div>
            </div>
            <div v-if="calculateAdditional">
              <div class="p-2">
                <div class="row" v-if="getBookingReceipt.insurance_fee">
                  <div class="col text-start">
                    Item Valuation: Rp
                    {{ getBookingReceipt.insurance_item_value }}
                  </div>
                  <div class="col text-end">
                    Rp
                    {{ getBookingReceipt.insurance_fee.toLocaleString() }}
                    (Insurance)
                  </div>
                </div>
                <div class="row" v-if="getBookingReceipt.repack_fee">
                  <div class="col text-start">
                    {{ getBookingReceipt.repack_value }}
                  </div>
                  <div class="col text-end">
                    Rp
                    {{ getBookingReceipt.repack_fee.toLocaleString() }} (Repack)
                  </div>
                </div>
                <div class="row" v-if="getBookingReceipt.other_fee">
                  <div class="col text-start">
                    {{ getBookingReceipt.other_value }}
                  </div>
                  <div class="col text-end">
                    Rp
                    {{ getBookingReceipt.other_fee.toLocaleString() }} (Other)
                  </div>
                </div>
              </div>
              <div class="p-2">
                <div class="row" style="font-weight: 600; font-size: 18px">
                  <div class="col text-start"></div>
                  <div class="col text-end">
                    Rp {{ calculateAdditional.toLocaleString() }}
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2" v-else>
              <div class="row m-2">
                <div class="row text-center">
                  <div class="col" style="font-weight: 600; font-size: 16px">
                    No additional fee added
                  </div>
                </div>
                <!-- <div class="row my-2 text-center">
                                <div class="col">Confirm all the details are correct before submitting!</div>
                            </div> -->
              </div>
            </div>
          </div>
          <div class="col-12" style="border: 1px dashed black">
            <div
              class="row text-start py-2"
              style="background: rgba(0, 0, 0, 0.1)"
            >
              <div style="font-weight: 600; font-size: 16px">Total Cost</div>
            </div>
            <div class="p-2">
              <div class="row">
                <div class="col text-start">Estimated Cost</div>
                <div class="col text-end">
                  Rp {{ calculateEstimation.toLocaleString() }}
                </div>
              </div>
              <div class="row">
                <div class="col text-start">Additional Fee</div>
                <div class="col text-end">
                  Rp {{ calculateAdditional.toLocaleString() }}
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col text-start">Subtotal</div>
                <div class="col text-end">
                  Rp {{ getBookingReceipt?.total_cost?.toLocaleString() }}
                </div>
              </div>
              <div class="row">
                <div class="col text-start">
                  <span v-if="isAdmin || isManager">
                    {{
                      getBookingReceipt.discount_fee &&
                      !getBookingReceipt.discount_note
                        ? "Discount"
                        : getBookingReceipt.discount_note
                    }}
                  </span>
                  <span>
                    {{
                      !getBookingReceipt.discount_fee
                        ? ""
                        : getBookingReceipt.discount_note
                        ? getBookingReceipt.discount_note
                        : "Discount"
                    }}
                  </span>
                </div>
                <ReceiptDiscount
                  v-if="getReceipt.delivery_status === 3"
                  :booking-receipt="getBookingReceipt"
                  class="col text-end"
                />
              </div>
              <div class="row">
                <div class="col text-start">{{ "PPN Logistic" }}</div>
                <div class="col text-end">
                  Rp {{ getBookingReceipt?.tax_fee?.toLocaleString() }}
                </div>
              </div>

              <br />
              <div class="row" style="font-weight: 600; font-size: 18px">
                <div class="col text-start">Payable Amount</div>
                <div class="col text-end" style="color: #3699ff">
                  Rp
                  {{
                    (
                      getBookingReceipt.total_cost + getBookingReceipt.tax_fee
                    ).toLocaleString()
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PhotoViewer
      :show="showPhoto"
      :images="imageArray"
      @onClose="closePhotoViewer"
    />
  </div>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import { Loader } from "@googlemaps/js-api-loader";
import { PDFDocument } from "pdf-lib";
import axios from "axios";
import moment from "moment";
import PhotoViewer from "@/components/PhotoViewer/PhotoViewer.vue";
import ReceiptDiscount from "../../modals/modalForms/receiptDiscount.vue";

export default {
  components: {
    GoogleMap,
    Marker,
    PhotoViewer,
    ReceiptDiscount,
  },
  data() {
    return {
      waybillSource: null,
      showWaybill: false,
      cases: [],
      imageArray: null,
      showPhoto: false,
      intervalId: null,
      receipt: {
        detail: {},
        transporter: {},
      },
      gasLocations: [],
      currentTab: "details",
      live: false,
      tripShow: false,
      delayedTripShow: false,
      gmaps: {
        styles: [
          {
            featureType: "all",
            stylers: [
              { saturation: 0 }, // Apply saturation effect to the map
            ],
          },
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              { visibility: "off" }, // Hide POI labels
            ],
          },
        ],
        zoom: 4,
        searchQuery: "",
        center: { lat: 0.4484759, lng: 113.944888 },
        options: {
          panControl: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
        },
        markerOptions: {
          icon: "/icons/location.svg",
        },
        suggestions: [],
        marker: {
          lat: null,
          lng: null,
        },
        historyMarker: {
          lat: null,
          lng: null,
        },
        originMarker: {
          lat: null,
          lng: null,
        },
        destinationMarker: {
          lat: null,
          lng: null,
        },

        selected: {
          area: null,
          formatted_address: "",
          coords: {
            lat: null,
            lng: null,
          },
        },
      },
    };
  },
  watch: {
    tripShow() {
      if (!this.tripShow) this.delayedTripShow = this.tripShow;
      else
        setTimeout(() => {
          this.delayedTripShow = this.tripShow;
        }, 500);
    },
    currentTab(newValue) {
      if (newValue === "tracking") {
        if (this.getBookingReceipt.rate.shipping_type.is_real_time) {
          if (this.receipt.detail.delivery_status < 3) {
            this.clearInterval();
            this.createInterval();
          }
        }
      } else this.clearInterval();
    },
  },
  computed: {
    calculateEstimation() {
      return this.getBookingReceipt.total_cost;
    },
    calculateAdditional() {
      return (
        this.getBookingReceipt.insurance_fee +
        this.getBookingReceipt.repack_fee +
        this.getBookingReceipt.other_fee
      );
    },
    getReceipt() {
      return this.receipt.detail;
    },
    getCurrentDeliveryStatus() {
      const deliveryStatus = this.getReceipt.delivery_status;
      let status = "";

      switch (deliveryStatus) {
        case 5:
          status = "Canceled";
          break;
        case 4:
          status = "Completed";
          break;
        case 3:
          status = "Delivered";
          break;
        case 2:
          status = "On Process";
          break;
        case 1:
          status = "Awaiting Pickup";
          break;
        default:
          status = "Bad Receipt";
          break;
      }

      return status;
    },
    getLatestStatus() {
      const deliveryStatus = this.getReceipt.delivery_status;
      let latest = "";

      switch (deliveryStatus) {
        case 5:
          latest = "This Receipt have been Canceled";
          break;
        case 4:
          latest = "This receipt have been Completed";
          break;
        case 3:
          latest = this.getReceipt.delivered_address;
          break;
        case 2:
          if (this.getReceipt.update_time)
            latest = this.getReceipt.update_address;
          else latest = this.getReceipt.pickup_address;
          break;
        case 1:
          latest =
            "Awaiting Pickup by Transporter " +
            this.receipt?.transporter?.full_name;
          break;
        default:
          latest = "Bad Receipt";
          break;
      }

      return latest;
    },
    getLatestAddress() {
      const deliveryStatus = this.getReceipt.delivery_status;
      let latest = "";

      switch (deliveryStatus) {
        case 3:
          latest = this.getReceipt.delivered_address;
          break;
        case 2:
          if (this.getReceipt.update_time)
            latest = this.getReceipt.update_address;
          else latest = this.getReceipt.pickup_address;
          break;

        default:
          latest = "Bad Receipt";
          break;
      }

      return latest;
    },
    getMarkerIcon() {
      return this.getBookingReceipt.rate.shipping_type.transportation_mode ===
        "Land"
        ? "/icons/custom/map_pickup.svg"
        : "/icons/custom/goods.svg";
    },
    getRecipient() {
      return this.receipt.detail?.booking_receipt?.recipient_company ?? "";
    },
    getShipper() {
      return this.receipt.detail?.booking_receipt?.user ?? "";
    },
    getRate() {
      return this.getBookingReceipt.rate;
    },
    getBookingReceipt() {
      return this.receipt.detail?.booking_receipt ?? "";
    },
    isClient() {
      if (this.$store.state.userData.access_level === 2) return true;
      return false;
    },
    isSales() {
      if (this.$store.state.userData.access_level === 3) return true;
      return false;
    },
    isAdmin() {
      if (this.$store.state.userData.access_level === 4) return true;
      return false;
    },
    isManager() {
      if (this.$store.state.userData.access_level === 5) return true;
      return false;
    },
  },
  methods: {
    async getPdfSource() {
      try {
        // `https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/`
        const pdfPath = `${this.getBookingReceipt.waybill_document}`;

        const response = await fetch(pdfPath);
        if (response.ok) {
          const existingPdfBytes = new Uint8Array(await response.arrayBuffer());
          this.waybillSource = existingPdfBytes;
          this.addSignature();
          console.log(this.waybillSource);
        } else console.error("Failed to fetch the PDF:", response.status);
      } catch (error) {
        console.error("Error fetching the PDF:", error);
      }
    },
    async urlToBase64(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve(reader.result.split(",")[1]);
          };
          reader.onerror = reject;
        });
      } catch (error) {
        console.error("Error converting URL to base64:", error);
        return null;
      }
    },
    async addSignature() {
      // this.$store.state.showLoader = true;
      try {
        const existingPdfBytes = this.waybillSource;

        // Load the existing PDF
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const recSignPath = `https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/${this.getBookingReceipt.recipient_signature}`;

        const driSignPath = `https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/${this.receipt.transporter.signature}`;
        const recSign = await this.urlToBase64(recSignPath);
        const driSign = await this.urlToBase64(driSignPath);
        // Embed the image into the PDF

        const driver = await pdfDoc.embedPng(driSign); // Change to embedJpg for JPEG images
        const recipient = await pdfDoc.embedPng(recSign); // Change to embedJpg for JPEG images

        // Add image to the first page
        // const { width, height } = page.getSize()
        const imageWidth = 120;
        const imageHeight = 50;

        const page = pdfDoc.getPages()[0];
        page.drawImage(recipient, {
          x: 475,
          y: 50,
          width: imageWidth,
          height: imageHeight + 20,
        });

        page.drawImage(driver, {
          x: 40,
          y: 50,
          width: imageWidth,
          height: imageHeight + 20,
        });

        // Save the modified PDF
        const modifiedPdfBytes = await pdfDoc.save();

        const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
          type: "application/pdf",
        });
        const modifiedPdfFile = new File(
          [modifiedPdfBlob],
          "modified_pdf.pdf",
          { type: "application/pdf" }
        );

        this.uploadFIle(modifiedPdfFile);

        console.log(modifiedPdfFile);
        // You can then handle the modified PDF bytes as needed
        return;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async uploadFIle(file) {
      let token = JSON.parse(localStorage.getItem("token"));
      await axios
        .get(
          "https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/usfc-upload-documents-get-presigned-url?fileName=" +
            `SIGNED_${this.getBookingReceipt.booking_number}_waybill.pdf`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/pdf",
            },
          }
        )
        .then((res) => {
          axios({
            method: "PUT",
            url: res.data.fileUploadURL,
            data: file,
            headers: {
              "Content-Type": "application/pdf",
            },
          }).then(async () => {
            axios
              .patch(
                this.$store.state.app.apiBasepath +
                  `/booking-receipt/update-waybill/${this.getBookingReceipt.id}`,
                {
                  waybill_document: `https://s3-usfc-avatar-image.s3.ap-southeast-1.amazonaws.com/SIGNED_${this.getBookingReceipt.booking_number}_waybill.pdf`,
                },
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then(() => {
                location.reload();
              })
              .catch(() => {
                this.$alertSys("An error occured", "err");
              });
          });
        });
    },
    openInNewTab(link) {
      if (!this.getBookingReceipt.waybill_document) {
        this.$alertSys("Waybill not available", "err");
        return;
      }
      console.log("waybill", link);
      window.open(link, "_blank");
    },
    async downloadFile(link) {
      if (!this.getBookingReceipt.waybill_document) {
        this.$alertSys("Waybill not available", "err");
        return;
      }

      const response = await fetch(link);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = link.split("/").pop(); // Specify the filename here
      anchor.click();

      // Clean up
      window.URL.revokeObjectURL(blobUrl);
    },
    getTime(date, type) {
      if (type == "date") {
        return moment(date).format("ddd, DD/MM/YYYY");
      }
      return moment(date).format("HH:mm");
    },
    handleImageError() {
      return "/placeholders/unavailable-image.jpg";
    },
    openPhotoViewer(category, photoFile, documentFile) {
      let arrayImage;
      let path, photoLabel, documentLabel;

      if (category) {
        if (category === 1) {
          path = "pickup";
          photoLabel = "Pickup Photo";
          documentLabel = "Pickup Document";
        }
        if (category === 2) {
          path = "delivery-status";
          photoLabel = "Update Photo";
          documentLabel = "Update Document";
        }
        if (category === 3) {
          path = "arrived";
          photoLabel = "Delivered Photo";
          documentLabel = "Delivered Document";
        }

        arrayImage = [
          {
            src: photoFile
              ? `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}`
              : this.handleImageError(),
            label: photoLabel,
          },
          {
            src: documentFile
              ? `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${documentFile}`
              : this.handleImageError(),
            label: documentLabel,
          },
        ];

        this.imageArray = arrayImage;
        console.log("AAA", arrayImage);
        this.showPhoto = true;
      }
    },

    getPhotoUrl(photoFile) {
      if (!photoFile)
        return "https://t4.ftcdn.net/jpg/04/69/55/05/360_F_469550598_GGPjgmPNMycMzhDBIOuTPXyC0j29nETY.jpg";
      const path = "gas-receipt";
      if (photoFile && !photoFile.includes("amazonaws"))
        photoFile = `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}`;

      return photoFile;
    },
    openGasReceipt(photoFile, label) {
      let arrayImage;
      let path, photoLabel;

      path = "gas-receipt";
      photoLabel = label;
      if (photoFile && !photoFile.includes("amazonaws"))
        photoFile = `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}`;

      arrayImage = [
        {
          src: photoFile || this.handleImageError(),
          label: photoLabel,
        },
      ];

      this.imageArray = arrayImage;
      this.showPhoto = true;
    },
    closePhotoViewer() {
      this.showPhoto = false;
      this.imageArray = null;
    },
    formatDate(dateString) {
      const inputDate = moment.utc(dateString).utcOffset(7);
      const today = moment().utcOffset(7).startOf("day");

      if (inputDate.isSame(today, "day")) {
        return "Today, " + inputDate.format("HH:mm") + " WIB";
      } else {
        return inputDate.format("DD/MM/YYYY, HH:mm") + " WIB";
      }
    },
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    createInterval() {
      console.log("aaa");
      this.getLocation(this.receipt?.transporter?.id);
      this.intervalId = setInterval(() => {
        this.getLocation(this.receipt?.transporter?.id);
      }, 10000);
    },

    async getLocation(transporterId) {
      console.log("aaa");
      let token = JSON.parse(localStorage.getItem("token"));

      const { data } = await axios.get(
        this.$store.state.app.apiBasepath +
          "/receipt/location?transporterId=" +
          transporterId,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log(data, "data");
      if (data) {
        const loc = {
          location: {
            lat: data.lat,
            lng: data.lng,
          },
        };

        this.selectLocation(loc);
      }
    },
    async getDetailByNumber(bookNum) {
      let token = JSON.parse(localStorage.getItem("token"));

      const data = await axios
        .get(
          this.$store.state.app.apiBasepath +
            "/booking-receipt/details-by-number/" +
            bookNum,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          return res.data;
        });

      if (data) this.getDetail(data.id);
    },
    async getDetail(id) {
      let token = JSON.parse(localStorage.getItem("token"));

      const data = await axios
        .get(this.$store.state.app.apiBasepath + "/receipt/details/" + id, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          return res.data;
        });

      if (data) {
        this.receipt.detail = data.receipt;
        this.receipt.transporter = data.transporter;
        this.receipt.secondary_transporter = data.secondary_transporter;

        this.gmaps.originMarker = {
          lat: +data?.receipt?.booking_receipt?.pickup_latitude,
          lng: +data?.receipt?.booking_receipt?.pickup_longitude,
        };

        this.gmaps.destinationMarker = {
          lat: +data?.receipt?.booking_receipt?.recipient_company?.latitude,
          lng: +data?.receipt?.booking_receipt?.recipient_company?.longitude,
        };

        if (
          this.getBookingReceipt.rate &&
          !this.getBookingReceipt.rate.shipping_type.is_real_time
        ) {
          this.getLocation(this.receipt?.transporter?.id);
        }

        this.$store.state.app.breadcrumb.currentSubSubMenu =
          this.receipt.detail.booking_receipt.booking_number;

        const baggings = await axios
          .get(
            this.$store.state.app.apiBasepath +
              "/booking-receipt/details/" +
              data.receipt.booking_receipt.id,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            return res.data.bagging;
          });

        if (baggings) this.receipt.baggings = baggings;

        const gas = await axios
          .get(
            this.$store.state.app.apiBasepath +
              "/booking-receipt/gas-station-locations/" +
              data.receipt.booking_receipt.id,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            return res.data.rows;
          });
        if (gas) {
          const temp = gas;

          await Promise.all(
            temp.map(async (gas) => {
              gas.lat = gas.latitude;
              gas.lng = gas.longitude;
              gas.formatted_address = await this.getLocDetails({
                lat: gas.lat,
                lng: gas.lng,
              });
              console.log(gas, "rob");
              return gas;
            })
          );

          if (gas) this.gasLocations = temp;
        }

        const cases = await axios
          .get(
            this.$store.state.app.apiBasepath +
              "/booking-receipt/case/" +
              data.receipt.booking_receipt.id,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            return res.data.rows;
          });

        if (cases) {
          this.cases = cases;
        }

        console.log("fetching");
        if (!this.getBookingReceipt.waybill_document.includes("SIGNED"))
          this.getPdfSource();
      }
    },

    async getLocDetails(coords) {
      try {
        const loader = new Loader({
          apiKey: "AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM", // Replace with your actual Google Maps API key
          version: "weekly",
          token: this.$store.state.app.sessionToken,
        });

        const google = await loader.load();

        const geocoder = new google.maps.Geocoder();

        // Geocode the coordinates to get place details
        geocoder.geocode({ location: coords }, (results, status) => {
          if (status === "OK" && results[0]) {
            return results[0].formatted_address;
          } else {
            console.error("Geocoder failed due to: " + status);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    markerCallback(e) {
      let coords = {
        location: {
          lat: e.lat(),
          lng: e.lng(),
        },
      };
      this.selectLocation(coords);
    },
    selectLocation(itemIndex) {
      console.log("focus");
      let loc = itemIndex.location;
      this.gmaps.marker = { lat: loc.lat, lng: loc.lng };
      this.gmaps.center = { lat: loc.lat, lng: loc.lng };
      setTimeout(() => {
        this.gmaps.suggestions = [];
        this.gmaps.zoom = 10;
      }, 250);
      // this.getLocDetails(loc);
    },
    async viewLocationHistory() {
      const loc = {
        location: {
          ...this.gmaps.marker,
        },
      };

      this.selectLocation(loc);
      // const address = this.getLatestAddress;

      // if (![this.getReceipt.delivered_address ?? "Address", this.getReceipt.update_address ?? "Address"].includes(address)) return;

      // if (!this.getLatestAddress) return;

      // try {
      //     const { data } = await axios.get("https://api.macra.co.id/maps/place?searchQuery=" + address + "&lat=" + this.gmaps.center.lat + "&lng=" + this.gmaps.center.lng);

      //     if (data) {
      //         const loc = data.results[0].geometry.location;
      //         this.gmaps.historyMarker = { lat: loc.lat, lng: loc.lng };
      //         this.gmaps.center = { lat: loc.lat, lng: loc.lng };

      //         setTimeout(() => {
      //             this.gmaps.suggestions = [];
      //             this.gmaps.zoom = 15;
      //         }, 250);
      //     }
      // } catch (error) {
      //     console.log(error);
      // }
    },
  },
  mounted() {
    if (this.$route.params.id.includes("UC"))
      this.getDetailByNumber(this.$route.params.id);
    else this.getDetail(this.$route.params.id);

    console.log("1");
  },
  beforeUnmount() {
    this.clearInterval();
  },
};
</script>
<style lang="scss" scoped>
@import "./userDetail.scss";
</style>

<template>
  <div style="height: 90vh">
    <div id="modalTitle" class="row">
      <div class="col p-2">
        <h5>
          <span class="p-2" style="font-weight: 700; float: left"
            >Create
            <span v-if="this.$store.state.app.openModal == 'publicRateCreate'"
              >Public</span
            >
            <span v-if="this.$store.state.app.openModal == 'clientRateCreate'"
              >Client</span
            >
            Rate</span
          >
          <span @click="this.$store.state.app.openModal = ''" id="closeModal"
            ><img
              class="d-flex justify-content-center"
              src="/icons/all/outline/add.svg"
          /></span>
        </h5>
      </div>
    </div>

    <form-wrapper>
      <div
        id="forms"
        class="row text-start d-flex flex-nowrap profile"
        style="height: 82%; overflow-y: scroll"
      >
        <form>
          <div class="label-float">
            <label :class="bodyForm.originId == '' ? '' : 'option-selected'"
              >Origin</label
            >
            <label
              v-if="bodyForm.originId == ''"
              :class="bodyForm.originId == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              @change="validateStage(0)"
              v-model="bodyForm.originId"
              placeholder=" "
            >
              <option :value="null" disabled>-- Select Origin --</option>
              <option
                v-for="origin in originList"
                :value="origin.id"
                :key="origin.id"
              >
                {{ origin.city }}
              </option>
            </select>
          </div>

          <div class="label-float">
            <label
              :class="bodyForm.destinationId == '' ? '' : 'option-selected'"
              >Destination</label
            >
            <label
              v-if="bodyForm.destinationId == ''"
              :class="bodyForm.destinationId == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              @change="validateStage(0)"
              v-model="bodyForm.destinationId"
              placeholder=" "
            >
              <option :value="null" disabled>-- Select Destination --</option>
              <option
                v-for="destination in destinationList"
                :value="destination.id"
                :key="destination.id"
              >
                {{ destination.city }}
              </option>
            </select>
          </div>
          <div class="label-float">
            <label
              :class="bodyForm.shippingTypeId == '' ? '' : 'option-selected'"
              >Shipping Type</label
            >
            <label
              v-if="bodyForm.shippingTypeId == ''"
              :class="bodyForm.shippingTypeId == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              @change="validateStage(0)"
              v-model="bodyForm.shippingTypeId"
              placeholder=" "
            >
              <option :value="null" disabled>-- Select Shipping Type --</option>
              <option
                v-for="shippingType in shippingTypeList"
                :value="shippingType.id"
                :key="shippingType.id"
              >
                {{ shippingType.name }}
              </option>
            </select>
          </div>
          <div class="label-float">
            <label :class="bodyForm.providerId == '' ? '' : 'option-selected'"
              >Provider</label
            >
            <label
              v-if="bodyForm.origproviderIdinId == ''"
              :class="bodyForm.providerId == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              @change="validateStage(0)"
              v-model="bodyForm.providerId"
              placeholder=" "
            >
              <option :value="null" disabled>-- Select Provider --</option>
              <option
                v-for="provider in providerList"
                :value="provider.id"
                :key="provider.id"
              >
                {{ provider.name }}
              </option>
            </select>
          </div>
          <div class="label-float">
            <label :class="bodyForm.serviceId == '' ? '' : 'option-selected'"
              >Service</label
            >
            <label
              v-if="bodyForm.serviceId == ''"
              :class="bodyForm.serviceId == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select
              @change="validateStage(0)"
              v-model="bodyForm.serviceId"
              placeholder=" "
            >
              <option :value="null" disabled>-- Select Service --</option>
              <option
                v-for="service in serviceList"
                :value="service.id"
                :key="service.id"
              >
                {{ service.name }}
              </option>
            </select>
          </div>

          <div class="label-float">
            <input
              @change="validateStage(0)"
              @input="checkNegativeInput('minimum_weight')"
              v-model="bodyForm.minimum_weight"
              id="minimum_weight"
              min="0"
              type="number"
              placeholder=" "
              autocomplete="off"
            />
            <label>Minimum Weight (Kg)</label>
          </div>
          <div class="label-float">
            <input
              @change="validateStage(0)"
              @input="checkNegativeInput('rate')"
              v-model="bodyForm.rate"
              id="rate"
              min="0"
              type="number"
              placeholder=" "
              autocomplete="off"
            />
            <label>Rate (Rp)</label>
          </div>
          <h5 class="pt-3 ms-1">SLA</h5>
          <div class="row pt-0">
            <div class="col pt-0">
              <div class="label-float">
                <input
                  @change="validateStage(0)"
                  @input="checkNegativeInput('day')"
                  v-model="temp.day"
                  id="day"
                  type="number"
                  min="0"
                  placeholder=" "
                  autocomplete="off"
                />
                <label>Day(s)</label>
              </div>
            </div>
            <div class="col pt-0">
              <div class="label-float">
                <input
                  @change="validateStage(0)"
                  @input="validateHourInput('hour')"
                  v-model="temp.hour"
                  id="hour"
                  type="number"
                  min="0"
                  max="23"
                  placeholder=" "
                  autocomplete="off"
                />
                <label>Hour(s)</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </form-wrapper>
    <div id="navigation" class="row my-2">
      <div class="col px-1">
        <button
          @click="register()"
          disabled
          id="finalize"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
        >
          Create
          <span v-if="this.$store.state.app.openModal == 'publicRateCreate'"
            >Public</span
          >
          <span v-if="this.$store.state.app.openModal == 'clientRateCreate'"
            >Client</span
          >
          Rate
        </button>

        <span
          @click="this.$store.state.app.openModal = ''"
          style="float: right"
          class="mx-2 btn btn-cancel"
        >
          Cancel</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import router from "@/router";
import $ from "jquery";
import axios from "axios";

export default {
  name: "originCreate",
  components: {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    userFullName() {
      if (this.$store.state.userData.full_name.length > 30) {
        return this.$store.state.userData.full_name.substring(0, 28) + "...";
      } else {
        return this.$store.state.app.userData.full_name;
      }
    },
    role() {
      if (this.$store.state.userData.access_level == 5) {
        return "Manager";
      } else if (this.$store.state.userData.access_level == 4) {
        return "Admin";
      } else if (this.$store.state.userData.access_level == 3) {
        return "Sales";
      } else if (this.$store.state.userData.access_level == 2) {
        return "Client";
      } else {
        return "Transporter";
      }
    },
  },
  data() {
    return {
      photo64: "",
      photoFile: "",
      access_level: 4,
      stageIndex: 0,
      pwdconf: "",
      stages: [
        // status (0: upcoming, 1: active, 2: done)
        {
          name: "Fill Personal Profile",
          status: 1,
        },
        {
          name: "Create Password",
          status: 0,
        },
      ],
      selects: "",
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
      bodyForm: {
        originId: null,
        destinationId: null,
        shippingTypeId: null,
        providerId: null,
        serviceId: null,
        minimum_weight: null,
        rate: null,
        sla: null,
      },
      temp: {
        day: null,
        hour: null,
      },
      originList: [],
      destinationList: [],
      shippingTypeList: [],
      providerList: [],
      serviceList: [],
    };
  },
  methods: {
    ascSort() {
      function compare(a, b) {
        if (a.rate < b.rate) return -1;
        if (a.rate > b.rate) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.rateForm.public.allRates.sort(compare);
      this.paginate();
    },
    preloadState() {
      this.$store.state.app.managerForm.noResult = false;
      this.$store.state.app.managerForm.formLoading = true;
    },
    isDataEmpty(axiosResponse) {
      if (axiosResponse.count == 0) {
        this.$store.state.app.managerForm.noResult = true;
        this.$store.state.app.managerForm.formLoading = false;
      } else {
        this.$store.state.app.managerForm.noResult = false;
        this.$store.state.app.managerForm.formLoading = false;
      }
    },
    refreshRate() {
      this.preloadState();
      this.$store.state.app.rateForm.public.filters.active = "Origin";

      router.replace("/dashboards");
      setTimeout(() => {
        if (this.$store.state.app.openModal == "publicRateCreate") {
          router.replace("/rates/public");
        } else {
          router.replace("/rates/client");
        }
      }, 1);
    },
    checkNegativeInput(el) {
      if (document.getElementById(el).value < 0) {
        document.getElementById(el).value = 0;
      }

      console.log(el);
      this.getTotalSla();
    },
    checkMaxHour(el) {
      if (document.getElementById(el).value > 23) {
        document.getElementById(el).value = 23;
      }

      console.log(el);
      this.getTotalSla();
    },
    validateHourInput(el) {
      this.checkNegativeInput(el);
      this.checkMaxHour(el);
    },
    getTotalSla() {
      let totalsla = 0;
      totalsla = this.temp.day * 24;
      this.bodyForm.sla = totalsla + this.temp.hour;
      this.validateStage();
    },
    validateStage() {
      let valid = false;
      for (var prop in this.bodyForm) {
        console.log("PROPVAL : " + this.bodyForm[prop]);
        if (
          this.bodyForm[prop] == null ||
          this.bodyForm[prop].length == 0 ||
          this.bodyForm[prop] == 0
        ) {
          $("#finalize").prop("disabled", false);
          valid = false;
          break;
        } else {
          valid = true;
        }
      }
      console.log(this.bodyForm);
      console.log(valid);
      if (valid) {
        $("#finalize").prop("disabled", false);
      } else {
        $("#finalize").prop("disabled", true);
      }
    },
    changePic() {
      $("#pict").click();
    },

    showPreview() {
      let pic = document.getElementById("pict");
      if (pic.files.length > 0) {
        var src = URL.createObjectURL(pic.files[0]);
        var preview = document.getElementById("pictureImg");
        preview.src = src;
      }
    },
    togglePass() {
      $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwd");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    togglePassConfirm() {
      $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwdconf");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    nextStage() {
      if (document.getElementById("pict").files.length > 0) {
        this.photoFile = document.getElementById("pict");
      } else {
        this.photoFile = null;
      }
      this.stages[this.stageIndex].status = 2;
      this.stages[this.stageIndex + 1].status = 1;
      $("#stages").scrollLeft("2000px");
      this.stageIndex++;
    },
    prevStage() {
      this.stages[this.stageIndex].status = 0;
      this.stages[this.stageIndex - 1].status = 1;
      $("#stages").scrollLeft("200px");
      this.stageIndex--;
    },
    photoInput() {
      $("#photoInput").click();
    },
    randomizeName() {
      return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021")
        .toString()
        .substring(0, 16);
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
    currentMenu() {
      if (
        ["Publish Rates", "Client Rates", "Request Change"].includes(
          this.$route.name
        )
      ) {
        return "Rates";
      }

      if (["Users", "References"].includes(this.$route.name)) {
        return "Manages";
      }

      if (["Receipt", "SLA"].includes(this.$route.name)) {
        return "Reports";
      } else {
        return this.$route.name;
      }
    },
    currentSubmenu() {
      let menu = this.currentMenu();
      if (["Dashboard", "Receipts"].includes(menu)) {
        return "";
      } else {
        return this.$route.name;
      }
    },
    getMenu(val) {
      this.menu = val;
    },
    getSub(val) {
      this.submenu = val;
    },
    toggleUserAction() {
      this.userAction = !this.userAction;
    },
    setFocusUser(ele) {
      $(ele.target).focus();
      console.log(ele.target);
    },
    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.keepLogin;
          this.$store.state.userData = userData;
        })
        .catch(() => {
          if (!this.$route.query.wv) this.$router.push("/signin");
        });
    },
    encode64() {
      async function listarchivos(fileInput) {
        var base64; //in this variable i need the base64
        var fileToLoad = fileInput;
        base64 = await getBase64(fileToLoad).then((data) => {
          return data;
        });
        console.log(base64);
        return base64;
      }

      //This is my function for get base64, but not return the string base64
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          return Promise.resolve(reader.result);
        });
      }

      let result = listarchivos(this.photoFile);
      return result;
    },
    updateTable() {
      this.$refs.adminTable.this.getAllAdmin();
    },
    register() {
      let token = JSON.parse(localStorage.getItem("token"));
      let path = "";
      if (this.$store.state.app.openModal == "publicRateCreate") {
        path = "Public";
        this.bodyForm.type = "public_rate";
      } else {
        path = "Client";
        this.bodyForm.type = "client_rate";
        // this.bodyForm["userId"] = this.$route.params.id;
        const corpId = this.$route.params.id;
        this.bodyForm["corporateId"] = +corpId;
      }

      axios
        .post(this.$store.state.app.apiBasepath + "/rate/add", this.bodyForm, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          this.$alertSys("New " + path + " Rate added", "success");
          this.$router.push("/dashboard");
          setTimeout(() => {
            if (path == "Client") {
              this.$router.push("/rates/client/" + this.bodyForm.corporateId);
            } else {
              this.$router.push("/rates/public");
            }
          }, 50);
          setTimeout(() => {
            this.$store.state.app.openModal = "";
          }, 250);
        })
        .catch(() => {
          this.$alertSys("An error occured", "err");
        });
    },
    citySort(cityObject) {
      function compare(a, b) {
        if (a.city < b.city) return -1;
        if (a.city > b.city) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      cityObject.sort(compare);
    },
    nameSort(nameObject) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      nameObject.sort(compare);
    },
    descSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return 1;
        if (a.full_name > b.full_name) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.originList.sort(compare);
    },
    getAllOrigin() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/origin/all", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.originList = res.data.rows;
          this.citySort(this.originList);
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    getAllDestination() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/destination/all", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.destinationList = res.data.rows;
          this.citySort(this.destinationList);
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    getAllShippingType() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/shipping-type/all", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.shippingTypeList = res.data.rows;
          this.nameSort(this.shippingTypeList);
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    getAllProvider() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/provider/all", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.providerList = res.data.rows;
          this.nameSort(this.providerList);
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },

    getAllService() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/service/all", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.serviceList = res.data;
          this.nameSort(this.serviceList);
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    getAllData() {
      this.getAllOrigin();
      this.getAllDestination();
      this.getAllShippingType();
      this.getAllProvider();
      this.getAllService();
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredAdmins[i] = JSON.parse(
          JSON.stringify(this.originList.slice(i * 10, (i + 1) * 10))
        );
      }
    },
  },
  mounted() {
    this.getAllData();
  },
};
</script>

<style scoped>
#forms::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#closeModal {
  float: right;
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}

#closeModal:hover {
  border: 1px solid black;
}

#navigation {
  padding-top: 10px;
  position: relative;
}

#stages > .col-auto {
  opacity: 0.5;
}

#stages > .col-auto.active {
  opacity: 1;
}

#pictureImg {
  cursor: pointer;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#pictureImgBtn {
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #3699ff;

  position: absolute;
  top: 180px;
  left: 53%;
}

#pictureImgBtn > #pictureBtn {
  transform: scale(1.2);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(103%) contrast(103%);
}

#profilePicture {
  height: 20%;
}

#stages {
  overflow-x: scroll;
}
#stages::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.blur-overlay {
  display: block;
  filter: blur(3px) brightness(90%);
  -webkit-filter: blur(3px) brightness(90%);
  -moz-filter: blur(3px) brightness(90%);
  -o-filter: blur(3px) brightness(90%);
  -ms-filter: blur(3px) brightness(90%);
  pointer-events: none;
}

.spacer {
  min-width: 250px;
  width: 20vw;
}

/* VUE TRANSITION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* css  */
myroute {
  cursor: pointer;
}

.btn-back {
  color: #3699ff;
  outline: 1px solid #3699ff;
}

.btn-back:hover {
  color: #3699ff;
  outline: 1px solid #3699ff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
}

.usfc-bg {
  background-color: #fbfbfb;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input[type="password"] {
  font-family: Verdana;
  letter-spacing: 0.25em;
  font-weight: 900;
}

.toggle-password,
.toggle-password-confirm {
  cursor: pointer;
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 75px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  z-index: 999;
  position: absolute;
  width: auto;
  right: 20px;
  top: 15vh;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 6px;
}

ul.user-button li {
  position: relative;
  padding: 10px 0;
  width: 200px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 2em;
}

ul.user-button {
  position: absolute;
  top: 75px;
  right: 0;
  padding-left: 0;
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

a {
  color: inherit;
  text-decoration: none;
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  border: 1px solid transparent;
}

select {
  width: 100%;
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}

/* FA ICONS CUSTOM */
.fa-check {
  margin: 0 5px;
  position: relative;
  top: -2px;
  font-size: 12px;
  color: white;
  border-radius: 50%;
  padding: 3px;
  background-color: #3699ff;
}

/* * {
    border: 1px solid red;
  } */
</style>

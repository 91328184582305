<template>
  <div class="px-3 py-0 my-0 prevent-select">
    <FormLoading v-if="this.$store.state.app.managerForm.formLoading" />
    <EmptyState v-if="this.$store.state.app.managerForm.noResult" />
    <table
      v-if="
        !this.$store.state.app.managerForm.formLoading &&
        !this.$store.state.app.managerForm.noResult
      "
      class="table"
    >
      <thead>
        <tr>
          <th class="col-2">
            <span> Service </span>
          </th>
          <th class="col-3">
            <span> Description </span>
          </th>
          <th class="col-3">
            <span> Price per kg </span>
          </th>
          <th class="col">
            <span> Min. Weight </span>
          </th>
          <th class="col">
            <span> SLA </span>
          </th>
        </tr>
        <!-- </thead>
      <tbody v-if="this.$store.state.app.managerForm.search == ''">
        <tr v-for="admin in filteredOrigin[page - 1]" :key="admin?.id" :class="this.$store.state.app.managerForm.refSelects.includes(admin?.id, this.$store.state.userData.access_level) ? 'idSelected' : 'point'">
          <th @click="removeSelected(admin?.id, this.$store.state.userData.access_level)" v-if="this.$store.state.app.managerForm.refSelects.includes(admin?.id, this.$store.state.userData.access_level)" scope="row" style="cursor: pointer">
            <img src="/icons/custom/picker.svg" />
          </th>
          <th @click="this.$store.state.app.managerForm.refSelects.push(admin?.id, this.$store.state.userData.access_level)" v-else scope="row" style="cursor: pointer">
            <img src="/icons/custom/picker-deselect.svg" />
          </th>

          <td @click="openDetails(admin?.id, 'originView')" >{{ admin?.city }}</td>
          <td @click="removeSelected(admin?.id, this.$store.state.userData.access_level)" style="cursor: pointer" v-else>{{ admin?.city }}</td>

          <td @click="openDetails(admin?.id, 'originView')" >{{ admin?.province }}</td>
          <td @click="removeSelected(admin?.id, this.$store.state.userData.access_level)" style="cursor: pointer" v-else>{{ admin?.province }}</td>
        </tr>
      </tbody> -->
      </thead>

      <tbody>
        <tr
          v-for="admin in filteredOrigin"
          :key="admin?.id"
          :class="
            this.$store.state.app.managerForm.refSelects.includes(
              admin?.id,
              this.$store.state.userData.access_level
            )
              ? 'idSelected'
              : 'point'
          "
        >
          <td>{{ admin.service.name }}</td>

          <td>
            <span v-if="admin.service.name == 'DTD'">Door to Door</span>
            <span v-if="admin.service.name == 'DTP'">Door to Port</span>
            <span v-if="admin.service.name == 'PTP'">Port to Port</span>
            <span v-if="admin.service.name == 'PTD'">Port to Door</span>
          </td>

          <td>{{ admin.rate }}</td>
          <td>{{ admin.minimum_weight }}</td>
          <td>{{ convertHours(admin.sla) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */

import axios from "axios";

import EmptyState from "@/reusables/EmptyState.vue";
import FormLoading from "@/reusables/formLoading.vue";
import Paginate from "vuejs-paginate-next";
import router from "@/router";

export default {
  name: "ManagerComponent",
  props: {},
  components: {
    Paginate,
    EmptyState,
    FormLoading,
  },
  data() {
    return {
      sortType: "ASC",
      page: 1,
      refSelects: [],
      admins: [],
      allOrigin: [],
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
    };
  },
  computed: {
    filteredOrigin() {
      return this.$store.state.app.rateForm.checkRates.data.display;
    },

    getLoadForm() {
      return this.loadForm;
    },
    totalPages() {
      return Math.ceil(this.$store.state.app.managerForm.allOrigin.length / 10);
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
    getSortType() {
      return this.sortType;
    },
  },
  methods: {
    convertHours(input) {
      let hours = +input;
      console.log(input);
      let days = Math.floor(hours / 24);
      let hrs = hours - 24 * days;

      return days + " Days " + hrs + " Hours ";
    },
    openDetails(id, access_level) {
      this.$store.state.app.managerForm.userDetail = access_level;
      this.$router.push("./request/" + id);
    },

    sort() {
      if (this.getSortType == "DESC") {
        this.ascSort();
      } else {
        this.descSort();
      }

      this.$store.state.app.managerForm.refSelects = [];
    },
    ascSort() {
      function compare(a, b) {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allOrigin.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allOrigin.sort(compare);
      this.paginate();
    },
    getAllAdmins() {
      this.$store.state.app.managerForm.noResult = false;
      this.$store.state.app.managerForm.formLoading = true;

      if (this.filteredOrigin.length > 0) {
        this.$store.state.app.managerForm.noResult = false;
        this.$store.state.app.managerForm.formLoading = false;
      } else {
        this.$store.state.app.managerForm.noResult_type = "rate";
        this.$store.state.app.managerForm.noResult = true;
        this.$store.state.app.managerForm.formLoading = false;
      }
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredOrigin[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allOrigin.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
    ascendingSort() {
      this.allOrigin.sort((a, b) => a - b);
    },
    selectAll() {
      Object.values(this.filteredOrigin[this.page - 1]).forEach((val) => {
        this.$store.state.app.managerForm.refSelects.push(val.id);
      });
    },
    removeSelected(val) {
      const index = this.$store.state.app.managerForm.refSelects.indexOf(val);
      if (index > -1) {
        // only splice array when item is found
        this.$store.state.app.managerForm.refSelects.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
  },
  mounted() {
    this.getAllAdmins();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
th:hover > img {
  filter: drop-shadow(0 0 0 #3699ff);
}

td {
  line-height: 6vh;
}

option {
  line-height: 50px;
}

.pagination {
  cursor: pointer;
}
.pagination:hover {
  filter: none;
  background-color: transparent;
}

.point {
  cursor: pointer;
}

.point:hover {
  outline: 1px solid #3699ff;
}

.idSelected {
  outline: 1px solid #3699ff;
}

.page-item:hover {
  filter: none;
}
.usfc-bg {
  background-color: #fbfbfb;
}

.white-filter {
  filter: invert(100%) brightness(1) contrast(200%);
}
.scaled {
  transform: scale(0.8);
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

li.page-item {
  color: black;
}

th > img {
  cursor: pointer;
}

/* pagination stylers */
.pagination > li {
  outline: none;
  border: none;
  filter: none;
}

.page-item.active .page-link {
  color: black;
  font-weight: bold;
}

li {
  text-align: center;
  width: 35px;
  margin: 0 5px;
  transition: all 0.2s ease;
}

li:hover {
  filter: none;
  translate: 0 -10px;
  cursor: pointer;
}

.page-item .page-link {
  color: darkgrey;
}

.page-item.disabled .page-link {
  opacity: 0.5;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  background-color: #3699ff;
  color: white;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  background-color: #3699ff;
}

.page-link {
  border-radius: 10px;
}

tbody tr {
  line-height: 290%;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
</style>

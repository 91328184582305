<template>
  <div
    v-if="!loadingData"
    class="row p-2"
    style="background-color: transparent; height: 80vh"
  >
    <div class="col" style="background: #fbfbfb">
      <div class="row h-100">
        <div class="col-3" style="background: #ffffff">
          <div class="row w-100" style="height: 100%">
            <!--  -->
            <div
              class="col justify-content-center"
              style="height: 100%; position: relative"
            >
              <div
                class="row ms-4 ps-2 my-2 active"
                style="font-size: 12px; opacity: 0.5"
              >
                <div>Navigation</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#shipper')"
                id="shipper_nav"
                class="row mx-auto childform my-2"
              >
                <div>Shipper Info</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#recipient')"
                id="recipient_nav"
                class="row mx-auto childform my-2"
              >
                <div>Recipient Info</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#shipping')"
                id="shipping_nav"
                class="row mx-auto childform my-2"
              >
                <div>Shipping Info</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#pickup')"
                id="pickup_nav"
                class="row mx-auto childform my-2"
              >
                <div>Pickup Info</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#bagging')"
                id="bagging_info"
                class="row mx-auto childform my-2"
              >
                <div>Bagging Info</div>
              </div>

              <!--  -->
              <div
                v-if="showFee"
                @click="scrollTo('#fee')"
                id="fee_nav"
                class="row mx-auto childform my-2"
              >
                <div>Additional Fee</div>
              </div>

              <!--  -->
              <div
                @click="scrollTo('#order')"
                id="order_summary"
                class="row mx-auto childform my-2"
              >
                <div>Order Summary</div>
              </div>
              <div
                v-if="
                  getBodyForm.booking_status === 4 &&
                  +this.$store.state.userData.access_level >= 4
                "
                class="row mx-auto my-2 w-100"
                style="position: absolute; bottom: 0"
              >
                <div
                  @click="approveBooking()"
                  :class="actions ? '' : 'disabled'"
                  class="btn btn-usfc-primary my-2"
                >
                  Approve
                </div>

                <div
                  @click="this.$store.state.app.openModal = 'rejectBooking'"
                  :class="actions ? '' : 'disabled'"
                  class="btn btn-usfc text-danger"
                >
                  Reject
                </div>
              </div>
              <div
                v-if="
                  getBodyForm.booking_status === 2 &&
                  +this.$store.state.userData.access_level == 2
                "
                class="row mx-auto my-2 w-100"
                style="position: absolute; bottom: 0"
              >
                <div
                  @click="approveClient()"
                  :class="actions ? '' : 'disabled'"
                  class="btn btn-usfc-primary my-2"
                >
                  Approve
                </div>

                <div
                  @click="this.$store.state.app.openModal = 'rejectBooking'"
                  :class="actions ? '' : 'disabled'"
                  class="btn btn-usfc text-danger"
                >
                  Reject
                </div>
              </div>
              <div
                v-else
                id="status"
                class="row mx-auto my-2 w-100"
                style="position: absolute; bottom: 0"
              >
                <div
                  v-if="
                    getBodyForm?.booking_status == 3 ||
                    getBodyForm?.booking_status == 1
                  "
                  @click="openApprovalDetails(getBodyForm.booking_status)"
                  class="btn btn-usfc-outline text-primary"
                >
                  Approval Details
                </div>
                <div
                  v-if="
                    getBodyForm?.booking_status == 4 &&
                    this.$store.state.userData.access_level === 2
                  "
                  class="btn btn-usfc text-warning"
                >
                  Pending for Admin Approval<img
                    class="mx-3"
                    src="/S_Pending_icon.svg"
                    style="width: 10%"
                  />
                </div>
                <div
                  v-if="
                    getBodyForm?.booking_status == 2 &&
                    this.$store.state.userData.access_level >= 4
                  "
                  class="btn btn-usfc text-warning"
                >
                  Pending for Client Approval<img
                    class="mx-3"
                    src="/S_Pending_icon.svg"
                    style="width: 10%"
                  />
                </div>
                <div
                  v-if="getBodyForm?.booking_status == 3"
                  class="btn btn-usfc text-primary"
                >
                  Approved
                  <img class="mx-3" src="/S_Approved.svg" style="width: 10%" />
                </div>
                <div
                  v-if="getBodyForm?.booking_status == 1"
                  class="btn btn-usfc text-danger"
                >
                  Rejected
                  <img class="mx-3" src="/S_Rejected.svg" style="width: 10%" />
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-center">
          <div class="row w-100">
            <div id="scrollable" class="col scroll-container">
              <div
                id="shipper"
                class="row mx-auto my-2 mb-3 w-75 py-3 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Shipper Information</strong></h4>
                  <div id="forms" class="row text-start d-flex flex-nowrap">
                    <form onsubmit="return false">
                      <div class="my-2">Company Name</div>
                      <div class="label-float pt-0">
                        <input
                          id="fullname"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.company"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">PIC Name</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.full_name"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Phone Number</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.phone"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Province</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.province"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">City</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.city"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">District</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.district"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Postal Code</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.postal_code"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Address</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.user.address"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                id="recipient"
                class="row mx-auto my-2 mb-3 w-75 py-3 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Recipient Information</strong></h4>
                  <div id="forms" class="row text-start d-flex flex-nowrap">
                    <form onsubmit="return false">
                      <div class="my-2">Company Name</div>
                      <div class="label-float pt-0">
                        <input
                          id="fullname"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.company"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">PIC Name</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.pic_name"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Phone Number</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.phone"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Province</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.province"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">City</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.city"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">District</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.district"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Postal Code</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.postal_code"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>

                      <div class="my-2">Address</div>
                      <div class="label-float pt-0">
                        <input
                          id="email"
                          type="text"
                          placeholder=" "
                          :value="getBodyForm?.recipient_company.address"
                          autocomplete="off"
                        />
                        <label></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                id="shipping"
                class="row mx-auto my-2 mt-3 pt-3 w-75 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Shipping Information</strong></h4>
                  <form-wrapper>
                    <div
                      id="forms"
                      class="row text-start d-flex flex-nowrap pb-3"
                      style="overflow-y: scroll"
                    >
                      <form style="max-height: 80%">
                        <div class="label-float">
                          <input
                            id="fullname"
                            type="text"
                            placeholder=" "
                            :value="getBodyForm?.rate?.origin?.city"
                            autocomplete="off"
                          />
                          <label>Origin</label>
                        </div>

                        <div class="label-float">
                          <input
                            id="fullname"
                            type="text"
                            placeholder=" "
                            :value="getBodyForm?.rate?.destination?.city"
                            autocomplete="off"
                          />
                          <label>Destination</label>
                        </div>

                        <div class="label-float">
                          <input
                            id="fullname"
                            type="text"
                            placeholder=" "
                            :value="getBodyForm?.rate?.provider?.name"
                            autocomplete="off"
                          />
                          <label>Provider</label>
                        </div>
                        <div class="label-float">
                          <input
                            id="fullname"
                            type="text"
                            placeholder=" "
                            :value="getBodyForm?.rate?.service?.name"
                            autocomplete="off"
                          />
                          <label>Service</label>
                        </div>
                        <div class="label-float">
                          <input
                            id="fullname"
                            type="text"
                            placeholder=" "
                            :value="getBodyForm?.rate?.shipping_type?.name"
                            autocomplete="off"
                          />
                          <label>Shipping Type</label>
                        </div>
                      </form>
                    </div>
                  </form-wrapper>
                </div>
              </div>

              <div
                id="pickup"
                class="row mx-auto my-2 mt-3 pt-3 w-75 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Pickup Details</strong></h4>
                  <form-wrapper>
                    <div id="forms" class="row text-start d-flex flex-nowrap">
                      <form>
                        <div class="row mt-3">
                          <div class="col">
                            <div style="font-weight: 600">
                              1. Pickup Address
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <!-- KEY AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM -->
                            <GoogleMap
                              class=""
                              api-key="AIzaSyCzYRio6GWqdf03CAd9Gs1sjS8b6fuIAyM"
                              @click="markerCallback($event.latLng)"
                              style="
                                width: 100%;
                                height: 200px;
                                filter: saturate(2);
                              "
                              :center="gmaps.center"
                              :controlSize="24"
                              :fullscreen-control="false"
                              :clickableIcons="false"
                              :map-type-control="false"
                              :street-view-control="false"
                              :zoom="gmaps.zoom"
                            >
                              <Marker
                                v-if="this.gmaps.marker.lat != null"
                                :options="{
                                  position: gmaps.marker,
                                  draggable: false,
                                  icon: '/icons/location.svg',
                                }"
                                @dragend="markerCallback($event.latLng)"
                              />
                            </GoogleMap>
                          </div>
                        </div>
                        <div
                          v-if="gmaps.selected.area != null"
                          id="selectedLocation"
                          class="row p-1 m-0 bg-white"
                          style="
                            width: 100%;
                            position: relative;
                            background: #ffffff;
                            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
                            border-radius: 6px;
                          "
                        >
                          <div class="col">
                            <div class="row py-1 px-0 mx-0 selected">
                              <div class="col">
                                <!-- <div class="row" style="font-weight: 600">{{ gmaps.selected.area }}</div> -->
                                <div class="row" style="font-size: 12px">
                                  {{ getBodyForm?.pickup_address }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col">
                            <div>
                              Address Details<sup class="text-danger">*</sup>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="label-float-tall">
                              <textarea
                                :value="getBodyForm?.pickup_address"
                                style="font-size: 12px"
                                rows="4"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3">
                          <div class="col">
                            <div style="font-weight: 600">2. PIC Info</div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="label-float">
                              <input
                                :value="getBodyForm.pic_name"
                                id="pic_name"
                                type="text"
                                placeholder=" "
                                autocomplete="off"
                              />
                              <label>PIC Name</label>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="label-float">
                              <input
                                :value="getBodyForm.pic_phone"
                                id="pic_name"
                                type="text"
                                placeholder=" "
                                autocomplete="off"
                              />
                              <label>Phone Number</label>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col">
                            <div style="font-size: 14px; font-weight: 600">
                              3. Pickup Time
                              <span
                                style="
                                  font-size: 12px;
                                  opacity: 0.75;
                                  font-weight: 200;
                                "
                                >(09.00 - 20.00 WIB)</span
                              >
                            </div>
                            <div
                              class="mb-3 mt-2"
                              style="font-size: 12px; opacity: 0.75"
                            >
                              The minimum reservation time for pickup is three
                              hours on the same day after booking, and the
                              maximum booking time for pickup on the same day is
                              17.00 WIB. Otherwise, you might choose a pickup
                              time on the next day.
                            </div>
                            <div class="row disabled">
                              <div class="col">
                                <Datepicker
                                  format="yyyy/MM/dd"
                                  :enable-time-picker="false"
                                  show-now-button
                                  v-model="getBodyForm.pickup_date"
                                  position="right"
                                  minutes-increment="10"
                                  minutes-grid-increment="10"
                                  placeholder="Select pickup date"
                                  auto-apply
                                  keep-action-row
                                  prevent-min-max-navigation=""
                                />
                              </div>
                              <div class="col">
                                <Datepicker
                                  @update:modelValue="validateTime()"
                                  hide-input-icon
                                  format="HH:mm"
                                  time-picker
                                  v-model="getPickupDate.time"
                                  position="right"
                                  minutes-increment="10"
                                  minutes-grid-increment="10"
                                  :min-time="{ hours: 9, minutes: 0 }"
                                  :max-time="{ hours: 20, minutes: 0 }"
                                  placeholder="Select pickup time"
                                  auto-apply
                                  keep-action-row
                                  :is-24="true"
                                  prevent-min-max-navigation=""
                                />
                                <div
                                  class="text-danger text-end"
                                  style="font-size: 12px; opacity: 0"
                                >
                                  Invalid pickup time
                                </div>
                              </div>
                              <div class="col-2">
                                <select
                                  style="
                                    position: relative;
                                    top: 2px;
                                    cursor: pointer;
                                    height: 25px;
                                  "
                                  class="py-1"
                                  v-model="dateTime.zone"
                                >
                                  <option>WIB</option>
                                  <option>WITA</option>
                                  <option>WIT</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </form-wrapper>
                </div>
              </div>
              <div
                id="bagging"
                v-if="baggings && baggings.length > 0"
                class="row mx-auto my-2 mt-3 pt-3 w-75 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Bagging Information</strong></h4>

                  <form-wrapper>
                    <div id="forms" class="row text-start d-flex flex-nowrap">
                      <div class="col ms-2">
                        Number of Baggings ({{ baggings.length }})
                      </div>
                    </div>
                    <div id="bagging-container" class="bagging-container mt-2">
                      <div
                        v-for="index in baggings.length"
                        :key="index"
                        :id="'bagging' + (index - 1)"
                        class="row text-start d-flex flex-nowrap bagging-card"
                      >
                        <div class="col-1 mt-4 ms-2" style="width: 25px">
                          {{ index }}.
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <div class="label-float">
                                <input
                                  v-model="baggings[index - 1].good_details"
                                  id="fullname"
                                  type="text"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Goods Detail</label>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <div class="label-float">
                                <input
                                  @input="baggingDimensionalWeight(index - 1)"
                                  v-model="baggings[index - 1].long"
                                  id="fullname"
                                  type="number"
                                  min="0"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Length (cm)</label>
                              </div>
                            </div>
                            <div
                              class="col-1 p-0 text-center"
                              style="width: 10px"
                            >
                              <span style="position: relative; top: 45%"
                                >X</span
                              >
                            </div>
                            <div class="col">
                              <div class="label-float">
                                <input
                                  @input="baggingDimensionalWeight(index - 1)"
                                  v-model="baggings[index - 1].width"
                                  id="fullname"
                                  type="number"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Width (cm)</label>
                              </div>
                            </div>
                            <div
                              class="col-1 p-0 text-center"
                              style="width: 10px"
                            >
                              <span style="position: relative; top: 45%"
                                >X</span
                              >
                            </div>
                            <div class="col">
                              <div class="label-float">
                                <input
                                  @input="baggingDimensionalWeight(index - 1)"
                                  v-model="baggings[index - 1].height"
                                  id="fullname"
                                  type="number"
                                  min="0"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Height (cm)</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="label-float">
                                <input
                                  @input="baggingDimensionalWeight(index - 1)"
                                  v-model="baggings[index - 1].actual_weight"
                                  id="fullname"
                                  type="number"
                                  min="0"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Weight</label>
                              </div>
                            </div>
                            <div class="col">
                              <div class="label-float">
                                <input
                                  disabled
                                  v-model="
                                    baggings[index - 1].dimensional_weight
                                  "
                                  id="fullname"
                                  type="text"
                                  placeholder=" "
                                  autocomplete="off"
                                />
                                <label>Dimensional Weight (kg)</label>
                              </div>
                            </div>
                          </div>

                          <div
                            class="row pb-5 mx-1 mt-3 pt-2"
                            style="border-top: solid 1px rgba(0, 0, 0, 0.1)"
                          >
                            <div class="col px-0">
                              <div style="">Accounted Weight (kg)</div>
                              <div>
                                {{ baggings[index - 1].accounted_weight }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form-wrapper>
                </div>
              </div>

              <div
                id="bagging"
                v-else
                class="row mx-auto my-2 mt-3 pt-3 w-75 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0">
                    <strong>Bagging Information (Unavaliable)</strong>
                  </h4>
                </div>
              </div>

              <div
                v-if="showFee"
                id="fee"
                class="row mx-auto my-2 mb-3 w-75 py-3 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Additional Fees</strong></h4>
                  <div id="forms" class="row text-start d-flex flex-nowrap">
                    <form class="col" onsubmit="return false">
                      <div class="row mx-1">
                        <div class="col">
                          <div class="row my-2 fee-title">Insurance</div>
                          <div class="fee-wrapper">
                            <div
                              v-if="
                                getBodyForm.booking_status === 4 &&
                                this.$store.state.userData.access_level >= 4
                              "
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 32px;
                              "
                            >
                              <div
                                @click="toggleFee('insurance')"
                                class="checkbox"
                                :class="approver.insurance ? 'enabled' : ''"
                              >
                                &check;
                              </div>
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.insurance"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.insurance_item_value"
                                autocomplete="off"
                              />
                              <label style="top: -5px"
                                >Item Valuation (Rp)</label
                              >
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.insurance"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.insurance_fee"
                                autocomplete="off"
                              />
                              <label style="top: -5px">Amount</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="row mx-1">
                                                <div class="col">
                                                    <div class="row my-2 fee-title">Tax</div>
                                                    <div class="fee-wrapper">
                                                        <div v-if="getBodyForm.booking_status === 4 && this.$store.state.userData.access_level >= 4" style="display: flex; align-items: center; justify-content: center; min-width: 32px">
                                                            <div @click="toggleFee('tax')" class="checkbox" :class="approver.tax ? 'enabled' : ''">&check;</div>
                                                        </div>
                                                        <div class="label-float pt-0 row">
                                                            <input id="fullname" :disabled="!approver.tax" class="fees" type="text" placeholder=" " v-model="approver.tax_value" autocomplete="off" />
                                                            <label style="top: -5px">Tax Information</label>
                                                        </div>
                                                        <div class="label-float pt-0 row">
                                                            <input id="fullname" :disabled="!approver.tax" class="fees" type="text" placeholder=" " v-model="approver.tax_fee" autocomplete="off" />
                                                            <label style="top: -5px">Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                      <div class="row mx-1">
                        <div class="col">
                          <div class="row my-2 fee-title">Repack</div>
                          <div class="fee-wrapper">
                            <div
                              v-if="
                                getBodyForm.booking_status === 4 &&
                                this.$store.state.userData.access_level >= 4
                              "
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 32px;
                              "
                            >
                              <div
                                @click="toggleFee('repack')"
                                class="checkbox"
                                :class="approver.repack ? 'enabled' : ''"
                              >
                                &check;
                              </div>
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.repack"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.repack_value"
                                autocomplete="off"
                              />
                              <label style="top: -5px"
                                >Repack Information</label
                              >
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.repack"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.repack_fee"
                                autocomplete="off"
                              />
                              <label style="top: -5px">Amount</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mx-1">
                        <div class="col">
                          <div class="row my-2 fee-title">Other</div>
                          <div class="fee-wrapper">
                            <div
                              v-if="
                                getBodyForm.booking_status === 4 &&
                                this.$store.state.userData.access_level >= 4
                              "
                              style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 32px;
                              "
                            >
                              <div
                                @click="toggleFee('other')"
                                class="checkbox"
                                :class="approver.other ? 'enabled' : ''"
                              >
                                &check;
                              </div>
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.other"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.other_value"
                                autocomplete="off"
                              />
                              <label style="top: -5px"
                                >Other Information
                              </label>
                            </div>
                            <div class="label-float pt-0 row">
                              <input
                                id="fullname"
                                :disabled="!approver.other"
                                class="fees"
                                type="text"
                                placeholder=" "
                                v-model="approver.other_fee"
                                autocomplete="off"
                              />
                              <label style="top: -5px">Amount</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                id="order"
                class="row mx-auto my-2 mt-3 pt-3 w-75 d-block"
                style="background: #ffffff"
              >
                <div class="col">
                  <h4 class="m-0"><strong>Order Summary</strong></h4>
                  <form-wrapper>
                    <div class="row">
                      <div class="col">
                        <label style="margin: 15px 0; font-weight: 600"
                          >Additional Information</label
                        >
                        <div class="label-float-tall">
                          <textarea
                            v-model="bodyForm.additional_info"
                            style="font-size: 12px; resize: none"
                            rows="4"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                    <div id="details" class="text-start m-3">
                      <div class="row">
                        <div class="col">
                          <div
                            class="row"
                            style="font-weight: 600; font-size: 16px"
                          >
                            Weight and Cost
                          </div>
                          <div class="row">
                            <div class="col">
                              <div
                                class="row my-2"
                                style="opacity: 0.5; font-size: 12px"
                              >
                                Total Bagging(s)
                              </div>
                              <div class="row" style="font-size: 16px">
                                {{ getBodyForm?.quantity }}
                              </div>
                            </div>
                            <div class="col"></div>
                            <div class="col">
                              <div
                                class="row my-2"
                                style="opacity: 0.5; font-size: 12px"
                              >
                                Accounted Weight (kg)
                              </div>
                              <div class="row" style="font-size: 16px">
                                {{
                                  getBodyForm?.total_accounted_weight.toLocaleString() ??
                                  0
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col">
                          <div
                            class="row"
                            style="font-weight: 600; font-size: 16px"
                          >
                            Shipping
                          </div>
                          <div class="row">
                            <div class="col">
                              <div
                                class="row my-2"
                                style="opacity: 0.5; font-size: 12px"
                              >
                                Shipping Type
                              </div>
                              <div class="row" style="font-size: 16px">
                                {{
                                  getBodyForm?.client_rate?.shipping_type?.name
                                }}
                              </div>
                            </div>

                            <div class="col">
                              <div
                                class="row my-2"
                                style="opacity: 0.5; font-size: 12px"
                              >
                                Service
                              </div>
                              <div class="row" style="font-size: 16px">
                                {{ getBodyForm?.client_rate?.service?.name }}
                              </div>
                            </div>
                            <div class="col">
                              <div
                                class="row my-2"
                                style="opacity: 0.5; font-size: 12px"
                              >
                                Rate per kg
                              </div>
                              <div class="row" style="font-size: 16px">
                                <div class="col-1 px-0">Rp</div>
                                <div class="col text-end">
                                  {{
                                    Number(
                                      getBodyForm?.rate.rate
                                    ).toLocaleString("en")
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col px-0">
                              <label style="font-weight: 600"
                                >Payment Method</label
                              >
                              <div class="label-float">
                                <label
                                  :class="
                                    bodyForm.payment_method == ''
                                      ? ''
                                      : 'option-selected'
                                  "
                                >
                                  <span
                                    v-if="bodyForm.payment_method != ''"
                                  ></span>
                                  <span v-else> Select Payment Method</span>
                                </label>
                                <label
                                  v-if="bodyForm.payment_method == ''"
                                  :class="
                                    bodyForm.payment_method == ''
                                      ? ''
                                      : 'option-selected'
                                  "
                                  style="
                                    display: block;
                                    width: 90%;
                                    text-align: right;
                                    opacity: 0.4;
                                  "
                                  ><img
                                    style="transform: scale(0.9)"
                                    src="/icons/all/outline/arrow-down-1.svg"
                                /></label>
                                <select
                                  id="payment"
                                  v-model="bodyForm.payment_method"
                                  placeholder=" "
                                >
                                  <option
                                    v-if="bodyForm.payment_method != ''"
                                    value=""
                                  >
                                    <span v-if="bodyForm.payment_method != ''"
                                      >Select Payment Method</span
                                    >
                                  </option>
                                  >

                                  <option value="TOP">
                                    Terms of Payment (TOP)
                                  </option>
                                  <option value="BANK_TRANSFER">
                                    Bank Transfer
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div
                              @click="
                                OpenPhotoViewer(
                                  getBodyForm.transfer_proof,
                                  `${getBodyForm.booking_number} Transfer Proof`
                                )
                              "
                              class="button"
                            >
                              <span v-if="getBodyForm.transfer_proof"
                                >View Payment / Transfer Proof</span
                              >
                              <span v-else>Payment Required</span>
                            </div>
                          </div>
                          <div
                            class="row mt-5 mb-2"
                            v-if="getBodyForm.booking_status === 3"
                          >
                            <div class="col">
                              <div
                                class="row mt-2"
                                style="font-weight: 600; font-size: 16px"
                              >
                                Estimated Cost
                              </div>
                            </div>

                            <div class="col justify-content-end">
                              <div
                                class="row mt-2 justify-content-end"
                                style="font-weight: 600; font-size: 16px"
                              >
                                Rp
                                {{
                                  getBodyForm?.total_cost.toLocaleString() ?? 0
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 mb-2" v-else>
                            <div class="row mx-0 px-0">
                              <div class="col">
                                <div
                                  class="row my-2"
                                  style="font-weight: 600; font-size: 16px"
                                >
                                  Estimated Cost
                                </div>
                              </div>

                              <div class="col justify-content-end">
                                <div
                                  class="row my-2 justify-content-end"
                                  style="font-weight: 600; font-size: 16px"
                                >
                                  Rp
                                  {{
                                    Number(
                                      getBodyForm.total_cost
                                    ).toLocaleString() ?? 0
                                  }}
                                </div>
                                <!-- <div
                                  v-else
                                  class="row my-2 justify-content-end"
                                  style="font-weight: 600; font-size: 16px"
                                >
                                  Rp
                                  {{
                                    Number(
                                      +getBodyForm.total_cost - +getAddedCost
                                    ).toLocaleString() ?? 0
                                  }}
                                </div> -->
                              </div>
                            </div>

                            <div class="row mx-0 px-0">
                              <div class="col">
                                <div
                                  class="row"
                                  style="font-weight: 400; font-size: 14px"
                                >
                                  Total Additional Fee
                                </div>
                              </div>

                              <div class="col justify-content-end">
                                <div
                                  class="row justify-content-end"
                                  style="font-weight: 400; font-size: 14px"
                                >
                                  + Rp
                                  {{
                                    Number(
                                      calculateAdditional
                                    ).toLocaleString() ?? 0
                                  }}
                                </div>
                              </div>
                            </div>
                            <!-- <div class="row mx-0 px-0">
                              <div class="col">
                                <div
                                  class="row"
                                  style="font-weight: 400; font-size: 14px"
                                >
                                  PPN Logistic
                                </div>
                              </div>

                              <div class="col justify-content-end">
                                <div
                                  class="row justify-content-end"
                                  style="font-weight: 400; font-size: 14px"
                                >
                                  Already Included in Price
                                </div>
                              </div>
                            </div> -->

                            <div
                              v-if="bodyForm.promotion_nominal"
                              class="row mx-0 px-0"
                            >
                              <div class="col">
                                <div
                                  class="row"
                                  style="font-weight: 400; font-size: 14px"
                                >
                                  Promo Code :
                                  {{ bodyForm.promotion_code.toUpperCase() }}
                                </div>
                              </div>

                              <div class="col justify-content-end">
                                <div
                                  class="row justify-content-end"
                                  style="
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #f25b5b !important;
                                  "
                                >
                                  - Rp
                                  {{
                                    Number(
                                      bodyForm.promotion_nominal
                                    ).toLocaleString() ?? 0
                                  }}
                                </div>
                              </div>
                            </div>

                            <div class="row mx-0 px-0">
                              <div class="col">
                                <div
                                  class="row my-2"
                                  style="font-weight: 600; font-size: 16px"
                                >
                                  Total Cost
                                </div>
                              </div>

                              <div class="col justify-content-end">
                                <div
                                  class="row my-2 justify-content-end"
                                  style="font-weight: 600; font-size: 16px"
                                >
                                  Rp
                                  {{
                                    Number(
                                      calculateTotalCostAfterTax
                                    ).toLocaleString() ?? 0
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form-wrapper>
                </div>
              </div>

              <div style="height: 55%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PhotoViewer
      :show="showPhoto"
      :images="imageArray"
      @onClose="closePhotoViewer"
    />
  </div>
</template>

<script>
import TableView from "./TableView.vue";
import $ from "jquery";
import axios from "axios";
import Datepicker from "@vuepic/vue-datepicker";
import { GoogleMap, Marker } from "vue3-google-map";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import PhotoViewer from "@/components/PhotoViewer/PhotoViewer.vue";
/* eslint-disable */

export default {
  name: "UserDetailComponent",
  props: {},
  components: { TableView, Datepicker, GoogleMap, Marker, PhotoViewer },
  data() {
    return {
      showPhoto: false,
      imageArray: null,
      additional: {
        insurance: false,
        additional_info: "",
        payment_method: "",
      },
      actions: false,
      approver: {
        insurance: false,
        repack: false,
        tax: false,
        other: false,
        insurance_item_value: "",
        insurance_fee: 0,
        repack_value: "",
        repack_fee: 0,
        other_value: "",
        other_fee: 0,
        tax_value: "",
        tax_fee: 0,
        total_fee: 0,
        total_cost: 0,
        added_cost: 0,
        booking_status: 2,
        userId: 6,
      },
      dateTime: {
        date: null,
        time: null,
        zone: "WIB",
      },
      gmaps: {
        zoom: 4,
        searchQuery: "",
        center: { lat: 0.4484759, lng: 113.944888 },
        options: {
          panControl: false,
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
        },
        markerOptions: {
          icon: "/icons/location.svg",
        },
        suggestions: [],
        marker: {
          lat: null,
          lng: null,
        },
        selected: {
          area: null,
          formatted_address: "",
          coords: {
            lat: null,
            lng: null,
          },
        },
      },
      pickup_info: {
        address_details: "",
        pic_name: "",
        phone_number: "",
        pickup_time: "",
      },
      baggings: [],
      loadingData: true,
      loadForm: "signin",
      menu: "Bookings",
      submenu: "Details",
      userAction: false,
      currentTab: "bookings",
      edit: false,
      password: {
        new: "",
        conf: "",
      },
      newsales: null,
      currentSales: [],
      newclients: [],
      bodyForm: {
        new_rate: null,
        originId: null,
        destinationId: null,
        shippingTypeId: null,
        providerId: null,
        serviceId: null,
        minimum_weight: null,
        rate: null,
        sla: null,
        status: null,
        user: {
          company: null,
          full_name: null,
        },
        sales_full_name: null,
        origin: {
          city: null,
        },
        destination: {
          city: null,
        },
        shipping_type: {
          name: null,
        },
        provider: {
          name: null,
        },
        service: {
          name: null,
        },
      },
      temp: {
        day: null,
        hour: null,
      },
      originList: [],
      destinationList: [],
      shippingTypeList: [],
      providerList: [],
      serviceList: [],
    };
  },
  watch: {
    approver: {
      deep: true,
      handler(newValue, oldValue) {
        let added_cost = 0;

        if (newValue.insurance) {
          added_cost += +newValue.insurance_fee;
        }
        if (newValue.repack) {
          added_cost += +newValue.repack_fee;
        }
        if (newValue.other) {
          added_cost += +newValue.other_fee;
        }

        newValue.added_cost = +added_cost;
        this.bodyForm.approver = newValue;
      },
    },
    bodyForm: {
      deep: true,
      handler(newValue, oldValue) {
        this.$store.state.app.currentBodyForm = newValue;
      },
    },
  },
  computed: {
    calculateTax() {
      this.approver.tax_value = "PPN Logistic";

      // SET TAX TO ZERO TEMP 31 AUG, ORIGINAL IS 0.011
      this.approver.tax_fee = +this.calculateTotalCost * 0;
      return this.approver.tax_fee;
    },
    calculateAdditional() {
      return (
        this.approver.insurance_fee +
        this.approver.repack_fee +
        this.approver.other_fee
      );
    },
    calculateTotalCost() {
      return (
        this.getBodyForm.total_cost +
        this.calculateAdditional +
        this.getBodyForm.promotion_nominal
      );
    },
    calculateTotalCostAfterTax() {
      return this.calculateTotalCost + this.calculateTax;
    },
    getAddedCost() {
      let added_cost =
        (0 +
          (+this.getBodyForm.insurance_fee || 0) +
          (+this.getBodyForm.tax_fee || 0) +
          (+this.getBodyForm.repack_fee || 0)) |
        (+this.getBodyForm.other_fee || 0);
      return added_cost;
    },
    getCurrentBodyForm() {
      return this.$store.state.app.currentBodyForm;
    },
    showFee() {
      if (
        (this.getBodyForm.booking_status === 3 &&
          this.$store.state.userData.access_level > 3) ||
        this.getBodyForm.booking_status !== 3
      )
        return true;

      return false;
    },
    getPickupDate() {
      let dateTime = { date: null, hr: null, min: null };
      if (this.bodyForm && this.bodyForm.pickup_date)
        dateTime = {
          date: moment(this.bodyForm.pickup_date).format("DD/MM/YYYY"),
          hr: moment(this.bodyForm.pickup_date).format("HH"),
          min: moment(this.bodyForm.pickup_date).format("mm"),
        };

      dateTime.time = {
        hours: dateTime.hr,
        minutes: dateTime.min,
      };

      return dateTime;
    },
    getBodyForm() {
      return this.bodyForm;
    },
    admin() {
      return this.$store.state.app.managerForm.userDetailForm;
    },
    filteredSales() {
      if (this.$store.state.app.managerForm.formSearch == "") {
        this.ascSort();
        return this.$store.state.app.managerForm.filteredSales;
      }

      return this.$store.state.app.managerForm.allSales.filter((client) => {
        return client.full_name
          .toLowerCase()
          .includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
      });
    },
    filteredClients() {
      if (this.$store.state.app.managerForm.formSearch == "") {
        this.ascSort();
        return this.$store.state.app.managerForm.filteredClients;
      }

      return this.$store.state.app.managerForm.allClients.filter((client) => {
        return client.full_name
          .toLowerCase()
          .includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
      });
    },
    getLoadForm() {
      return this.loadForm;
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    adminDetails() {
      return this.getAdminDetails();
    },
    isUserAction() {
      return this.userAction;
    },
  },
  methods: {
    OpenPhotoViewer(photoFile, label) {
      let arrayImage;
      let path, photoLabel;

      path = "avatar";
      photoLabel = label;
      if (photoFile && !photoFile.includes("amazonaws"))
        photoFile = `https://s3-usfc-${path}-image.s3.ap-southeast-1.amazonaws.com/${photoFile}`;

      arrayImage = [
        {
          src: photoFile || this.handleImageError(),
          label: photoLabel,
        },
      ];

      this.imageArray = arrayImage;
      this.showPhoto = true;
    },
    closePhotoViewer() {
      this.showPhoto = false;
      this.imageArray = null;
    },
    approveBooking() {
      let invalid = false;

      if (
        this.approver.insurance &&
        (!this.approver.insurance_fee || !this.approver.insurance_item_value)
      ) {
        invalid = true;
      }
      if (
        this.approver.tax &&
        (!this.approver.tax_fee || !this.approver.tax_value)
      ) {
        invalid = true;
      }
      if (
        this.approver.repack &&
        (!this.approver.repack_fee || !this.approver.repack_value)
      ) {
        invalid = true;
      }
      if (
        this.approver.other &&
        (!this.approver.other_fee || !this.approver.other_value)
      ) {
        invalid = true;
      }

      const currentBody = _.cloneDeep(this.$store.state.app.currentBodyForm);
      currentBody.approver = this.approver;

      this.$store.state.app.currentBodyForm = currentBody;
      if (!invalid) return (this.$store.state.app.openModal = "approveBooking");
      this.$alertSys("Check the Additional Fee names and Amounts", "err");
      return;
    },
    async approveClient() {
      let token = JSON.parse(localStorage.getItem("token"));
      const basepath = this.$store.state.app.apiBasepath;

      axios
        .post(
          basepath + "/booking-receipt/approve-client/" + this.$route.params.id,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          location.reload();
        })
        .catch(() => {
          this.$alertSys("Failed to Approve Booking", "err");
        });
    },
    openApprovalDetails(status) {
      // 1 = Reject
      // 2 = Approve

      if (status === 1) {
        this.$store.state.app.openModal = "rejectionDetail";
      } else {
        this.$store.state.app.openModal = "approveBooking";
      }
    },
    toggleFee(fee) {
      if (fee === "insurance" && this.additional.insurance) return;
      this.approver[fee] = !this.approver[fee];
    },
    scrollTo(id) {
      $(id)[0].scrollIntoView({
        behavior: "smooth", // or "auto" or "instant"
        block: "start", // or "end"
      });

      $(id)[0].classList.add("glow");
      setTimeout(() => {
        $(id)[0].classList.remove("glow");
      }, 2000);
    },
    async getDetail(id) {
      let token = JSON.parse(localStorage.getItem("token"));

      const data = await axios
        .get(
          this.$store.state.app.apiBasepath + "/booking-receipt/details/" + id,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          this.$store.state.app.breadcrumb.currentSubSubMenu =
            res.data.details.booking_number;

          return res.data;
        });
      if (data) {
        this.bodyForm = data.details;
        this.gmaps.marker = {
          lat: +data.details.pickup_latitude,
          lng: +data.details.pickup_longitude,
        };
        this.gmaps.center = {
          lat: +data.details.pickup_latitude,
          lng: +data.details.pickup_longitude,
        };
        this.gmaps.zoom = 16;

        this.baggings = data.bagging;
        this.loadingData = false;
        this.bodyForm.transporter_receipt = data.transporterReceipt;
        this.$store.state.app.currentBodyForm = this.bodyForm;

        if (
          data.details.booking_status === 2 ||
          data.details.booking_status === 3
        ) {
          for (const key in data.details) {
            if (data.details.hasOwnProperty(key)) {
              this.approver[key] = data.details[key];
            }
          }
        }

        this.additional.insurance = this.approver.insurance;
      }
    },
    markerCallback(e) {
      let coords = {
        location: {
          lat: e.lat(),
          lng: e.lng(),
        },
      };
      // this.selectLocation(coords);
    },
    approveRequest() {
      let token = JSON.parse(localStorage.getItem("token"));
      let access_level = this.$store.state.app.managerForm.userDetail;

      axios
        .post(
          this.$store.state.app.apiBasepath +
            "/client-rate/approve-request/" +
            this.$route.params.id,
          this.approver,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          console.log(res.data);
          this.getDetail(this.$route.params.id);
        })
        .catch(() => {
          this.$alertSys("Failed to Approve Change Rate Request", "err");
        });
    },
    rejectRequest() {
      let token = JSON.parse(localStorage.getItem("token"));
      let access_level = this.$store.state.app.managerForm.userDetail;

      axios
        .post(
          this.$store.state.app.apiBasepath +
            "/client-rate/reject-request?request_number=" +
            this.$store.state.app.breadcrumb.currentSubSubMenu,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          console.log(res.data);
          this.getDetail(this.$route.params.id);
        })
        .catch(() => {
          this.$alertSys("Failed to Reject Booking", "err");
        });
    },
    minTime() {
      const h = new Date();
      const dh = new Date(h.getTime() + 180 * 60000);
      return dh;
    },
    minHr() {
      if (this.minTime.getHours() < 9) {
        return 9;
      } else {
        return this.minTime.getHours();
      }
    },
  },
  mounted() {
    const self = this;
    this.getDetail(this.$route.params.id);

    this.dateTime.date = new Date();
    this.dateTime.time = {
      hours: this.minTime().getHours(),
      minutes: Math.round(this.minTime().getMinutes() / 10) * 10,
    };
    setTimeout(() => {
      const shipperNav = document.querySelector("#shipper_nav");
      const recipientNav = document.querySelector("#recipient_nav");
      const shippingNav = document.querySelector("#shipping_nav");
      const pickupNav = document.querySelector("#pickup_nav");
      const baggingNav = document.querySelector("#bagging_info");
      const feeNav = document.querySelector("#fee_nav");
      const orderNav = document.querySelector("#order_summary");

      function isElementVisible(elem) {
        const rect = elem.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const windowWidth =
          window.innerWidth || document.documentElement.clientWidth;

        // Check if the element is in the viewport
        const vertInView =
          rect.top <= windowHeight && rect.top + rect.height >= 0;
        const horInView =
          rect.left <= windowWidth && rect.left + rect.width >= 0;

        // Return true if the element is in the viewport, otherwise false
        return vertInView && horInView;
      }

      // Define a function to check if an element is in the top half of the viewport
      function isElementInTopHalf(elem) {
        const rect = elem.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        return rect.top >= 0 && rect.top <= windowHeight / 2;
      }

      // Define a function to update the class of the navigation buttons
      let currentNav = null;
      function updateNavClass() {
        let isActive = false;
        if (document.querySelector("#shipper")) {
          if (
            isElementVisible(document.querySelector("#shipper")) &&
            isElementInTopHalf(document.querySelector("#shipper"))
          ) {
            if (currentNav !== shipperNav) {
              removeActiveClass();
              shipperNav.classList.add("active");
              currentNav = shipperNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#recipient")) {
          if (
            isElementVisible(document.querySelector("#recipient")) &&
            isElementInTopHalf(document.querySelector("#recipient"))
          ) {
            if (currentNav !== recipientNav) {
              removeActiveClass();
              recipientNav.classList.add("active");
              currentNav = recipientNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#shipping")) {
          if (
            isElementVisible(document.querySelector("#shipping")) &&
            isElementInTopHalf(document.querySelector("#shipping"))
          ) {
            if (currentNav !== shippingNav) {
              removeActiveClass();
              shippingNav.classList.add("active");
              currentNav = shippingNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#pickup")) {
          if (
            isElementVisible(document.querySelector("#pickup")) &&
            isElementInTopHalf(document.querySelector("#pickup"))
          ) {
            if (currentNav !== pickupNav) {
              removeActiveClass();
              pickupNav.classList.add("active");
              currentNav = pickupNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#bagging")) {
          if (
            isElementVisible(document.querySelector("#bagging")) &&
            isElementInTopHalf(document.querySelector("#bagging"))
          ) {
            if (currentNav !== baggingNav) {
              removeActiveClass();
              baggingNav.classList.add("active");
              currentNav = baggingNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#fee")) {
          if (
            isElementVisible(document.querySelector("#fee")) &&
            isElementInTopHalf(document.querySelector("#fee"))
          ) {
            if (currentNav !== feeNav) {
              removeActiveClass();
              feeNav.classList.add("active");
              currentNav = feeNav;
            }
            isActive = true;
          }
        }

        if (document.querySelector("#order")) {
          if (
            isElementVisible(document.querySelector("#order")) &&
            isElementInTopHalf(document.querySelector("#order"))
          ) {
            if (currentNav !== orderNav) {
              removeActiveClass();
              self.actions = true;
              orderNav.classList.add("active");
              currentNav = orderNav;
            }
            isActive = true;
          }
        }

        // If no nav button is active, set the first one to be active
        if (!isActive && currentNav === null) {
          shipperNav.classList.add("active");
          currentNav = shipperNav;
        }
      }

      function removeActiveClass() {
        if (currentNav) {
          currentNav.classList.remove("active");
          currentNav = null;
        }
      }

      // Call the updateNavClass function when the window is scrolled or resized
      setTimeout(() => {
        document
          .getElementById("scrollable")
          .addEventListener("scroll", updateNavClass);
        document
          .getElementById("scrollable")
          .addEventListener("resize", updateNavClass);
      }, 100);

      $("input").prop("disabled", true);
      $("textarea").prop("disabled", true);
      $("select").prop("disabled", true);
      shipperNav.classList.add("active");

      // this.approveBooking();
    }, 1000);

    $("#scrollable").animate(
      {
        scrollTop: 0,
      },
      400,
      "swing"
    );
    // this.getAllSales();

    // this.getAllClients();

    // setTimeout(() => {
    //   if (this.admin.access_level !== 2) {
    //     this.currentTab = "details";
    //   }
    // }, 250);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.button {
  background-color: #3699ff;
  color: #fff;
  text-align: center;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
}
.glow {
  animation: fadeGlow 1s;
}

@keyframes fadeGlow {
  0% {
    filter: brightness(100%);
  }
  25% {
    filter: brightness(80%);
  }
  50% {
    filter: brightness(100%);
  }
  75% {
    filter: brightness(80%);
  }
  100% {
    filter: brightness(100%);
  }
}
.fee-title {
  display: flex;
  justify-content: center;
  width: 19%;
  text-align: center;
}
.fee-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-around;
  gap: 15px;

  .label-float:first-child {
    width: 55%;
  }
  .label-float:last-child {
    width: 30%;
  }

  .checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #2c3e50;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
    color: white;
    user-select: none;

    transition: all 0.1s ease;

    &.enabled {
      background-color: #3699ff;
    }
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
}
.ongoing-feature {
  filter: brightness(0.5);
  &::before {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: white;
    font-weight: 900;
    content: "UNFINISHED FEATURE";
    top: -50%;
  }
}

.scroll-container {
  overflow-y: scroll;
  height: 78vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.deleteaccount {
  transition: all 0.3s ease;
  font-size: 14px;
  cursor: pointer;
}

span.text-danger:hover {
  text-decoration: underline;
  cursor: pointer;
}

.deleteaccount:hover {
  text-decoration: underline;
}

#forms {
  overflow-y: scroll;
  border: 1px solid transparent;
}

#forms.company {
  height: 100%;
}

#userDetails {
  width: 550px;
  padding: 0;
}

#closeModal {
  float: right;
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}

#closeModal:hover {
  border: 1px solid black;
}

#navigation {
  position: relative;
  bottom: 0;
  right: 0;
}

#stages > .col-auto {
  opacity: 0.5;
}

#stages > .col-auto.active {
  opacity: 1;
}

#pictureImg {
  cursor: pointer;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#pictureImgProfile {
  cursor: pointer;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#pictureImgBtn {
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #3699ff;

  position: relative;
  bottom: 25px;
  left: 75px;
}

#pictureImgBtn > #pictureBtn {
  transform: scale(1.2);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(103%) contrast(103%);
}

#profilePicture {
  height: 20%;
}

#stages {
  overflow-x: scroll;
}
#forms::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.blur-overlay {
  display: block;
  filter: blur(3px) brightness(90%);
  -webkit-filter: blur(3px) brightness(90%);
  -moz-filter: blur(3px) brightness(90%);
  -o-filter: blur(3px) brightness(90%);
  -ms-filter: blur(3px) brightness(90%);
  pointer-events: none;
}

.spacer {
  min-width: 250px;
  width: 20vw;
}

/* VUE TRANSITION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.childform {
  padding-left: 15px;
  line-height: 40px;
  border-radius: 6px;
  min-width: 150px;
  width: 90%;
  cursor: pointer;
}

.childform:hover {
  background-color: rgba(0, 0, 0, 0.15);
  filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg)
    brightness(103%) contrast(103%);
}

.childform.active {
  background-color: rgba(0, 0, 0, 0.15);
  filter: invert(67%) sepia(81%) saturate(5021%) hue-rotate(193deg)
    brightness(103%) contrast(103%);
}

/* css  */
myroute {
  cursor: pointer;
}

.btn-back {
  color: #3699ff;
  outline: 1px solid #3699ff;
}

.btn-back:hover {
  color: #3699ff;
  outline: 1px solid #3699ff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
}

.usfc-bg {
  background-color: #fbfbfb;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input[type="password"] {
  font-family: Verdana;
  letter-spacing: 0.25em;
  font-weight: 900;
}

.toggle-password,
.toggle-password-confirm {
  cursor: pointer;
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 75px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
          border: solid 1px rgba(54, 153, 255, 0.25);
        } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  z-index: 999;
  position: absolute;
  width: auto;
  right: 20px;
  top: 15vh;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 6px;
}

ul.user-button li {
  position: relative;
  padding: 10px 0;
  width: 200px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 2em;
}

ul.user-button {
  position: absolute;
  top: 75px;
  right: 0;
  padding-left: 0;
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

a {
  color: inherit;
  text-decoration: none;
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  border: 1px solid transparent;
}

input:disabled {
  color: rgba(0, 0, 0, 0.4);
}
select:disabled > option {
  color: rgba(0, 0, 0, 0.4);
}

select {
  width: 100%;
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}
.btn.btn-usfc-outline {
  color: #3699ff;
  background: white;
  border: 1px solid #3699ff;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.btn.btn-usfc-edit {
  min-width: 120px;
  color: #3699ff;
  background-color: white;
  border-radius: 6px;
  padding: 10px 25px;
  border: 1px solid #3699ff;
}

.btn-usfc-edit:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  // min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}

/* FA ICONS CUSTOM */
.fa-check {
  margin: 0 5px;
  position: relative;
  top: -2px;
  font-size: 12px;
  color: white;
  border-radius: 50%;
  padding: 3px;
  background-color: #3699ff;
}

/* * {
      border: 1px solid red;
    } */
</style>

<template>
  <div class="container">
    <div id="modalTitle" class="row w-100">
      <div class="col p-2">
        <h5>
          <span class="p-2" style="font-weight: 700; float: left"
            >Create Client</span
          >
          <span @click="this.$store.state.app.openModal = ''" id="closeModal"
            ><img
              class="d-flex justify-content-center"
              src="/icons/all/outline/add.svg"
          /></span>
        </h5>
      </div>
    </div>
    <div id="stages" class="row d-flex flex-nowrap mx-2">
      <template v-for="stage in stages" v-bind:key="stage.name">
        <span
          class="tracker"
          style="opacity: 0; width: 0; transition: all 0.4s ease"
          :style="stage.status == 2 ? 'width: 32px; opacity: 1;' : ''"
        >
          <Transition name="fade" v-if="stage.status == 2">
            <i class="fa fa-check" aria-hidden="true"></i>
          </Transition>
        </span>
        <div
          style="transition: all 0.4s ease"
          :class="stage.status == 1 ? 'active' : ''"
          class="col-auto"
        >
          {{ stage.name }}
        </div>
      </template>
      <div class="col-5">
        <span class="tracker"> </span>
      </div>
    </div>
    <form-wrapper v-if="stageIndex == 1" class="w-100">
      <div v-if="!subForm.id" class="pic-accounts">
        <div class="account-grid">
          <div
            class="grid-item"
            @click.stop="subForm.id = 'pic-subForm'"
            v-if="this.clients.length < 1"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
              >
                <path
                  d="M6.60785 6.83347C7.00438 6.83347 7.39704 6.75803 7.76339 6.61145C8.12975 6.46487 8.46262 6.25002 8.74302 5.97918C9.02341 5.70833 9.24583 5.3868 9.39758 5.03292C9.54933 4.67905 9.62743 4.29977 9.62743 3.91673C9.62743 3.5337 9.54933 3.15442 9.39758 2.80055C9.24583 2.44667 9.02341 2.12514 8.74302 1.85429C8.46262 1.58345 8.12975 1.3686 7.76339 1.22202C7.39704 1.07544 7.00438 1 6.60785 1C5.807 1 5.03896 1.3073 4.47267 1.85429C3.90639 2.40129 3.58826 3.14317 3.58826 3.91673C3.58826 4.6903 3.90639 5.43218 4.47267 5.97918C5.03896 6.52617 5.807 6.83347 6.60785 6.83347Z"
                  stroke="#59AAFF"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9411 10.582V15.5821M11.3529 13.0821H16.5293"
                  stroke="#59AAFF"
                />
                <path
                  d="M13.9411 10.5827V15.5828M11.3529 13.0828H16.5293M10.0588 10.166H6.52153C4.589 10.166 3.62273 10.166 2.88422 10.5294C2.23491 10.8489 1.707 11.3589 1.37615 11.9861C1 12.6994 1 13.6328 1 15.4995V15.9995H10.0588"
                  stroke="#59AAFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>Add Profile</div>
          </div>
          <div
            v-for="(client, index) in clients"
            :key="index"
            class="grid-item"
            @click.stop="editPic(index)"
          >
            <div>Client Name: {{ client.full_name }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="subForm.id === 'pic-subForm'"
        id="forms"
        class="row text-start d-flex flex-nowrap company"
        style="height: 500px; overflow-y: scroll"
      >
        <form>
          <div class="label-float">
            <input
              v-model="bodyForm.full_name"
              id="fullname"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Full Name</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.email"
              id="email"
              type="email"
              placeholder=" "
              autocomplete="off"
            />
            <label>Email</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.username"
              id="username"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Username</label>
          </div>
          <div class="label-float">
            <label :class="bodyForm.gender == '' ? '' : 'option-selected'"
              >Gender</label
            >
            <label
              v-if="bodyForm.gender == ''"
              :class="bodyForm.gender == '' ? '' : 'option-selected'"
              style="
                display: block;
                width: 90%;
                text-align: right;
                opacity: 0.4;
              "
              ><img
                style="transform: scale(0.9)"
                src="/icons/all/outline/arrow-down-1.svg"
            /></label>
            <select v-model="bodyForm.gender" placeholder=" ">
              <option disabled></option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.nationality"
              id="nationality"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Nationality</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.birth_date"
              id="birthdate"
              min="1950/01/01"
              max="2010/01/01"
              type="date"
              placeholder=" "
              autocomplete="off"
            />
            <label>Birthdate</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.phone"
              id="phone"
              type="tel"
              placeholder=" "
              autocomplete="off"
            />
            <label>Phone Number</label>
          </div>
        </form>
      </div>
    </form-wrapper>

    <form-wrapper v-if="stageIndex == 0" class="w-100">
      <div id="profilePicture" class="row mx-auto mt-2 mb-4">
        <div class="col-auto mx-auto">
          <input
            @change="showPreview()"
            type="file"
            accept="image/jpeg"
            id="pict"
            hidden
          />
          <img
            @click="changePic()"
            id="pictureImg"
            src="/icons/default_company.svg"
          />
          <span
            @click="changePic()"
            id="pictureImgBtn"
            class="p-2 d-flex justify-content-center"
          >
            <img id="pictureBtn" src="/icons/all/outline/edit-2.svg"
          /></span>
        </div>
      </div>
      <div
        id="forms"
        class="row text-start d-flex flex-nowrap profile"
        style="overflow-y: scroll"
      >
        <form>
          <div class="label-float">
            <input
              v-model="bodyForm.company"
              id="fullname"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Company Name</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.street"
              id="email"
              type="email"
              placeholder=" "
              autocomplete="off"
            />
            <label>Address</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.province"
              id="username"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Province</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.city"
              id="nationality"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>City</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.district"
              id="nationality"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>District</label>
          </div>
          <div class="label-float">
            <input
              v-model="bodyForm.postal_code"
              id="birthdate"
              type="text"
              placeholder=" "
              autocomplete="off"
            />
            <label>Postal Code</label>
          </div>
        </form>
      </div>
    </form-wrapper>

    <form-wrapper v-if="stageIndex == 2" class="w-100">
      <div id="forms" class="row text-start">
        <form>
          <div class="label-float">
            <input
              v-model="bodyForm.password"
              id="pwd"
              type="password"
              placeholder=" "
              autocomplete="off"
            />
            <label>New password</label>
            <span
              style="float: right; position: relative; top: -40px; left: -10px"
              @click="togglePass()"
              toggle="#password-field"
              class="bi bi-eye-slash-fill toggle-password"
            ></span>
          </div>
          <br />
          <div class="label-float" style="top: -20px">
            <input
              v-model="pwdconf"
              id="pwdconf"
              type="password"
              placeholder=" "
              autocomplete="off"
            />
            <label>Re-enter new password</label>
            <span
              style="float: right; position: relative; top: -40px; left: -10px"
              @click="togglePassConfirm()"
              toggle="#password-field"
              class="bi bi-eye-slash-fill toggle-password-confirm"
            ></span>
          </div>
          <span style="font-size: 12px; opacity: 0.75"
            >Password must contain at least eight characters, at least one
            number and both lower and uppercase letters, and special characters
            (!@#$%^&*)</span
          >
        </form>
      </div>
    </form-wrapper>
    <form-wrapper v-if="stageIndex == 3" class="w-100">
      <div id="forms" class="row text-start">
        <form>
          <!-- <div class="label-float">
            <div class="d-inline">
              <input
                v-model="this.$store.state.app.managerForm.formSearch"
                class="searchbar fa"
                type="text"
                style="
                  padding: 1% 17% 1% 1% !important;
                  font-family: Open Sans, FontAwesome;
                "
                placeholder="&#xF002;  search.. "
              />
            </div>
          </div> -->

          <!-- <select v-model="selectedPromotorId">
            <option value="" disabled>Select a Sales</option>
            <option
              v-for="promotor in sales"
              :key="promotor.id"
              :value="promotor.id"
            >
              {{ promotor.full_name }}
            </option>
          </select> -->

          <div
            v-for="promotor in sales"
            :key="promotor.id"
            :value="promotor.id"
          >
            <div
              class="sales-container"
              @click="chooseSales(promotor.id, promotor.full_name)"
              :class="bodyForm.salesId == promotor.id ? 'selected-sales' : ''"
            >
              {{ promotor.full_name }}
            </div>
          </div>
          <div>Sales Choosed: {{ bodyForm.sales_name }}</div>
          <!-- <div class="px-3 py-0 my-0 prevent-select">
            <table
              class="table"
              v-if="this.$store.state.app.managerForm.formSearch != ''"
            >
              <tbody>
                <tr
                  @click="chooseSales(sales.id)"
                  class="sales"
                  v-for="sales in this.$store.state.app.managerForm
                    .filteredSales"
                  v-bind:key="sales.id"
                >
                  <th scope="row" class="col-2">
                    <img
                      src="/icons/default_company.svg"
                      style="
                        object-fit: cover;
                        height: 42px;
                        border-radius: 50%;
                        width: 42px;
                      "
                    />
                  </th>
                  <td class="col text-start">
                    <span style="line-height: 2.5em">{{
                      sales.full_name
                    }}</span>
                  </td>
                  <td
                    v-if="this.bodyForm.salesId == sales.id"
                    scope="row"
                    style="position: relative; top: 5px"
                  >
                    <span style="float: right">
                      <img src="/icons/custom/picker.svg" />
                    </span>
                  </td>
                  <td v-else scope="row" style="position: relative; top: 5px">
                    <span style="float: right">
                      <img src="/icons/custom/picker-deselect.svg" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center"
              v-else
              style="font-size: 14px; opacity: 0.4"
            >
              Type the sales name above
            </div>
          </div> -->
        </form>
      </div>
    </form-wrapper>
    <div id="navigation" class="row my-2 p-0 px-0 w-100">
      <div class="col">
        <span
          v-if="stages.length == stageIndex + 1"
          @click="register()"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
          >Create Client</span
        >
        <button
          :disabled="
            (bodyForm.password != pwdconf || bodyForm.password < 8) &&
            stages[2].status == 1
          "
          v-else
          @click="nextStage()"
          style="float: right"
          class="mx-2 btn btn-usfc-primary"
        >
          Next
        </button>
        <span
          @click="this.$store.state.app.openModal = ''"
          v-if="stageIndex == 0"
          style="float: right"
          class="mx-2 btn btn-cancel"
        >
          Cancel</span
        >
        <span
          @click="prevStage()"
          v-else
          style="float: right"
          class="mx-2 btn btn-back"
        >
          Back</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.js";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import $ from "jquery";
import axios from "axios";

export default {
  name: "adminCreate",
  components: {},
  computed: {
    filteredSales() {
      // Jika formSearch kosong, urutkan secara ascending dan kembalikan filteredSales
      if (this.$store.state.app.managerForm.formSearch === "") {
        this.ascSort(); // Pastikan ascSort() tidak mempengaruhi data yang diperlukan
        return this.$store.state.app.managerForm.filteredSales;
      }

      // Filter allSales berdasarkan formSearch
      return this.$store.state.app.managerForm.allSales.filter((client) => {
        return client.full_name
          .toLowerCase()
          .includes(this.$store.state.app.managerForm.formSearch.toLowerCase());
      });
    },
    currentRouteName() {
      return this.$route.name;
    },
    userFullName() {
      if (this.$store.state.userData.full_name.length > 30) {
        return this.$store.state.userData.full_name.substring(0, 28) + "...";
      } else {
        return this.$store.state.app.userData.full_name;
      }
    },
    role() {
      if (this.$store.state.userData.access_level == 5) {
        return "Manager";
      } else if (this.$store.state.userData.access_level == 4) {
        return "Admin";
      } else if (this.$store.state.userData.access_level == 3) {
        return "Sales";
      } else if (this.$store.state.userData.access_level == 2) {
        return "Client";
      } else {
        return "Transporter";
      }
    },
  },
  data() {
    return {
      photo64: "",
      subForm: {
        id: false,
        mode: null,
      },
      sales: [],
      photoFile: "",
      access_level: 2,
      stageIndex: 0,
      pwdconf: "",
      stages: [
        // status (0: upcoming, 1: active, 2: done)
        {
          name: "Fill Company Details",
          status: 1,
        },
        {
          name: "Fill Personal Profile",
          status: 0,
        },
        {
          name: "Create Password",
          status: 0,
        },
        {
          name: "Choose Sales",
          status: 0,
        },
      ],
      bodyForm: {
        full_name: "",
        email: "",
        access_level: 2,
        username: "",
        phone: "",
        password: "",
        birth_date: "",
        nationality: "",
        gender: "",
        photo: "",
        company: "",
        street: "",
        district: "",
        province: "",
        city: "",
        postal_code: "",
        salesId: null,
      },
      clients: [],

      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
    };
  },
  methods: {
    chooseSales(id, name) {
      console.log(id, name);
      if (this.bodyForm.salesId == id) {
        this.bodyForm.salesId = null;
        this.bodyForm.sales_name = name;
      } else {
        this.bodyForm.salesId = id;
        this.bodyForm.sales_name = name;
        this.bodyForm.assigned_sales_date = moment().format("YYYY-MM-DD");
      }
    },
    ascSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allSales.sort(compare);
    },
    getAllSales() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/user/all-sales", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          this.$store.state.app.managerForm.allSales = res.data.rows;
          this.sales = res.data.rows;
          this.ascSort();
        })
        .catch((err) => {
          console.log("500: " + err);
        });
      console.log(this.$store.state.app.managerForm.allSales);
    },
    changePic() {
      $("#pict").click();
    },

    showPreview() {
      let pic = document.getElementById("pict");
      if (pic.files.length > 0) {
        var src = URL.createObjectURL(pic.files[0]);
        var preview = document.getElementById("pictureImg");
        preview.src = src;
      }
    },
    togglePass() {
      $(".toggle-password").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwd");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    next() {
      console.log(this.bodyForm);
    },
    togglePassConfirm() {
      $(".toggle-password-confirm").toggleClass("bi-eye bi-eye-slash-fill");
      var input = $("#pwdconf");
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    },
    async nextStage() {
      //   console.log(
      //     this.stages[this.stageIndex].name,
      //     this.stages[this.stageIndex].status
      //   );
      console.log(this.bodyForm);
      if (this.subForm.id) {
        if (this.subForm.id === "pic-subForm") {
          const client = {
            full_name: this.bodyForm.full_name,
            email: this.bodyForm.email,
            access_level: 2,
            username: this.bodyForm.username,
            phone: this.bodyForm.phone,
            password: this.bodyForm.password,
            birth_date: this.bodyForm.birth_date,
            nationality: this.bodyForm.nationality,
            gender: this.bodyForm.gender,
            assigned_sales_date: moment().format("YYYY-MM-DD"),
          };

          let token = JSON.parse(localStorage.getItem("token"));

          try {
            const response = await axios.post(
              this.$store.state.app.apiBasepath + "/user/validate-pic",
              client,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              this.$alertSys("Account Available", "success");
              if (this.subForm.mode === null) {
                this.clients.push(client);
                console.log(this.bodyForm);
                this.subForm.id = false;
              }

              //   this.bodyForm.full_name = "";
              //   this.bodyForm.email = "";
              //   this.bodyForm.access_level = 2;
              //   this.bodyForm.username = "";
              //   this.bodyForm.phone = "";
              //   this.bodyForm.password = "";
              //   this.bodyForm.birth_date = "";
              //   this.bodyForm.nationality = "";
              //   this.bodyForm.gender = "";
              //   this.pwdconf = "";
              //   this.subForm.id = false;
              //   this.subForm.mode = null;
            }
          } catch (error) {
            this.$alertSys("Account Already Exist", "err");
          }
        }
        return;
      }
      if (document.getElementById("pict")) {
        if (document.getElementById("pict").files.length > 0) {
          this.photoFile = document.getElementById("pict");
        } else {
          this.photoFile = null;
        }
      }

      this.stages[this.stageIndex].status = 2;
      this.stages[this.stageIndex + 1].status = 1;
      $("#stages").animate({ scrollLeft: "+=100" }, 600);
      this.stageIndex++;
      console.log(
        "ini udah di next",
        this.stages[this.stageIndex].name,
        this.stages[this.stageIndex].status,
        this.stages
      );
    },
    prevStage() {
      this.stages[this.stageIndex].status = 0;
      this.stages[this.stageIndex - 1].status = 1;
      $("#stages").animate({ scrollLeft: "-=100" }, 600);
      this.stageIndex--;
    },
    photoInput() {
      $("#photoInput").click();
    },
    randomizeName() {
      return this.$CryptoJS.AES.encrypt(Date(), "@Macra2021")
        .toString()
        .substring(0, 16);
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
    currentMenu() {
      if (
        ["Publish Rates", "Client Rates", "Request Change"].includes(
          this.$route.name
        )
      ) {
        return "Rates";
      }

      if (["Users", "References"].includes(this.$route.name)) {
        return "Manages";
      }

      if (["Receipt", "SLA"].includes(this.$route.name)) {
        return "Reports";
      } else {
        return this.$route.name;
      }
    },
    currentSubmenu() {
      let menu = this.currentMenu();
      if (["Dashboard", "Receipts"].includes(menu)) {
        return "";
      } else {
        return this.$route.name;
      }
    },
    getMenu(val) {
      this.menu = val;
    },
    getSub(val) {
      this.submenu = val;
    },
    toggleUserAction() {
      this.userAction = !this.userAction;
    },
    setFocusUser(ele) {
      $(ele.target).focus();
      console.log(ele.target);
    },
    keepLogin(getToken) {
      axios
        .post(
          this.$store.state.app.apiBasepath + "/user/keep-login",
          {},
          { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } }
        )
        .then((response) => {
          localStorage.setItem("token", JSON.stringify(response.data.token));

          let userData = response.data.keepLogin;
          this.$store.state.userData = userData;
        })
        .catch(() => {
          if (!this.$route.query.wv) this.$router.push("/signin");
        });
    },
    encode64() {
      async function listarchivos(fileInput) {
        var base64; //in this variable i need the base64
        var fileToLoad = fileInput;
        base64 = await getBase64(fileToLoad).then((data) => {
          return data;
        });
        console.log(base64);
        return base64;
      }

      //This is my function for get base64, but not return the string base64
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          return Promise.resolve(reader.result);
        });
      }

      let result = listarchivos(this.photoFile);
      return result;
    },
    register() {
      let photoFile = this.photoFile;
      // return console.log(photoFile.files[0])
      let fileName = "";
      if (photoFile) {
        if (photoFile.files.length > 0) {
          fileName =
            this.randomizeName() + "." + this.photoFile.value.split(".").pop();
          this.bodyForm.photo = fileName;
        }
      } else {
        fileName = null;
      }

      console.log(photoFile);
      let token = JSON.parse(localStorage.getItem("token"));
      this.bodyForm.photo = fileName;
      // return console.log(photoFile);
      if (photoFile != null) {
        axios
          .get(
            " https://dmmrx30km1.execute-api.ap-southeast-1.amazonaws.com/avatar-image-get-presigned-url?fileName=" +
              fileName,
            { headers: { "Access-Control-Allow-Origin": "*" } }
          )
          .then((res) => {
            axios({
              method: "PUT",
              url: res.data.fileUploadURL,
              data: this.photoFile.files[0],
              headers: {
                "Content-Type": "image/jpeg",
              },
            }).then(() => {
              const body = {
                name: this.bodyForm.company,
                street: this.bodyForm.street,
                district: this.bodyForm.district,
                province: this.bodyForm.province,
                city: this.bodyForm.city,
                postal_code: this.bodyForm.postal_code,
                fullname: this.bodyForm.full_name,
                email: this.bodyForm.email,
                phone: this.bodyForm.phone,
                username: this.bodyForm.username,
                password: this.bodyForm.password,
                gender: this.bodyForm.gender,
                nationality: this.bodyForm.nationality,
                birth_date: this.bodyForm.birth_date,
                photo: this.bodyForm.photo,
              };
              axios
                .post(
                  this.$store.state.app.apiBasepath + "/user/register",
                  body,
                  { headers: { Authorization: `Bearer ${token}` } }
                )
                .then(() => {
                  this.$store.state.app.managerForm.currentTab = "";
                  this.$alertSys("New Client successfully added!", "success");

                  setTimeout(() => {
                    this.$store.state.app.managerForm.currentTab = "clients";
                    this.$store.state.app.openModal = "";
                  }, 250);
                })
                .catch(() => {
                  this.$alertSys("An error occured", "err");
                });
            });
          })
          .catch((err) => {
            err.response.data.error.errors[0].message;
          });
      } else {
        console.log(this.bodyForm);
        const body = {
          name: this.bodyForm.company,
          street: this.bodyForm.street,
          district: this.bodyForm.district,
          province: this.bodyForm.province,
          city: this.bodyForm.city,
          postal_code: this.bodyForm.postal_code,
          fullname: this.bodyForm.full_name,
          email: this.bodyForm.email,
          phone: this.bodyForm.phone,
          username: this.bodyForm.username,
          password: this.bodyForm.password,
          gender: this.bodyForm.gender,
          nationality: this.bodyForm.nationality,
          birth_date: this.bodyForm.birth_date,
          photo: this.bodyForm.photo,
        };
        axios
          .post(
            this.$store.state.app.apiBasepath + "/user/register-public",
            body,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(() => {
            this.$store.state.app.managerForm.currentTab = "";
            this.$alertSys("New Client successfully added!", "success");

            setTimeout(() => {
              this.$store.state.app.managerForm.currentTab = "clients";
              this.$store.state.app.openModal = "";
            }, 250);
          })
          .catch(() => {
            this.$alertSys("An error occured", "err");
          });
      }
    },
    ascSortClient() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
      }

      this.page = 1;
      this.sortType = "ASC";
      this.$store.state.app.managerForm.allClients.sort(compare);
      this.paginate();
    },
    descSort() {
      function compare(a, b) {
        if (a.full_name < b.full_name) return 1;
        if (a.full_name > b.full_name) return -1;
      }

      this.page = 1;
      this.sortType = "DESC";
      this.$store.state.app.managerForm.allClients.sort(compare);
      this.paginate();
    },
    getAllAdmins() {
      let token = JSON.parse(localStorage.getItem("token"));

      axios
        .get(this.$store.state.app.apiBasepath + "/user/all-client", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log("200: " + res);
          this.$store.state.app.managerForm.allClients =
            res.data.AllClient.rows;
          this.ascSortClient();
        })
        .catch(() => {
          console.log("500: " + token);
        });
    },
    paginate() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      for (let i = 0; i < this.totalPages; i++) {
        this.$store.state.app.managerForm.filteredClients[i] = JSON.parse(
          JSON.stringify(
            this.$store.state.app.managerForm.allClients.slice(
              i * 10,
              (i + 1) * 10
            )
          )
        );
      }
    },
  },

  mounted() {
    console.log(this.stages[this.stageIndex].name);
    this.getAllSales();
  },
};
</script>

<style scoped>
.selected-sales {
  background-color: #3699ff;
  color: white;
  font-weight: bold;
}
.sales-container {
  padding: 10px;
  border: 1px solid #3699ff;
  border-radius: 6px;
  margin: 5px;
  cursor: pointer;
}
#forms::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

#forms.profile {
  max-height: 45vh;
}
#forms.company {
  height: auto;
}

#closeModal {
  float: right;
  transform: rotate(45deg);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid transparent;
}

#closeModal:hover {
  border: 1px solid black;
}

#navigation {
  margin-top: 22px;
  position: relative;
  bottom: 0;
  right: 0;
}
#stages > .col-auto {
  opacity: 0.5;
}

#stages > .col-auto.active {
  opacity: 1;
}

#pictureImg {
  cursor: pointer;
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

#pictureImgBtn {
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #3699ff;

  position: absolute;
  top: 210px;
  left: 60%;
}

#pictureImgBtn > #pictureBtn {
  transform: scale(1.2);
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(103%) contrast(103%);
}

#profilePicture {
  height: 20%;
}

#stages {
  overflow-x: scroll;
}
#stages::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.blur-overlay {
  display: block;
  filter: blur(3px) brightness(90%);
  -webkit-filter: blur(3px) brightness(90%);
  -moz-filter: blur(3px) brightness(90%);
  -o-filter: blur(3px) brightness(90%);
  -ms-filter: blur(3px) brightness(90%);
  pointer-events: none;
}

.spacer {
  min-width: 250px;
  width: 20vw;
}

/* VUE TRANSITION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* css  */
myroute {
  cursor: pointer;
}

.sales {
  cursor: pointer;
}

.sales:hover {
  outline: 1px solid #3699ff;
}

.btn-back {
  color: #3699ff;
  outline: 1px solid #3699ff;
}

.btn-back:hover {
  color: #3699ff;
  outline: 1px solid #3699ff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
}

.usfc-bg {
  background-color: #fbfbfb;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input[type="password"] {
  font-family: Verdana;
  letter-spacing: 0.25em;
  font-weight: 900;
}

.toggle-password,
.toggle-password-confirm {
  cursor: pointer;
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 75px;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
        border: solid 1px rgba(54, 153, 255, 0.25);
      } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  z-index: 999;
  position: absolute;
  width: auto;
  right: 20px;
  top: 15vh;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 6px;
}

ul.user-button li {
  position: relative;
  padding: 10px 0;
  width: 200px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 2em;
}

ul.user-button {
  position: absolute;
  top: 75px;
  right: 0;
  padding-left: 0;
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

a {
  color: inherit;
  text-decoration: none;
}

/* INPUT FORM LABEL STYLE */
.label-float {
  position: relative;
  padding-top: 13px;
}

input {
  width: 100%;
  padding: 2px 0;
  border: 1px solid transparent;
}

select {
  width: 100%;
  border: 1px solid transparent;
}

.btn {
  font-size: small;
  min-width: 90px;

  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel {
  border-radius: 6px;
  padding: 10px 25px;
}

.btn.btn-cancel:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.btn.btn-cancel:active {
  border: 1px solid rgba(0, 0, 0, 1);
}

.btn.btn-usfc-primary {
  background-color: #3699ff;
  color: white;
  border-radius: 6px;
  padding: 10px 25px;
}

.btn-usfc-primary:hover {
  background-color: #3699ff;
  color: white;
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
}

.label-float input {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float select {
  border: none;
  background-color: whitesmoke;
  border-radius: 5px;
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
  top: 10px;
}

.label-float input:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float select:focus {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.label-float input::placeholder {
  color: black;
}

.label-float select::placeholder {
  color: black;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 10px;
  color: #3951b2;
  opacity: 1;
  background-color: transparent;
}

.label-float select:required:invalid + label {
  color: red;
}
.label-float select:focus:required:invalid {
  border: 2px solid red;
}
.label-float select:required:invalid + label:before {
  content: "*";
}

label.option-selected {
  font-size: 13px;
  transform: translateY(calc(-55%));
  color: #3951b2;
  background-color: transparent;
}

/* FA ICONS CUSTOM */
.fa-check {
  margin: 0 5px;
  position: relative;
  top: -2px;
  font-size: 12px;
  color: white;
  border-radius: 50%;
  padding: 3px;
  background-color: #3699ff;
}

/* * {
    border: 1px solid red;
  } */
</style>

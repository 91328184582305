<template>
    <div class="container-fluid bg-white" :class="['bookingCreate', 'receiptsPopupTable'].includes(this.$store.state.app.openModal) ? 'modal-frame-xl' : 'modal-frame'" @keydown.esc="this.$store.state.app.openModal = ''" tabindex="0">
        <AdminCreate v-if="this.$store.state.app.openModal == 'adminCreate'" />
        <ClientCreate v-if="this.$store.state.app.openModal == 'clientCreate'" />
        <SalesCreate v-if="this.$store.state.app.openModal == 'salesCreate'" />
        <TransporterCreate v-if="this.$store.state.app.openModal == 'transporterCreate'" />
        <OriginCreate v-if="this.$store.state.app.openModal == 'originCreate'" />
        <DestinationCreate v-if="this.$store.state.app.openModal == 'destinationCreate'" />
        <ShippingCreate v-if="this.$store.state.app.openModal == 'shippingCreate'" />
        <ProviderCreate v-if="this.$store.state.app.openModal == 'providerCreate'" />
        <GasCreate v-if="this.$store.state.app.openModal == 'gasCreate'" />
        <AdminDeleteConfirmation v-if="this.$store.state.app.openModal == 'adminDeleteConfirmation'" />
        <SalesDeleteConfirmation v-if="this.$store.state.app.openModal == 'salesRemoveConfirmation'" />
        <!-- <AdminCreate v-if="this.$store.state.app.openModal == 'adminCreate'" /> -->
        <DestinationView v-if="this.$store.state.app.openModal == 'destinationView'" />
        <OriginView v-if="this.$store.state.app.openModal == 'originView'" />
        <ShippingView v-if="this.$store.state.app.openModal == 'shippingView'" />
        <ProviderView v-if="this.$store.state.app.openModal == 'providerView'" />
        <GasVIew v-if="this.$store.state.app.openModal == 'gasView'" />
        <RateCreate v-if="this.$store.state.app.openModal == 'publicRateCreate' || this.$store.state.app.openModal == 'clientRateCreate'" />
        <RateView v-if="this.$store.state.app.openModal == 'publicRateView' || this.$store.state.app.openModal == 'clientRateView'" />
        <RequestCreate v-if="this.$store.state.app.openModal == 'requestCreate'" />
        <RecipientsCreate v-if="this.$store.state.app.openModal == 'recipientsCreate'" />
        <RecipientsView v-if="this.$store.state.app.openModal == 'recipientsView'" />
        <BookingCreate v-if="this.$store.state.app.openModal == 'bookingCreate'" />
        <BookingApprove v-if="this.$store.state.app.openModal == 'approveBooking'" />
        <BookingReject v-if="this.$store.state.app.openModal == 'rejectBooking'" />
        <ApprovalDetail v-if="this.$store.state.app.openModal == 'approvalDetail'" />
        <RejectDetail v-if="this.$store.state.app.openModal == 'rejectionDetail'" />
        <ChangeTransporter v-if="this.$store.state.app.openModal == 'changeTransporter'" />
        <UpdateTrip v-if="this.$store.state.app.openModal == 'updateTrip'" />
        <ReceivePackage v-if="this.$store.state.app.openModal == 'receivePackage'" />
        <ReceiptsPopupTable v-if="this.$store.state.app.openModal == 'receiptsPopupTable'" />
        <PromoCreate v-if="this.$store.state.app.openModal == 'promoCreate'" />
        <PromoView v-if="this.$store.state.app.openModal == 'promoView'" />
        <AdsCreate v-if="this.$store.state.app.openModal == 'adsCreate'" />
        <AdsView v-if="this.$store.state.app.openModal == 'adsView'" />
        <AdPopup v-if="this.$store.state.app.openModal == 'adPopup'" />
    </div>
</template>

<script>
    import "jquery/dist/jquery.js";

    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";
    import "bootstrap-icons/font/bootstrap-icons.css";
    import $ from "jquery";
    import axios from "axios";
    import AdminCreate from "./modalForms/adminCreate.vue";
    import AdminDeleteConfirmation from "./modalForms/adminDeleteConfirmation.vue";
    import ClientCreate from "./modalForms/clientCreate.vue";
    import SalesCreate from "./modalForms/salesCreate.vue";
    import TransporterCreate from "./modalForms/transporterCreate.vue";
    import SalesDeleteConfirmation from "./modalForms/salesDeleteConfirmation.vue";
    import OriginCreate from "./modalForms/originCreate.vue";
    import DestinationCreate from "./modalForms/destinationCreate.vue";
    import ShippingCreate from "./modalForms/shippingCreate.vue";
    import ProviderCreate from "./modalForms/providerCreate.vue";
    import GasCreate from "./modalForms/gasCreate.vue";
    import OriginView from "./modalForms/originView.vue";
    import DestinationView from "./modalForms/destinationView.vue";
    import ShippingView from "./modalForms/shippingView.vue";
    import ProviderView from "./modalForms/providerView.vue";
    import GasVIew from "./modalForms/gasView.vue";
    import RateCreate from "./modalForms/rateCreate.vue";
    import RateView from "./modalForms/rateView.vue";
    import RequestCreate from "./modalForms/requestCreate.vue";
    import RecipientsCreate from "./modalForms/recipientsCreate.vue";
    import RecipientsView from "./modalForms/recipientsView.vue";
    import BookingCreate from "./modalForms/bookingCreate.vue";
    import BookingApprove from "./modalForms/approveBooking.vue";
    import BookingReject from "./modalForms/rejectBooking.vue";
    import RejectDetail from "./modalForms/rejectionDetail.vue";
    import ApprovalDetail from "./modalForms/approvalDetail.vue";
    import ChangeTransporter from "./modalForms/changeTransporter.vue";
    import UpdateTrip from "./modalForms/updateTrip.vue";
    import ReceivePackage from "./modalForms/receivePackage.vue";
    import ReceiptsPopupTable from "./modalForms/receiptsPopupTable.vue";

    import PromoCreate from "./modalForms/promoCreate.vue";
    import PromoView from "./modalForms/promoView.vue";
    import AdsCreate from "./modalForms/adsCreate.vue";
    import AdsView from "./modalForms/adsView.vue";
    import AdPopup from "./modalForms/adPopup.vue";
    export default {
        name: "ModalDialog",
        components: {
            AdminCreate,
            AdminDeleteConfirmation,
            ClientCreate,
            SalesCreate,
            TransporterCreate,
            SalesDeleteConfirmation,
            OriginCreate,
            DestinationCreate,
            ShippingCreate,
            ProviderCreate,
            GasCreate,
            OriginView,
            DestinationView,
            ShippingView,
            ProviderView,
            GasVIew,
            RateCreate,
            RateView,
            RequestCreate,
            RecipientsCreate,
            RecipientsView,
            BookingCreate,
            BookingApprove,
            BookingReject,
            RejectDetail,
            ApprovalDetail,
            ChangeTransporter,
            UpdateTrip,
            ReceivePackage,
            ReceiptsPopupTable,
            PromoCreate,
            PromoView,
            AdsCreate,
            AdsView,
            AdPopup,
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
            userFullName() {
                if (this.$store.state.userData.full_name.length > 30) {
                    return this.$store.state.userData.full_name.substring(0, 28) + "...";
                } else {
                    return this.$store.state.app.userData.full_name;
                }
            },
            role() {
                if (this.$store.state.userData.access_level == 5) {
                    return "Manager";
                } else if (this.$store.state.userData.access_level == 4) {
                    return "Admin";
                } else if (this.$store.state.userData.access_level == 3) {
                    return "Sales";
                } else if (this.$store.state.userData.access_level == 2) {
                    return "Client";
                } else {
                    return "Transporter";
                }
            },
        },
        data() {
            return {
                loadForm: "signin",
                menu: "Manage",
                submenu: "Users",
                userAction: false,
                publicRouteNames: ["Signin", "ResetPass", "PassChanged"],
            };
        },
        methods: {
            logout() {
                localStorage.clear();
                location.reload();
            },
            currentMenu() {
                if (["Publish Rates", "Client Rates", "Request Change"].includes(this.$route.name)) {
                    return "Rates";
                }

                if (["Users", "References"].includes(this.$route.name)) {
                    return "Manages";
                }

                if (["Receipt", "SLA"].includes(this.$route.name)) {
                    return "Reports";
                } else {
                    return this.$route.name;
                }
            },
            currentSubmenu() {
                let menu = this.currentMenu();
                if (["Dashboard", "Receipts"].includes(menu)) {
                    return "";
                } else {
                    return this.$route.name;
                }
            },
            getMenu(val) {
                this.menu = val;
            },
            getSub(val) {
                this.submenu = val;
            },
            toggleUserAction() {
                this.userAction = !this.userAction;
            },
            setFocusUser(ele) {
                $(ele.target).focus();
                console.log(ele.target);
            },
            keepLogin(getToken) {
                axios
                    .post(this.$store.state.app.apiBasepath + "/user/keep-login", {}, { headers: { Authorization: `Bearer ${JSON.parse(getToken)}` } })
                    .then((response) => {
                        localStorage.setItem("token", JSON.stringify(response.data.token));

                        let userData = response.data.keepLogin;
                        this.$store.state.userData = userData;
                    })
                    .catch(() => {
                        if (!this.$route.query.wv) this.$router.push("/signin");
                    });
            },
        },
    };
</script>

<style scoped>
    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .modal-frame {
        z-index: 5;
        max-height: 90vh;
        width: 550px;
        border-radius: 5px;
    }

    .modal-frame-xl {
        z-index: 5;
        max-height: 90vh;
        border-radius: 5px;
        width: fit-content;
        /* max-width: 600px;  */
        overflow: auto;
    }

    .blur-overlay {
        display: block;
        filter: blur(3px) brightness(90%);
        -webkit-filter: blur(3px) brightness(90%);
        -moz-filter: blur(3px) brightness(90%);
        -o-filter: blur(3px) brightness(90%);
        -ms-filter: blur(3px) brightness(90%);
        pointer-events: none;
    }

    .spacer {
        min-width: 250px;
        width: 20vw;
    }

    /* VUE TRANSITION */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* css  */
    myroute {
        cursor: pointer;
    }

    .button-back {
        color: #3699ff;
    }

    .usfc-bg {
        background-color: #fbfbfb;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    input[type="password"] {
        font-family: Verdana;
        letter-spacing: 0.25em;
        font-weight: 900;
    }

    .toggle-password,
    .toggle-password-confirm {
        cursor: pointer;
    }

    .blue-filter {
        filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg) brightness(108%) contrast(101%);
    }
    .profile-card {
        background-color: white;
        width: 75px;
        height: 100%;
        border-radius: 20px;
        cursor: pointer;
    }

    /* .profile-card:hover {
      border: solid 1px rgba(54, 153, 255, 0.25);
    } */

    .prevent-select {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .parent_arrow {
        transform: rotate(180deg);
    }

    .user-button {
        z-index: 999;
        position: absolute;
        width: auto;
        right: 20px;
        top: 15vh;
        background-color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 6px;
    }

    ul.user-button li {
        position: relative;
        padding: 10px 0;
        width: 200px;
        /* display: block; */
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        line-height: 2em;
    }

    ul.user-button {
        position: absolute;
        top: 75px;
        right: 0;
        padding-left: 0;
    }

    li:hover {
        opacity: 1;
        background: rgba(54, 153, 255, 0.1);
        border-radius: 6px;
        filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg) brightness(107%) contrast(101%);
    }

    .sign-out-button {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .sign-out-button:hover {
        filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg) brightness(111%) contrast(104%);
    }

    .top-panel {
        height: 10vh;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
</style>

<template>
  <ul class="mt-4 mx-2">
    <li
      @click="this.$store.state.app.managerForm.currentReqTab = 'approval'"
      :class="currentReqTab == 'approval' ? 'active' : ''"
      class="px-5 py-2 mx-1"
    >
      Pending ({{ NeedApproval }})
    </li>
    <li
      v-if="$store.state"
      @click="this.$store.state.app.managerForm.currentReqTab = 'completed'"
      :class="currentReqTab == 'completed' ? 'active' : ''"
      class="px-5 py-2 mx-1"
    >
      Completed
    </li>
  </ul>
</template>

<script>
/* eslint-disable */
import axios from "axios";

export default {
  name: "ManagerComponent",
  props: {},
  components: {},
  data() {
    return {
      loadForm: "signin",
      menu: "Manage",
      submenu: "Users",
      userAction: false,
      NeedApproval: null,
    };
  },
  computed: {
    currentReqTab() {
      return this.$store.state.app.managerForm.currentReqTab;
    },
    getLoadForm() {
      return this.loadForm;
    },
    currentMenu() {
      return this.menu;
    },
    currentSubmenu() {
      return this.submenu;
    },
    isUserAction() {
      return this.userAction;
    },
  },
  methods: {
    getApproval() {
      let token = JSON.parse(localStorage.getItem("token"));
      const access = this.$store.state.userData.access_level;
      let path;
      if (access >= 4) path = "all";
      else if (access === 2) path = "by-client";
      else path = "all";

      axios
        .get(
          this.$store.state.app.apiBasepath +
            `/booking-receipt/${path}?filter=pending`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data.count > 0) {
            this.$store.state.app.managerForm.noResult = false;
            this.$store.state.app.managerForm.formLoading = false;
          } else {
            this.$store.state.app.managerForm.noResult = true;
            this.$store.state.app.managerForm.formLoading = false;
          }
          this.NeedApproval = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getApproval();
    }, 500);
    // setInterval(() => {
    //   this.getApproval();
    // }, 10000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.usfc-bg {
  background-color: #fbfbfb;
}

.blue-filter {
  filter: invert(45%) sepia(69%) saturate(2325%) hue-rotate(196deg)
    brightness(108%) contrast(101%);
}
.profile-card {
  background-color: white;
  width: 7%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
}

/* .profile-card:hover {
    border: solid 1px rgba(54, 153, 255, 0.25);
  } */

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.parent_arrow {
  transform: rotate(180deg);
}

.user-button {
  position: absolute;
  right: 3vw;
  top: 15vh;
  width: 12vw;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  text-align: left;
  border-radius: 6px;
}

ul {
  height: 5%;
}

li {
  text-align: center;
  display: inline;
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  vertical-align: middle;
}

li.active {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

li:hover {
  opacity: 1;
  background: rgba(54, 153, 255, 0.1);
  border-radius: 6px;
  filter: invert(42%) sepia(100%) saturate(546%) hue-rotate(178deg)
    brightness(107%) contrast(101%);
}

.sign-out-button {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.sign-out-button:hover {
  filter: invert(51%) sepia(27%) saturate(6806%) hue-rotate(329deg)
    brightness(111%) contrast(104%);
}

.top-panel {
  height: 10vh;
}

.frame-content {
  min-height: 80vh;
}
.illustration {
  align-items: center;
  flex-wrap: wrap;
}

.vh-100 {
  height: 100vh;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

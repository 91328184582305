<template>
    <SlaView v-if="this.$route.name == 'SLA'" />
    <ReceiptView v-if="this.$route.name == 'Receipt'" />
    <SalesView v-if="this.$route.name == 'Sales Performance'" />
    <TransporterView v-if="this.$route.name == 'Transporter Performance'" />
    <GasolineView v-if="this.$route.name == 'Gasoline Report'" />
</template>
<script>
    import SlaView from "./slaView/slaView.vue";
    import ReceiptView from "./receiptView/receiptView.vue";
    import SalesView from "./salesView/salesView.vue";
    import TransporterView from "./transporterView/transporterView.vue";
    import GasolineView from "./gasolineView/gasolineView.vue";
    export default { components: { SlaView, ReceiptView, SalesView, TransporterView, GasolineView } };
</script>
<style lang=""></style>
./transporterView/transporterView_old.vue